import { useCallback } from 'react';
import { API_URL } from 'constant';
import { useCookie, useNetwork, useNotification } from 'hooks';

const getCookie = (key: string) => {
	const name = key + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const decodeCookie = decodedCookie.split(';');
	for (let i = 0; i < decodeCookie.length; i++) {
		let decodeKey: any = decodeCookie[i];
		while (decodeKey.charAt(0) === ' ') {
			decodeKey = decodeKey.substring(1);
		}
		if (decodeKey.indexOf(name) === 0) {
			return decodeKey.substring(name.length, decodeKey.length);
		}
	}
	return '';
};

export const moveToLoginPage = () => {
	const key = 'user';
	const localData = getCookie(key);

	if (localData) {
		try {
			document.cookie =
				key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		} catch (err) {
			// do nothing
		}
	}
	localStorage.clear();
};

export const useLogout = () => {
	const { post: logoutApi, loading, isLoaded } = useNetwork();
	const { deleteCookie } = useCookie();
	const { errorNotification } = useNotification();

	const logout = useCallback(() => {
		logoutApi(API_URL.LOGOUT, {})
			.then(resData => {
				deleteCookie('user');
				localStorage.clear();
				window.location.href = '/login';
				if (resData.status === 200) {
					return;
				}
			})
			.catch(error => {
				errorNotification(error);
			});
	}, [deleteCookie, errorNotification, logoutApi]);

	return { logout, loading, isLoaded };
};
