import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Button, CountryMobileNumber, Input, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';

import {
	CreateSubAccountState,
	ICreateSubAccountState,
	ISubAccountListState,
	SUBACCOUNTDETAILS,
	SubAccountListState,
} from '../../hooks';
import { scrollToIndex } from 'utils/scroll';
import { SubAccountNameIds } from 'views/sub-account/constants';
import { SubscriptionPriceState } from 'global-stores';
import {
	capitalizeFirstLetter,
	isEmailValid,
	isValidPhoneNumber,
	isValidRecipientName,
} from 'utils';

interface ICreateModal {
	isOpen: boolean;
	handleCloseModal: () => void;
	isEditable: boolean;
	subAccDetail: ISubAccountListState | null;
}

interface IErrorMessageState {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	name: string;
}
const specialCharRegex = /^[a-zA-Z0-9 ]*$/;

export const CreateSubAccountModal: FC<ICreateModal> = ({
	isOpen,
	handleCloseModal,
	isEditable,
	subAccDetail,
}) => {
	const [createSubAccount, setCreateSubAccount] = useRecoilState(
		CreateSubAccountState
	);
	const [errorMessage, setErrorMessage] = useState<IErrorMessageState>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		name: '',
	});

	const [subAccountRows, setSubAccountRows] =
		useRecoilState(SubAccountListState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const [isErrorAccountName, setIsErrorAccountName] = useState(false);

	const resetCreateAccount = useResetRecoilState(CreateSubAccountState);

	const {
		post: postCreateSubAccount,
		patch: patchEditSubAccount,
		loading,
	} = useNetwork();

	const { successNotification } = useNotification();

	const price = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0]?.['entity'];
		} else return 0;
	}, [subscriptionPrice]);

	const showSubscriptionServices = useMemo(() => {
		return (
			createSubAccount['ownBilling'] || createSubAccount['differentCompany']
		);
	}, [createSubAccount]);

	useEffect(() => {
		if (isEditable && subAccDetail) {
			const { ownBilling, name, differentCompany } = subAccDetail ?? {};
			setCreateSubAccount({
				name: name?.trim(),
				ownBilling,
				differentCompany,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		handleCloseModal();
		setTimeout(() => {
			setIsErrorAccountName(false);
			resetCreateAccount();
		}, 1000);
	};

	const handleCreate = useCallback(() => {
		const { name, primaryUserDetails, differentCompany, ownBilling } =
			createSubAccount;
		const enties = Object.entries(primaryUserDetails ?? {});
		let primaryValue = false;
		let invalidUserNamesFormat = false;
		if (differentCompany || ownBilling) {
			for (const [key, value] of enties) {
				const capitalizeFieldName = capitalizeFirstLetter(key);

				if (!value) {
					setErrorMessage(prev => ({
						...prev,
						[key]: `${capitalizeFieldName} is required!`,
					}));

					if (key) {
						scrollToIndex(SubAccountNameIds[key]);
					}
				}

				if (
					(key == 'firstName' || key == 'lastName') &&
					!isValidRecipientName(value.trim())
				) {
					setErrorMessage(prev => ({
						...prev,
						[key]: message.VALID_NO_SPECIAL_NUMBER_MESSAGE,
					}));
					invalidUserNamesFormat = true;
					if (key) {
						scrollToIndex(SubAccountNameIds[key]);
					}
				}
			}
			primaryValue = Object.values(primaryUserDetails ?? {}).some(el => !el);
		}
		if (!name.trim()) {
			setErrorMessage(prev => ({
				...prev,
				['name']: `Sub-account name is required!`,
			}));

			scrollToIndex(SubAccountNameIds['name']);
		}
		if (name.trim().length > 50) {
			setErrorMessage(prev => ({
				...prev,
				['name']: 'Sub-account name must be less than or equal to 50 characters',
			}));
			scrollToIndex(SubAccountNameIds['name']);
			return;
		}

		const isNameExist = subAccountRows.find(
			item =>
				item?.name?.toLowerCase() ===
				createSubAccount.name?.toLowerCase()?.trim()
		);
		if (isNameExist && !isEditable) {
			setErrorMessage(prev => ({
				...prev,
				['name']: message.DUPLICATE_NAME_MESSAGE,
			}));

			scrollToIndex(SubAccountNameIds['name']);

			return setIsErrorAccountName(true);
		}

		if (
			isNameExist &&
			isEditable &&
			isNameExist?.name !== subAccDetail?.name?.trim()
		) {
			setErrorMessage(prev => ({
				...prev,
				['name']: message.DUPLICATE_NAME_MESSAGE,
			}));

			scrollToIndex(SubAccountNameIds['name']);

			return setIsErrorAccountName(true);
		}

		const payload = {
			name: name.trim(),
			ownBilling,
			differentCompanyBilling: differentCompany,
			primaryUserDetail: primaryUserDetails,
		};

		if (isEditable && subAccDetail) {
			patchEditSubAccount(`${API_URL.SUB_ACCOUNT}/${subAccDetail?._id}`, {
				name: name.trim(),
			}).then(resp => {
				if (resp?._id) {
					successNotification(message.UPDATE_SUCCESS_MESSAGE);
					handleClose();
					setSubAccountRows(prev => {
						const prevObj = structuredClone(prev);
						const subResult = prevObj?.find(
							item => item._id === subAccDetail?._id
						);
						const idx = prevObj?.findIndex(
							item => item._id === subAccDetail?._id
						);
						if (subResult && idx !== -1) {
							const payload = {
								...subResult,
								['name']: name,
							};

							prevObj.splice(idx, 1, payload);
						}
						return prevObj;
					});
				}
			});
		} else {
			if (!primaryValue && !invalidUserNamesFormat && name.trim()) {
				postCreateSubAccount(API_URL.SUB_ACCOUNT, payload).then(resp => {
					if (resp?._id) {
						if (differentCompany || ownBilling) {
							resp['totalUsers'] = 1;
						}
						successNotification(message.SUB_ACCOUNT_CREATED_SUCCESS);
						setSubAccountRows(pre => [resp, ...pre]);
						handleClose();
					}
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createSubAccount]);

	const handleChange = useCallback(
		(
			e: ChangeEvent<HTMLInputElement & { countryCode?: string }>,
			name: string
		) => {
			const { value, checked, type } = e.target;
			if (type === 'checkbox') {
				setErrorMessage({
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					name: '',
				});
			} else {
				setErrorMessage(prev => ({
					...prev,
					[name]: '',
				}));
			}

			const capitalizeFieldName = capitalizeFirstLetter(name);
			if (type === 'checkbox') {
				setCreateSubAccount(prev => ({
					...prev,
					[name]: checked,
				}));
				return;
			}

			if (name === 'name') {
				if (!specialCharRegex.test(value)) return;
				setCreateSubAccount(prev => ({
					...prev,
					[name]: value,
				}));

				setIsErrorAccountName(false);
				if (!value) {
					setErrorMessage(prev => ({
						...prev,
						[name]: `Sub-account name is required!`,
					}));
					return;
				}
				if (value.length > 50) {
					setErrorMessage(prev => ({
						...prev,
						[name]: `Sub-account name must be less than or equal to 50 characters`,
					}));
					return;
				}
			} else {
				setCreateSubAccount(
					prev =>
						({
							...prev,
							primaryUserDetails: {
								...prev.primaryUserDetails,
								[name]: value,
							},
						} as ICreateSubAccountState)
				);

				if (!value && name !== 'countryCode') {
					setErrorMessage(prev => ({
						...prev,
						[name]: `${capitalizeFieldName} is required!`,
					}));
				} else if (name === 'email' && !isEmailValid(value)) {
					setErrorMessage(prev => ({
						...prev,
						[name]: 'Invalid email address',
					}));
					return;
				} else if (name === 'phone' && !isValidPhoneNumber(value.trim())) {
					setErrorMessage(prev => ({
						...prev,
						[name]: 'Invalid phone number!',
					}));
					return;
				}
			}
		},
		[setCreateSubAccount]
	);

	const onSelectOptions = (_: boolean, name: string) => {
		if (isEditable) return;

		setCreateSubAccount(prev => {
			const newObj: any = structuredClone(prev);
			newObj['ownBilling'] = false;
			newObj['differentCompany'] = true;
			newObj[name] = false;
			return newObj;
		});
	};

	const onHandlePhoneChange = useCallback(
		(countryCode: string | number, phone: string) => {
			if ((/^\d+$/.test(phone) || phone === '') && phone.length <= 12) {
				setCreateSubAccount(
					prev =>
						({
							...prev,
							primaryUserDetails: {
								...prev.primaryUserDetails,
								phone,
								countryCode,
							},
						} as ICreateSubAccountState)
				);
				if (phone.length < 8) {
					setErrorMessage(prev => ({
						...prev,
						['phone']: 'Phone should be at least 8 digits',
					}));
				} else {
					setErrorMessage(prev => ({
						...prev,
						['phone']: '',
					}));
				}
				return;
			}
		},
		[setCreateSubAccount]
	);

	const onHandleChangeCountry = useCallback(
		(countryCode: string | number) => {
			setCreateSubAccount(
				prev =>
					({
						...prev,
						primaryUserDetails: {
							...prev.primaryUserDetails,
							countryCode,
						},
					} as ICreateSubAccountState)
			);
		},
		[setCreateSubAccount]
	);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleClose}
			modalName="create-sub-account-modal"
			title={
				<div className="onboarding-details__header">
					<div className="onboarding-details__title">
						{isEditable ? 'Edit' : 'Create'} Sub-account
					</div>
				</div>
			}
		>
			<>
				<div className="create-sub-account">
					<Input
						label="Sub-account name"
						inputType="text"
						placeholder="Simplici"
						value={createSubAccount['name']}
						handleChange={e => handleChange(e, 'name')}
						isError={!!errorMessage.name || isErrorAccountName}
						errorMessage={errorMessage['name']}
						id="sub-account-name"
					/>
					<div className="create-sub-account_wrapper">
						<div className="create-sub-account_wrapper__inner">
							<div
								className="create-sub-account_wrapper__inner__section"
								style={{
									border: !createSubAccount['ownBilling']
										? '1.5px solid var(--color-primary-light)'
										: '1.5px solid var(--color-label-100-light)',
								}}
								onClick={() =>
									onSelectOptions(createSubAccount.ownBilling, 'ownBilling')
								}
							>
								<div className="sub-account-type-option-wrapper">
									<div className="create-sub-account__ownBilling">
										<div className="create-sub-account__ownBilling--title">
											{SUBACCOUNTDETAILS.OWN_BILLING_TITLE}
										</div>
										<div className="create-sub-account__ownBilling--description">
											{SUBACCOUNTDETAILS.OWN_BILLING_DESCRIPTION}
										</div>
									</div>

									<input
										type="radio"
										checked={!createSubAccount['ownBilling']}
									/>
								</div>
							</div>
							<div
								className="create-sub-account_wrapper__inner__section create-sub-account_wrapper__inner--disabled"
								style={{
									border: !createSubAccount['differentCompany']
										? '1.5px solid var(--color-primary-light)'
										: '1.5px solid var(--color-label-100-light)',
								}}
								//@avinashSatschel need to comment this method as this is disalbed for now
								// onClick={() =>
								// 	onSelectOptions(
								// 		'differentCompany'
								// 	)
								// }
							>
								<div className="sub-account-type-option-wrapper">
									<div className="create-sub-account__ownBilling">
										<div className="create-sub-account__ownBilling--title">
											{SUBACCOUNTDETAILS.COMPANY_SUB_ACCOUNT_TITLE}
										</div>
										<div className="create-sub-account__ownBilling--description">
											{SUBACCOUNTDETAILS.COMPANY_SUB_ACCOUNT_DESCRIPTION}
										</div>
									</div>
									<input
										type="radio"
										checked={!createSubAccount['differentCompany']}
										disabled
									/>
								</div>
								<div className="sub-account-type-option-wrapper__coming_soon">
									Coming Soon...
								</div>
							</div>
						</div>
					</div>
					{showSubscriptionServices && !isEditable && (
						<div className="create-sub-account__subscription">
							<div>
								<div className="create-sub-account__subscription--heading">
									<div className="create-sub-account__subscription--inner">
										<div className="create-sub-account__subscription_title">
											{SUBACCOUNTDETAILS.MANAGER_DETAILS_TITLE}
										</div>
									</div>
									<div className="create-sub-account__subscription__lines" />
								</div>
								<p className="create-sub-account__subscription--subHeading">
									{SUBACCOUNTDETAILS.MANAGER_DETAILS_DESCRIPTION}
								</p>
							</div>
							<div className="create-sub-account__subscriptionForm">
								<div className="create-sub-account__subscription--firstName">
									<Input
										label="First Name"
										inputType="text"
										placeholder="Enter first name"
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'firstName')
										}
										isError={!!errorMessage.firstName}
										errorMessage={errorMessage['firstName']}
										maxLength={50}
										isRequired
										id="sub-account-firstName"
									/>
								</div>
								<div className="create-sub-account__subscription--lastName">
									<Input
										label="Last Name"
										inputType="text"
										placeholder="Enter last name"
										id="sub-account-lastName"
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'lastName')
										}
										isError={!!errorMessage.lastName}
										errorMessage={errorMessage['lastName']}
										maxLength={50}
										isRequired
									/>
								</div>

								<div className="create-sub-account__subscription--email">
									<Input
										label="Email"
										inputType="text"
										placeholder="johndoe@examplemail.com"
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'email')
										}
										isError={!!errorMessage.email}
										errorMessage={errorMessage['email']}
										isRequired
										id="sub-account-email"
									/>
								</div>
								<div
									className="create-sub-account__subscription--phone"
									id="sub-account-phone"
								>
									<CountryMobileNumber 
										handleChange={(e) =>
											onHandlePhoneChange(e.countryCode, e.phone)
										}
										defaultNumber={createSubAccount.primaryUserDetails?.phone}
										label="Mobile Number"
										handleChangeCountry={onHandleChangeCountry}
										isCountryCodeVisible={true}
										defaultCountryCode={createSubAccount.primaryUserDetails?.countryCode}
										isError={!!errorMessage.phone}
										errorMessage={errorMessage['phone']}
										isRequired
									/>
								</div>
								<div className="create-sub-account__subscriptionEntity">
									<div className="create-sub-account__icon">
										<i className="ri-briefcase-fill"></i>
									</div>
									<div className="create-sub-account__subscriptionDetails">
										<div className="create-sub-account__subsTitle">
											Onboarding + esign
										</div>
										<div className="create-sub-account__price">
											{`$${price ?? 0}/user/month`}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="create-sub-account_footer">
					<Button
						label={isEditable ? 'Cancel' : 'Back'}
						type="button__filled--secondary"
						handleClick={handleClose}
					/>
					<Button
						label={
							loading ? (
								<Loader type="loader" dimension={24} className="loader-white" />
							) : isEditable ? (
								'Save'
							) : (
								'Create'
							)
						}
						type="button__filled--primary"
						handleClick={handleCreate}
						disabled={loading}
					/>
				</div>
			</>
		</Modal>
	);
};
