import { useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';
import './sub-account-details.scss';
import { getDate } from 'utils';
import Tippy from '@tippyjs/react';

export const SubAccountDetails = () => {
	const {
		companyName = '',
		parentBusiness,
		primaryUser,
		businessCreatedAt = '',
	} = useRecoilValue(loginState);

	return (
		<>
			<div className="whitelabel-settings__label-text">Sub-account</div>
			<div className="SubAccountDetails--wrapper">
				<div className="SubAccountDetails--body">
					<div className="SubAccountDetails--icon">
						<i className="ri-building-2-line" />
					</div>
					<div className="">
						<div className="SubAccountDetails--title">Name</div>
						<Tippy content={companyName}><div className="SubAccountDetails--desc SubAccountDetails--companyName">{companyName}</div></Tippy>
					</div>
				</div>
				<div className="SubAccountDetails--body">
					<div className="SubAccountDetails--icon ">
						<i className="ri-calendar-line date" />
					</div>
					<div className="">
						<div className="SubAccountDetails--title">Creation date</div>
						<div className="SubAccountDetails--desc">
							{' '}
							{getDate(businessCreatedAt)}
						</div>
					</div>
				</div>
				<div className="SubAccountDetails--body">
					<div className="SubAccountDetails--icon">
						<i className="ri-briefcase-line name" />
					</div>
					<div className="">
						<div className="SubAccountDetails--title">Sub-account of</div>
						<div className="SubAccountDetails--desc">
							{parentBusiness?.name ?? ''}
						</div>
						<div className="SubAccountDetails--desc">
							{primaryUser?.email ?? 'NA'}
						</div>
						<div className="SubAccountDetails--desc">
							{`${primaryUser?.countryCode ?? ''} ${
								primaryUser?.phone ?? 'NA'
							}`}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
