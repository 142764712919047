import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import {
	EmailMessageReminderValue,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	UploadedDocsState,
} from 'global-stores';
import { useAddDollarCurrencySurvey } from 'hooks/use-add-dollar-currency';
import {
	AddedActionsState,
	AddedFileDataState,
	ConfigureSurvayFormState,
	CustomAccreditationTempId,
	SelectedStepsState,
} from 'views/onboarding-flow/store';
import { defaultCountry, defaultLang } from 'views/pipeline';

export const usePublishPayload = () => {
	const addedActions = useRecoilValue(AddedActionsState);
	const masterSteps = useRecoilValue(PipelineMasterSteps);
	const settingsFormState = useRecoilValue(PipelineSettingsFormState);
	const uploadedDocs = useRecoilValue(UploadedDocsState);
	const addedFiles = useRecoilValue(AddedFileDataState);
	const selectedSteps = useRecoilValue(SelectedStepsState);
	const configureFormState = useRecoilValue(ConfigureSurvayFormState);
	const templateId = useRecoilValue(CustomAccreditationTempId);
	const getEmailMessageReminderValue = useRecoilValue(
		EmailMessageReminderValue
	);

	const { modifyPagesResponse } = useAddDollarCurrencySurvey();

	const getPayload = useCallback(() => {
		const docIds = uploadedDocs.map(doc => {
			return doc._id;
		});
		const payload: { [key: string]: any } = {};
		payload.name = settingsFormState.publishName.trim();
		payload.credentialId = settingsFormState.publishCredential?.value ?? '';
		const deliveryMethod: any = masterSteps.find(
			step => step.key === 'deliveryMethod'
		);
		const publish = masterSteps.find(step => step.key === 'publish');
		const successScreen: any = masterSteps.find(
			step => step.key === 'successScreen'
		);
		const authAction: any = selectedSteps.find(
			step => step.key === 'authentication'
		);
		const addedSteps = [
			...authAction.actions, // this step has been added additionnaly as we wanted to remove the facial recognition from the flow builder but to keep in the onboarding
			...addedActions,
			...(deliveryMethod.actions ?? []),
			...(publish?.actions ?? []),
		]
		const isSuccessScreenStepDragged = addedSteps.findIndex((step) => step.stepKey === "successScreen") > -1
		if(!isSuccessScreenStepDragged) {
			addedSteps.push(...successScreen.actions ?? {})
		}
 		const steps = addedSteps.map((action, index) => {
			const metadata: { [key: string]: boolean | string } = {};
			action.metadata?.forEach(({ key, type, default: defaultValue }: any) => {
				if (
					(type === 'input' && key !== 'publishName') ||
					type === 'textArea'
				) {
					return (metadata[key] = settingsFormState[key] || defaultValue);
				}
				if (key === 'documentIds') {
					return (metadata[key] = docIds as any);
				}
				if (key === 'accreditationType') {
					const defaultAccre = addedActions.find(
						item => item.key === 'accreditationVerify'
					);
					return (metadata[key] = {
						...settingsFormState[key],
						documentId: addedFiles._id || defaultAccre?.metadata[1]?.documentId,
						templateId:
							settingsFormState?.accreditationDocument?.value === 'customUpload'
								? templateId
								: undefined,
						provider:
							settingsFormState?.accreditationType?.value === '506b'
								? 'esign'
								: undefined,
					});
				}
				if (key === 'Address Verification') {
					const address = settingsFormState[key];
					return (metadata['addressVerification'] = address);
				}
				//  here default country and default language are being added in the payload metadata
				//  because we are removing the country code selection and language selection from fundInvestment and we need that during plaid
				//  this may removed later when once we add teh country code and language selection again in the fundInvestment flow

				// country code
				if (key === 'fundInvestmentCountry') {
					return (metadata[key] = defaultCountry.value);
				}

				// language support
				if (key === 'fundInvestmentLanguage') {
					return (metadata[key] = defaultLang.value);
				}
				if (key === 'payInPayOut') {
					const payMetaData = addedActions.find(
						item => item.key === 'fundInvestmentVerification'
					);
					const payType =
						payMetaData.metadata?.find(
							(item: any) => item?.key === 'payInPayOut'
						)?.default ?? 'payIn';

					return (metadata[key] = payType);
				}
				return (metadata[key] = settingsFormState[key]);
			});
			const actionData: any = {
				index,
				actionId: action.key,
				metadata,
			};

			// removing liveness temoporarely for completing kyc
			if (action.key === 'allId') {
				metadata.liveliness = false;
			}
			if (action.key === 'formAction') {
				// Awadhesh : add dollar currency
				const cloneConfigureSurvey = {
					...configureFormState,
					pages: modifyPagesResponse(configureFormState?.pages ?? []),
				};
				metadata[action.key] = cloneConfigureSurvey as any;
			}

			return {
				index,
				stepId: action.stepKey,
				actions: [actionData],
			};
		});

		const pipelineSteps: any[] = [];
		let lastIndex = -1;

		const addedStepKey: any = {};

		steps.forEach(step => {
			const found: any = addedStepKey[step.stepId];
			if (found !== undefined && pipelineSteps[found]) {
				pipelineSteps[found] = {
					...pipelineSteps[found],
					// eslint-disable-next-line no-unsafe-optional-chaining
					actions: [...pipelineSteps[found]?.actions, ...step.actions],
				};
				addedStepKey[step.stepId] = lastIndex;
			} else {
				lastIndex += 1;
				addedStepKey[step.stepId] = lastIndex;
				pipelineSteps.push(step);
			}
		});

		// Paras: handling reminder setting payload keys
		const keysToDelete = [];
		const { emailInput, messageInput } = getEmailMessageReminderValue;

		if (!emailInput) {
			keysToDelete.push(
				'reminderSettingEmailMessage',
				'reminderSettingEmailSubject'
			);
		}

		if (!messageInput) {
			keysToDelete.push('reminderSettingTextMessage');
		}

		for (let i = 0; i < pipelineSteps.length; i++) {
			if (pipelineSteps[i].stepId === 'deliveryMethod') {
				const metadata = pipelineSteps[i]?.actions[2]?.metadata;

				// Paras: Deleting the specified keys from metadata
				keysToDelete.forEach(key => {
					delete metadata[key];
				});
			}
		}
		payload.steps = pipelineSteps;
		return payload;
	}, [
		uploadedDocs,
		settingsFormState,
		masterSteps,
		selectedSteps,
		addedActions,
		getEmailMessageReminderValue,
		addedFiles._id,
		templateId,
		configureFormState,
		modifyPagesResponse,
	]);
	return { getPayload };
};
