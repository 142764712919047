import { Button, Loader } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { SubscriptionRequiredModal } from 'components';
import { API_URL, validateEmail } from 'constant';
import {
	AssinedUserState,
	EditModal,
	UserRoles as IUserRoles,
	InviteDetailsState,
	InviteEmailInputState,
	InviteFirstNameInputState,
	InviteLastNameInputState,
	InviteModal,
	InvitePhoneInputState,
	LoginPhoneNumberState,
	UserRoleState,
	UserRolesState,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { useFreePlan, useNetwork, useNotification } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { Header } from 'views/header';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { SubAccountListState } from 'views/sub-account';
import { AddNewRoleModal, InviteModalPage } from './components';
import { ComplainceAgent } from './components/complaince-agent';
import { RolesAndPermission } from './components/role-permission-list';
import { UserRoleSkeletonLoader } from './components/user-role-loader';
import { InviteNavigation, UserRoleNavigation } from './constant';
import {
	AddNewRoleFormState,
	InviteNavigationState,
	PermissionNavigation,
	SelectedUserRoleState,
	UserRolePermissionNavigation,
} from './store';

export const nameRegex = /^[a-zA-Z0-9 ]+$/;

export const UserRoles = () => {
	const [isOpenInviteUserModal, setIsOpenInviteUserModel] =
		useRecoilState(InviteModal);
	const invites = useRecoilValue(InviteDetailsState);
	const setShowFirstNameError = useSetRecoilState(InviteFirstNameInputState);
	const setShowLastNameError = useSetRecoilState(InviteLastNameInputState);
	const setShowLastEmailError = useSetRecoilState(InviteEmailInputState);
	const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
	const [userRoleNavigate, setUserRoleNavigate] = useRecoilState(
		UserRolePermissionNavigation
	);
	const selectedUserRoleState = useRecoilValue(SelectedUserRoleState);
	const [subAccountRows, setSubAccountRows] =
		useRecoilState(SubAccountListState);
	const setUsers = useSetRecoilState(UserRoleState);
	const [edit, setEdit] = useRecoilState(EditModal);
	const setAssinedUser = useSetRecoilState(AssinedUserState);
	const userRolesList = useRecoilValue(UserRolesState);
	const [inviteNavigation, setInviteNavigation] = useRecoilState(
		InviteNavigationState
	);
	// Reset state
	const resetUserRoleNavigation = useResetRecoilState(PermissionNavigation);
	const resetAddRoleForm = useResetRecoilState(AddNewRoleFormState);
	const resetSelectedUserRole = useResetRecoilState(SelectedUserRoleState);
	const resetShowPhoneError = useResetRecoilState(InvitePhoneInputState);
	const resetLoginPhoneNumber = useResetRecoilState(LoginPhoneNumberState);
	const resetInviteNavigation = useResetRecoilState(InviteNavigationState);
	const resetInvites = useResetRecoilState(InviteDetailsState);

	// Local variables
	const [userRoleLoaded, setUserRoleLoaded] = useState(false);
	const [isSubscriptionExist, setSubscriptionExist] = useState(false);
	const [isInviteUserLoading, setIsInviteUserLoading] =
		useState<boolean>(false);
	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState<boolean>(false);

	// Hooks
	const {
		post: inviteUser,
		data: userDetails,
		loading: inviteLoading,
	} = useNetwork();
	const {
		patch: reinviteUser,
		data: reUserDetails,
		error: reinviteUserError,
		loading: reinviteLoading,
	} = useNetwork();
	const { successNotification, errorNotification } = useNotification();
	const { trackEvents } = useTrackEvents();
	const {
		fetchUserRoles,
		userRoles,
		fetchRolesTemplate,
		templateRoles,
		checkUserWritePermission,
		fetchAssinedUser,
	} = useUserRoles();
	const { get: getSubAccount, data: subAccountList } = useNetwork();

	const navigate = useNavigate();
	// const { sandboxStatus = false } = useMemo(() => user, [user]);
	const { hasSubscribedBusiness, hasSubscribedPerson } = useFreePlan();
	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.UserRoles),
		[checkUserWritePermission]
	);

	useEffect(() => {
		if (!subAccountRows.length) {
			getSubAccount(API_URL.SUB_ACCOUNT);
		}
		setUserRoleNavigate(UserRoleNavigation.AddedRoleCard);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!templateRoles) fetchRolesTemplate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateRoles]);

	useEffect(() => {
		if (subAccountList?.data) {
			setSubAccountRows(subAccountList.data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subAccountList?.data]);

	const handleOpen = useCallback(() => {
		if (hasSubscribedBusiness || hasSubscribedPerson) {
			trackEvents('invite-new-user', {});
			resetUserRoleNavigation();
			setIsAddRoleModalOpen(true);
			setShowLastEmailError(false);
			setEdit(false);
		} else {
			setSubscriptionExist(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasSubscribedBusiness, hasSubscribedPerson]);

	const handleCloseModal = () => {
		setIsAddRoleModalOpen(false);
		resetAddRoleForm();
		resetSelectedUserRole();
	};

	const handleClose = useCallback(() => {
		resetInvites();
		setShowFirstNameError(false);
		setShowLastNameError(false);
		setIsOpenInviteUserModel(false);
		resetShowPhoneError();
		resetLoginPhoneNumber();
		setTimeout(() => {
			resetInviteNavigation();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const closeSubscriptionModal = useCallback(() => {
		setSubscriptionExist(prev => !prev);
	}, []);

	const handleNavigate = useCallback(() => {
		navigate(ROUTES.BILLING);
		setSubscriptionExist(prev => !prev);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCloseDropdown = useCallback(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InvitePreview:
				setInviteNavigation(InviteNavigation.InviteForm);
				break;
			case InviteNavigation.InviteForm:
				setShowFirstNameError(false);
				setShowLastNameError(false);
				setEdit(false);
				resetInvites();
				setIsOpenInviteUserModel(false);
				resetLoginPhoneNumber();
				break;
			default:
				break;
		}
	}, [
		inviteNavigation,
		resetInvites,
		resetLoginPhoneNumber,
		setEdit,
		setInviteNavigation,
		setIsOpenInviteUserModel,
		setShowFirstNameError,
		setShowLastNameError,
	]);

	const onSaveEditRole = useCallback(() => {
		const { funds } = invites;
		const fundIds = funds?.map(({ value }) => value);
		const { _id, role, developer, isAgentPermitted, email } = invites ?? {};
		const payLoad = { role, developer, fundIds, isAgentPermitted, email };
		trackEvents('sent-user-reinvite', { payLoad });
		reinviteUser(`${API_URL.USER_ROLES}/${_id}`, payLoad);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invites]);

	const inviteRoleUser = useCallback(async () => {
		setIsInviteUserLoading(true);
		const {
			firstName = '',
			lastName = '',
			funds = [],
			subAccount,
			...restPayload
		} = invites ?? {};

		if ('email' in invites) {
			// Gaurav: To check first & last name
			if (firstName.trim().length < 3 || lastName.trim().length < 3) {
				if (firstName.trim().length < 3) {
					setShowFirstNameError(true);
				}

				if (lastName.trim().length < 3) {
					setShowLastNameError(true);
				}
				return;
			}
			const fundIds = funds.map(({ value }) => value);
			const newPayload = {
				firstName,
				lastName,
				fundIds,
				subAccount: subAccount?.value ?? undefined,
				...restPayload,
				developer: false,
				isAgentPermitted: false,
			};
			// deleting email from invite as it not required
			trackEvents('sent-user-invite', { newPayload });
			const resp = await inviteUser(API_URL.USER_ROLES, newPayload);

			if (resp?.success) {
				setAssinedUser(prev => {
					const updateData = {
						...prev,
						users: resp?.users ?? [],
					} as IUserRoles;

					return updateData;
				});
				fetchUserRoles();
				fetchAssinedUser(selectedUserRoleState?._id ?? '');
				setTimeout(() => {
					resetInviteNavigation();
					resetLoginPhoneNumber();
				}, 1000);
			}
			setIsInviteUserLoading(false);
		} else {
			setShowLastEmailError(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invites]);

	const onSaveRole = useCallback(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				setInviteNavigation(InviteNavigation.InvitePreview);
				break;
			case InviteNavigation.InvitePreview:
				inviteRoleUser();
				break;
			default:
				break;
		}
	}, [inviteNavigation, inviteRoleUser, setInviteNavigation]);

	useEffect(() => {
		if (userDetails) {
			const { users, message } = userDetails;
			if (users) {
				setUsers(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					return [...prevState, users];
				});
				setIsOpenInviteUserModel(false);
				successNotification(message);
				resetInvites();
			} else {
				errorNotification(message);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails]);

	useEffect(() => {
		const init = async () => {
			if (!userRoles) {
				setUserRoleLoaded(true);
				await fetchUserRoles();
				setUserRoleLoaded(false);
			}
		};
		init();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (reUserDetails) {
			if (reUserDetails?._id) {
				setUsers(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					const removeState = prevState.find(
						(preState: any) => preState._id === reUserDetails?._id
					);
					const index = prevState.indexOf(removeState);

					if (index !== -1) {
						prevState[index] = reUserDetails;
					}
					return prevState;
				});
				setIsOpenInviteUserModel(false);
				successNotification(`Details updated successfully`);
				resetInvites();
			} else if (reUserDetails?.message) {
				errorNotification(reUserDetails?.message);
			}
		} else if (reinviteUserError) {
			errorNotification(reinviteUserError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reUserDetails]);

	const pageLoading = useMemo(() => {
		return <UserRoleSkeletonLoader />;
	}, []);

	const userRoleLoading = useMemo(() => {
		if (userRoleLoaded) {
			return pageLoading;
		}
		//Deepak : removed no role available screen beacuse we have to show owner account
		if (!userRoleLoaded && !(userRolesList ?? [])?.length) {
			return (
				<RolesAndPermission isUserPermissionWrite={isUserPermissionWrite} />
			);
		}
		return null;
	}, [userRoleLoaded, userRolesList, pageLoading, isUserPermissionWrite]);

	const inviteButtonLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Review';
			case InviteNavigation.InvitePreview:
				return !inviteLoading || !reinviteLoading ? (
					!edit ? (
						'Send Invite'
					) : (
						'Save'
					)
				) : (
					<Loader className="loader-blue" type="loader" dimension={26} />
				);
			default:
				return '';
		}
	}, [inviteNavigation, inviteLoading, reinviteLoading, edit]);

	const inviteBackLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Cancel';
			case InviteNavigation.InvitePreview:
				return 'Back';
			default:
				return '';
		}
	}, [inviteNavigation]);

	const isInviteFormDisable = useMemo(() => {
		const { email, role, lastName, firstName, subAccount } = invites ?? {};
		if (
			loginPhoneNumber?.phone?.length >= 8 &&
			firstName?.length >= 3 &&
			lastName?.length >= 3 &&
			nameRegex.test(firstName) &&
			nameRegex.test(lastName) &&
			validateEmail(email) &&
			role
		) {
			if (subAccount) {
				if (subAccount && subAccount.status === 'APPROVED') {
					return false;
				} else {
					return true;
				}
			}
			return false;
		}
		return true;
	}, [invites, loginPhoneNumber?.phone?.length]);

	const isDisabled = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return isInviteFormDisable;
			case InviteNavigation.InvitePreview:
				return false;
			default:
				return false;
		}
	}, [inviteNavigation, isInviteFormDisable]);

	// render user role permission details
	const renderUserRolePermission = useMemo(() => {
		switch (userRoleNavigate) {
			case UserRoleNavigation.AddedRoleCard:
				return (
					<RolesAndPermission isUserPermissionWrite={isUserPermissionWrite} />
				);
			case UserRoleNavigation.ComplainceAgent:
				return (
					<ComplainceAgent isUserPermissionWrite={isUserPermissionWrite} />
				);
			default:
				return (
					<RolesAndPermission isUserPermissionWrite={isUserPermissionWrite} />
				);
		}
	}, [userRoleNavigate, isUserPermissionWrite]);

	return (
		<div className="dashboard-main-body">
			<Header
				title="User Roles"
				showBtn={userRoleNavigate === UserRoleNavigation.AddedRoleCard}
				btnLabel="Add Role"
				icon="ri-add-line"
				handleClickBtn={handleOpen}
			/>
			{userRoleLoading}
			{(userRolesList ?? [])?.length > 0 ? renderUserRolePermission : null}
			<InviteModalPage
				edit={edit}
				isOpen={isOpenInviteUserModal}
				handleClose={handleClose}
				isDisableRole
			>
				<div className="ConfigureModal--footer">
					<Button
						label={inviteBackLabel}
						handleClick={onCloseDropdown}
						type="button__filled button__filled--secondary"
					/>
					{edit ? (
						<Button
							label={inviteButtonLabel}
							handleClick={onSaveEditRole}
							type="button__filled button__filled--primary"
							disabled={isDisabled}
						/>
					) : (
						<Button
							label={inviteButtonLabel as string}
							handleClick={onSaveRole}
							type="button__filled button__filled--primary"
							disabled={isDisabled}
							loader={
								isInviteUserLoading ? (
									<Loader type="loader" dimension={20} />
								) : (
									<></>
								)
							}
						/>
					)}
				</div>
			</InviteModalPage>
			{isAddRoleModalOpen && (
				<AddNewRoleModal
					isAddRoleModalOpen={isAddRoleModalOpen}
					handleCloseModel={handleCloseModal}
				/>
			)}

			{isSubscriptionExist && (
				<SubscriptionRequiredModal
					isModalOpen={isSubscriptionExist}
					closeModal={closeSubscriptionModal}
					handleNavigate={handleNavigate}
				/>
			)}
		</div>
	);
};

export default UserRoles;
