/* eslint-disable no-console */
import { IKybDetails } from 'global-stores/sessions/type';
import { FC, useMemo, useState } from 'react';
import { KybInfoHeader } from '../kyb-info-header';
import './kyb-filing.scss';

interface IKYBFiling {
	item: IKybDetails;
}

export const KybFiling: FC<IKYBFiling> = ({ item }) => {
	const filingValue = useMemo(() => {
		const { financialDetails } = item ?? {};
		const { filingDetails } = financialDetails ?? {};
		const { value } = filingDetails ?? [];

		return value;
	}, [item]);

	const [showAllFilingDetails, setShowAllFilingDetails] = useState(false);

	const toggleShowAllMembers = () => {
		setShowAllFilingDetails(!showAllFilingDetails);
	};
	const handleDownload = (url: any) => {
		window.open(url, '_blank');
	};

	if (!filingValue?.length) {
		return <></>;
	}

	return (
		<>
			<div className="kyb-filing_header">
				<KybInfoHeader type="Filing" />
				{filingValue?.length > 4 && (
					<div onClick={toggleShowAllMembers} className="kyb-filing_view-more">
						{showAllFilingDetails ? 'View less' : 'View more'}
					</div>
				)}
			</div>

			<div className="kyb-filing_container">
				{filingValue?.map((filing, index) => {
					if (index < 4 || showAllFilingDetails) {
						return (
							<div
								key={filing?.title + filing?.date}
								className="kyb-filing_container__card"
							>
								<div className="kyb-filing_container__card__initial">
									<div className="kyb-filing_container__card__initial__text">
										{filing?.title !== '' ? filing?.title : '--'}
									</div>
									<div className="kyb-filing_container__card__initial__designation">
										{filing?.date !== '' ? filing?.date : '--'}
									</div>
								</div>
								{filing?.url && (
									<div className="kyb-filing_container__card__para">
										<i
											className="ri-download-line"
											onClick={() => handleDownload(filing?.url)}
										/>
									</div>
								)}
							</div>
						);
					} else {
						return <></>;
					}
				})}
			</div>
		</>
	);
};
