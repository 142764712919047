import { FC, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { useTreeGraphChart } from 'views/complex-onboarding-flow/hooks';
import { ITreeGraphChart } from 'views/complex-onboarding-flow/type';
import { ComplexSaveState } from 'views/pipelines/components';

import './tree-graph-chart.scss';
import classNames from 'classnames';

export const TreeGraphChart: FC<ITreeGraphChart> = ({
	isLoading,
	title,
	isInverted,
	seriesData,
	seriesLabelName,
	nodesColor,
	isInvite,
	onClick,
	isSessionModal,
	isStepper,
	activeStepId,
	getPricingData
}) => {
	const oldSeriesData = useRef<[string][]>([]);
	const oldNodeColorData = useRef<{ id: string; color: string }[]>([]);
	
	const [complexSave, setComplexSave] = useRecoilState(ComplexSaveState);
	const { getTreeGraphConfig } = useTreeGraphChart();

	useEffect(() => {
		// TODO: this is a temp fix, we need to find a better way
		const seriesDataUpdated =
			JSON.stringify(oldSeriesData.current) !== JSON.stringify(seriesData);

		const nodeColorUpdated =
			JSON.stringify(oldNodeColorData.current) !== JSON.stringify(nodesColor);

		if (seriesDataUpdated || nodeColorUpdated || complexSave) {
			getTreeGraphConfig({
				isLoading,
				seriesData,
				title,
				isInverted,
				seriesLabelName,
				nodesColor,
				isInvite,
				onClick,
				isSessionModal,
				isStepper,
				activeStepId,
				getPricingData
			});
			oldSeriesData.current = seriesData;
			oldNodeColorData.current = nodesColor;
			setComplexSave(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seriesData, nodesColor, complexSave, oldNodeColorData]);

	const chartWrapper = useMemo(() =>{
		return classNames('tree-graph-chart-wrapper',{
			'tree-graph-chart--center' : isStepper
		})
	},[isStepper])

	const treeChart = useMemo(() =>{
		return classNames('',{
			'onboarding-invite-tree-graph-div' : isInvite || isSessionModal || isStepper,
			'onboarding-stepper-tree-graph-div' : isStepper
		})
	},[isStepper, isInvite, isSessionModal])

	return (
		<div className={chartWrapper}>
			<div
				id={title}
				className={treeChart}
			/>
		</div>
	);
};
