import { FC, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { kycSettingsState } from 'global-stores';

const chartWidth = '100%';

interface Props {
	isLoading?: boolean;
	XAxis: any[];
	data: any[];
	title: string;
	color: string;
}

export const LineAreaChart: FC<Props> = ({
	isLoading,
	XAxis,
	data,
	title,
	color,
}) => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings;

	useEffect(() => {
		const chart =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'area',
					height: 200,
					minWidth: 200,
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
				},
				credits: {
					enabled: false,
				},
				title: {
					text: title,
					align: 'left',
					style: {
						color: '#000000',
						fontWeight: '600',
						fontSize: '14px',
					},
				},
				subtitle: {
					text: null,
				},
				xAxis: {
					gridLineWidth: 1,
					categories: XAxis,
				},
				yAxis: {
					min: data.length ? null : 0,
					max: data.length ? null : 10,
					gridLineWidth: 1,
					title: {
						text: null,
					},
					labels: {
						format: title === 'Revenue Growth' && '{value}%',
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					area: {
						color,
						fillColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1,
							},
							stops: [
								[0, color],
								[1, 'white'],
							],
						},
						marker: {
							radius: 2,
						},
						lineWidth: 1,
						states: {
							hover: {
								lineWidth: 1,
							},
						},
						threshold: null,
					},
				},

				series: [
					{
						name: title,
						data,
					},
				],
			}) ?? {};

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	}, [XAxis, bodyFont?.family, color, data, isLoading, title]);

	return (
		<div
			style={{
				backgroundColor: 'transparent',
				width: chartWidth,
				overflowX: 'auto',
			}}
		>
			<div id={title} />
		</div>
	);
};
