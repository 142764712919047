import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { REACT_APP_API_HOST } from 'envs';
import {
	IAction,
	IMetadata,
	IPipelineSteps,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	PipelineTemplateState,
	SubscriptionPriceState,
} from 'global-stores';
import { GoogleFontsState } from 'global-stores/google-fonts';
import { useNetwork } from 'hooks';
import { WebWorker } from 'hooks/worker';
import { SettingFormInitState } from 'views/onboarding-flow';

export const useDashboradhook = () => {
	const setPipelineMasterSteps = useSetRecoilState(PipelineMasterSteps);
	const setPipelineStateForm = useSetRecoilState(PipelineSettingsFormState);
	const setSettingFormInit = useSetRecoilState(SettingFormInitState);
	const setGoogleFonts = useSetRecoilState(GoogleFontsState);
	const setTemplateInit = useSetRecoilState(PipelineTemplateState);
	const setSubscriptionPrice = useSetRecoilState(SubscriptionPriceState);

	const { get: getPipelineInits, accessToken } = useNetwork();
	const { get: getTemplateList } = useNetwork({
		returnResponse: true,
		updateState: false,
	});
	const { get: getSubsPrice } = useNetwork({
		returnResponse: true,
		updateState: false,
	});

	const getPipelineInit = useCallback(() => {
		getPipelineInits(`${API_URL.PIPELINE_INIT}`).then(resp => {
			const { data: allDatas } = resp ?? {};
			const data = allDatas as IPipelineSteps[];
			if (data) {
				try {
					/**
					 * Todo @Paras this is to hide proof read from beta please remove this once we are good to go in beta
					 * */ 
					const steps: IPipelineSteps[] = data.map((step: any) => {
						const actions = step.actions.map((action: any) => ({
							...action,
							stepKey: step.key,
						}));
						return {
							...step,
							actions,
						};
					});

					//@avinashSatschel: commented Docusign as no longer use
					const updatedSteps = steps?.map(step => {
						if (step.actions?.length) {
							step.actions.forEach(action => {
								if (action.metadata && action.metadata.length > 0) {
									action.metadata.forEach(meta => {
										if (meta.options) {
											meta.options = meta.options.filter(
												opt => opt.key !== 'docusign'
											);
										}
									});
								}
							});
						}
						return step;
					});					

					setPipelineMasterSteps(updatedSteps);
					const newState: { [key: string]: boolean | string | undefined } = {};
					data.forEach((step: IPipelineSteps) => {
						step.actions.forEach((action: IAction) => {
							action.metadata.forEach((meta: IMetadata) => {
								const { key } = meta;
								if (meta.default === 'true') {
									return (newState[key] = true);
								}
								if (meta.default === 'false') {
									return (newState[key] = false);
								}
								if (
									key === 'accreditationType' ||
									key === 'accreditationDocument'
								) {
									return (newState[key] = '');
								}
								if (
									key === 'reminderSettingEmailMessage' ||
									key === 'reminderSettingTextMessage'
								) {
									const value = meta?.default?.replace(
										' to recieve your {shares}',
										''
									);
									return (newState[key] = value ?? '');
								}
								return (newState[key] = meta.default);
							});
						});
					});

					setSettingFormInit(newState);
					setPipelineStateForm(newState);
				} catch (error) {
					/* empty */
				}
			}
		});
	}, [
		getPipelineInits,
		setPipelineMasterSteps,
		setPipelineStateForm,
		setSettingFormInit,
	]);

	const fetchGoogleFonts = useCallback(() => {
		const url = `${REACT_APP_API_HOST}${API_URL.GOOGLE_FONDS}`;
		WebWorker.worker.postMessage({
			endpoint: url,
			config: {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
			nodeId: '',
		});
		WebWorker.worker.onmessage = resp => {
			const { status, data } = resp.data;
			if (status === 200) {
				setGoogleFonts(data);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	const getTemplateInit = async () => {
		const resp = await getTemplateList(API_URL.STEP_TEMPLATES);
		if (resp?.apiPayload?.message.toLowerCase() === 'ok') {
			const { data } = resp.apiPayload;
			setTemplateInit(data ?? {});
		}
	};

	const getSubscriptionPrice = async () => {
		const resp = await getSubsPrice(API_URL.SUBSCRIPTION_PRICE);
		if (resp?.apiPayload?.message.toLowerCase() === 'ok') {
			const { data } = resp.apiPayload;
			setSubscriptionPrice(data);
		}
	};
	return {
		getPipelineInit,
		fetchGoogleFonts,
		getTemplateInit,
		getSubscriptionPrice,
	};
};
