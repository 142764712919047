import { IApiResponse } from './types';

const MAP_CASE_TO_KEY = {
	PEP: 'pep',
	EDD: 'edd',
	GWL: 'gwl',
	SOE: 'soe',
	['PEP/EDD']: 'pepedd',
};

const OFAC_LABELS: Record<string, string> = {
	totalSubCases: 'Total Subcases',
	totalMatches: 'Total Matches',
	caseId: 'Case ID',
};

export const OfacFormatter = (ofac: IApiResponse) => {
	const cloneOfac: IApiResponse = JSON.parse(JSON.stringify(ofac));
	const { caseId, subcases, totalMatches, totalSubCases } = cloneOfac ?? {};
	[caseId, subcases, totalMatches, totalSubCases].forEach(item => {
		const { label = '' } = item;
		item.label = OFAC_LABELS?.[label] ?? label;
	});

	const ofacCases = subcases?.value?.reduce((acc: Record<string, any>, curr) => {
		const currentSubCase: any =
			cloneOfac[
				MAP_CASE_TO_KEY[
					curr as keyof typeof MAP_CASE_TO_KEY
				] as keyof typeof cloneOfac
			];
		currentSubCase?.report?.metadata.forEach((item: { riskScore: string }) => {
			item.riskScore = parseFloat(item?.riskScore ?? 0).toFixed(2);
		});
		currentSubCase?.report?.metadata.sort(
			(a: { riskScore: string }, b: { riskScore: string }) =>
				parseFloat(b?.riskScore) - parseFloat(a?.riskScore)
		);
		currentSubCase?.report?.metadata.forEach((item: { riskScore: string; }) => {
			if (item?.riskScore) {
				item.riskScore += " %";
			}
		});
		acc[curr] = currentSubCase;
		return acc;
	}, {});

	return {
		caseId: caseId,
		totalSubCases: totalSubCases,
		totalMatches: totalMatches,
		tabs: subcases.value,
		selectedTab: subcases.value[0],
		ofacCases,
	};
};
