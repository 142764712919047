import { useCallback } from "react";
import { useRecoilState } from "recoil";

import { InvestorsDetails } from "./state";
import { useNotification } from "hooks";
import { IInvestor, IInvestorDetails } from "../constant";

export const useInvestorDetails = () => {
    const [totalInvestors, setTotalInvestors] = useRecoilState<IInvestor[]>(InvestorsDetails);
    const {errorNotification} = useNotification()
    const disableExtraRows = useCallback(() => {
		if ((totalInvestors).length) {
			let sum = 0;
			let sumIndex = -1;
			totalInvestors.forEach((item, i) => {
				sum += Number(item.shareOwned.value ?? 0);
				if (sum >= 51 && sumIndex === -1) {
					sumIndex = i;
				}
			});
			if(sumIndex > -1) {
				setTotalInvestors((prev) => {
					const prevState = structuredClone(prev);
					return prevState.map((investor, index) => {
						if(index > sumIndex) {
							return ({...investor, disabled: true})
						}else {
							return investor
						}
					})
			  })
			}			
		}
	},[setTotalInvestors, totalInvestors])

    const ValidateSubmit = useCallback(() => {
		if(totalInvestors.length){
			const userWithError = totalInvestors.find((investor) => {
				return Object.keys(investor).some((key) => investor[key as keyof IInvestorDetails]?.error);
			});

			if(userWithError){
				errorNotification("Fix all the errors in the Shareholders/Owners table");
				return;
			}
			const sharesSum = totalInvestors.reduce((acc: number, curr: IInvestor, index: number) => {
				if (index > 0 && (Number(curr.shareOwned) >= Number(totalInvestors?.[index - 1]?.shareOwned))) {					
					errorNotification("Users should be arranged in descending order of share ownership");
				}
				if (Number(curr.shareOwned) <= 0) {
					errorNotification("Share owned should be greater than 0");
				}
				acc += Number(curr.shareOwned || 0);
				return acc;
			}, 0);
			if(sharesSum >= 51 && sharesSum < 100) {
				errorNotification('niche ke row disable kar do')
			}
			if(sharesSum > 100) {
				errorNotification("Total shares owned cannot be more than 100%");
			}
		}else {
			errorNotification("Investors Table should ")
		}
	},[errorNotification, totalInvestors])

    return {disableExtraRows, ValidateSubmit}
}