// packages import
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NoDataAvailable, SkeletonTable } from '@storybook';

// local components
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { useTrackEvents } from 'helpers';
import { invoicesState, SelectedDateRangeState } from 'global-stores';

import { InvoiceTbody } from './invoice-tbody';
import { useFormatDate } from '../hook/farmatDate';

// styles import
import './invoices.scss';

export const Invoices = () => {
	// global states
	const [invoicesData, setInvoicesData] = useRecoilState(invoicesState);
	const dateRange = useRecoilValue(SelectedDateRangeState);

	// hooks
	const { get: getInvoiceData, data: invoiceData, loading } = useNetwork();
	const { formatDate } = useFormatDate();
	const { trackEvents } = useTrackEvents();

	// this will fetch the invoices list
	useEffect(() => {
		if (!invoicesData?.length) {
			getInvoiceData(API_URL.INVOICE);
		}
		// eslint-disable-next-line
	}, []);

	// this will set the invoices list to the global state
	useEffect(() => {
		if (invoiceData) {
			trackEvents('billing-info-invoice', { invoice: invoiceData.data });
			setInvoicesData(invoiceData.data);
		}
		// eslint-disable-next-line
	}, [invoiceData]);

	const { endDate, startDate }: any = dateRange?.[0] ?? {};
	const fromDates = new Date(formatDate(startDate)).getTime();
	const endDates = new Date(formatDate(endDate)).getTime();

	const filteredInvoice = useMemo(
		() =>
			invoicesData
				?.map((invoice: any) => {
					const date = new Date(formatDate(invoice.date)).getTime();
					if (date >= fromDates && date <= endDates) {
						return invoice;
					}
					return null;
				})
				.filter((item: any) => item),
		[endDates, formatDate, fromDates, invoicesData]
	);

	const renderTableBody = useMemo(() => {
		return filteredInvoice?.map((invoice: any) => {
			return (
				<tr key={invoice?.date} className="invoice-table--row-border">
					<InvoiceTbody invoice={invoice} />
				</tr>
			);
		});
	}, [filteredInvoice]);

	return (
		<div className="invoices-detail hover">
			{loading && invoicesData?.length === 0 ? (
				<div className="invoice-wrapper">
					<div className="billing-detail-invoice-headers">
						<div className="billing-detail-title invoices--title">Invoices</div>
						<div className="invoices--subtitle">
							Access all your previous invoices{' '}
						</div>
					</div>
					<div className="billing-detail-loader">
						<SkeletonTable listsToRender={4} numberColumn={6} />
					</div>
				</div>
			) : (
				<>
					{
						<div className="invoice-wrapper">
							<div className="billing-detail-invoice-headers">
								<div className="billing-detail-title invoices--title">
									Invoices
								</div>
								<div className="invoices--subtitle">
									Access all your previous invoices{' '}
								</div>
							</div>
							<div className="invoice-table-wrapper">
								<table className="invoice-table">
									<thead className="Invoices--table-head">
										<tr>
											<th className="invoice-table-header">
												<div className="invoice-table-header_invoice">
													Invoice No.
												</div>
											</th>
											<th className="invoice-table-header">Date & Time</th>
											<th className="invoice-table-header">Amount (USD)</th>
											{/* <th className="invoice-table-header">Actions</th> */}
											<th className="invoice-table-header">Status</th>
											<th className="invoice-table-header invoice-table-header__download">
												Actions
											</th>
										</tr>
									</thead>
									<tbody>{renderTableBody}</tbody>
								</table>
								{(!filteredInvoice || filteredInvoice?.length === 0) && (
									<div className="Billing--empty-state">
										<NoDataAvailable
											illustration={'invoice-illustration.svg'}
											description="You do not have any invoices yet."
											message="No Invoices Available"
											hideBtn
											className="empty-invoice-table"
										/>
									</div>
								)}
							</div>
						</div>
					}
				</>
			)}
		</div>
	);
};
