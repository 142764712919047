import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { useNetwork, useNotification } from 'hooks';
import { Button, Input, Loader } from '@storybook';
import { MFA_MESSAGES } from './constant';
import { API_URL } from 'constant';
import { useSignup } from 'views/authentication';
import { LoginStep, MfaTokenForVerifyState, userIdbeforeLoginState } from 'components/login/store';

import './mfa-authentication.scss';

export const MfaAuthentication = () => {
	// Hooks
	const { errorNotification } = useNotification();
	const { fetchUserDetails } = useSignup();
	const { post, loading: mfa_loading } = useNetwork({ returnResponse: true });
	
	// local states
	const [mfaCode, setMfaCode] = useState('');
	const [IsBackUpVerify, setBackUpVerify] = useState(false);
	const [verifiedLoading, setVerifiedLoading] = useState(false);

	// recoils
	const mfaTokenForVerify = useRecoilValue(MfaTokenForVerifyState);
	const userIdbeforeLogin = useRecoilValue(userIdbeforeLoginState);
	const resetLoginCode = useResetRecoilState(LoginStep);

	const handleVerify = useCallback(async () => {
		if (!IsBackUpVerify) {
			const payload = {
				mfaCode: mfaCode,
				userId: userIdbeforeLogin,
			};
			const headers = {
				Authorization: `Bearer ${mfaTokenForVerify}`,
				accountMode: 'live',
			};

			const resp = await post(API_URL.MFA_VERIFY, payload, headers);
			if (resp?.verified) {
				setVerifiedLoading(true);
				fetchUserDetails(resp?.token ?? "", true,'live', true);
			} else {
				setMfaCode('');
				setVerifiedLoading(false);
				errorNotification('Please enter correct authentication code');
			}
		} else {
			const payload = {
				type: 'verify',
				backupCode: mfaCode,
				userId: userIdbeforeLogin,
			};

			const headers = {
				Authorization: `Bearer ${mfaTokenForVerify}`,
				accountMode: 'live',
			};

			const resp = await post(API_URL.BACK_UP_CODES, payload, headers);
			if (resp?.verified) {
				setVerifiedLoading(true);
				fetchUserDetails(resp?.token, true,'live', true);
			} else {
				setMfaCode('');
				setVerifiedLoading(false);
				errorNotification(resp?.errorData?.message);
			}
		}
	}, [
		IsBackUpVerify,
		mfaCode,
		userIdbeforeLogin,
		mfaTokenForVerify,
		post,
		fetchUserDetails,
		errorNotification,
	]);

	const handleBackupSelect = useCallback(() => {
		setBackUpVerify(prev => !prev);
		setVerifiedLoading(false);
		setMfaCode('');
	}, []);

	const renderSectorTitle = useMemo(() => {
		if (IsBackUpVerify) {
			return (
				<div>
					<div className="mfa-auth__title">{MFA_MESSAGES.BACK_UP_HEADING}</div>
					<div className="mfa-auth__description">
						{MFA_MESSAGES.BACK_UP_SUB_HEADING}
					</div>
				</div>
			);
		} else
			return (
				<div>
					<div className="mfa-auth__title">{MFA_MESSAGES.HEADING}</div>
					<div className="mfa-auth__description">
						{MFA_MESSAGES.SUB_HEADING}
					</div>
				</div>
			);
	}, [IsBackUpVerify]);

	const handleButtonState = useMemo(() => {
		return  mfa_loading || verifiedLoading || mfaCode.length < 4;
	}, [verifiedLoading, mfaCode.length, mfa_loading]);

	const handleMoveToLoginScreen = useCallback(()=>{
		resetLoginCode();
	},[resetLoginCode])

	const renderbackButton = useMemo(() => {
		if (IsBackUpVerify) {
			return (
				<div
					onClick={handleBackupSelect}
					className="create-account mfa-auth__pointer"
				>
					<span>Back</span>
				</div>
			);
		} else
			return (
				<>
					<div className="create-account mfa-auth__login">
						<span>Log in using </span>
						<div className="go-to-signup" onClick={handleBackupSelect}>
							backup verification code
						</div>
					</div>
					<div onClick={handleMoveToLoginScreen} className="create-account">
						<span>Back to </span>
						<div className="go-to-signup">login</div>
					</div>
				</>
			);
	}, [IsBackUpVerify, handleBackupSelect, handleMoveToLoginScreen]);

	const renderLabel = useMemo(() => {
		if (IsBackUpVerify) {
			return 'Backup verification code';
		} else {
			return 'Login code';
		}
	}, [IsBackUpVerify]);

	const handleVerifyLabel = useMemo(() => {
		if (mfa_loading || verifiedLoading) {
			return <Loader type="loader" dimension={20} />;
		} else {
			return 'Verify';
		}
	}, [verifiedLoading, mfa_loading]);

	return (
		<div className="mfa-auth">
			<div className="mfa-auth__wraper">
				<div className="mfa-auth__inner_wrapper">
					<div className="mfa-auth__inner">
						{renderSectorTitle}
						<Input
							label={renderLabel}
							inputType={'text'}
							value={mfaCode}
							placeholder={renderLabel}
							handleChange={e => setMfaCode(e.target.value)}
						/>
						<Button
							label={handleVerifyLabel}
							handleClick={handleVerify}
							disabled={handleButtonState}
							type={'button__filled--primary button__large button__block'}
						/>
						{renderbackButton}
					</div>
				</div>
			</div>
		</div>
	);
};
