import { REACT_APP_CDN_URL } from 'envs';
import { IInviteNavigateModalSteps } from 'global-stores';
import { IEnvironmentHost } from 'helpers';
interface IBrandColor {
	colorName: string;
	color: string;
	class: string;
}

interface IBrandFont {
	font: string;
	class: string;
}

export const REGEX_ACCEPT_ONLY_NUMBER = /^[0-9\b]+$/;

export const REGEX = {
	// eslint-disable-next-line no-useless-escape
	isValidAppStoreUrl : /^(https?:\/\/(?:www\.)?(apple\.com|itunes\.apple\.com|developer\.apple\.com)\/[^\s]*)$/,
	// eslint-disable-next-line no-useless-escape
	isValidPlayStoreUrl : /^https:\/\/play\.google\.com(\/store\/apps\/details\?id=[a-zA-Z0-9._\-]+(&.*)?)?\/?$/,
	// eslint-disable-next-line no-useless-escape
	isValidUrl: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/,
	isValidKey: /^[^\s]+$/
}

export const LOGO = {
	LIGHT:
		'https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png',
};

export const BRAND_LOGO =
	'https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-icon.png';

export const RESPONSIVE_LOGO =
	window.innerWidth < 768 ? BRAND_LOGO : LOGO.LIGHT;

export const BRAND_PRIMARY_COLOR: IBrandColor[] = [
	{ colorName: 'blue', color: '#0051cc', class: 'color-blue' },
	{ colorName: 'red', color: 'red', class: 'color-red' },
	{ colorName: 'black', color: 'black', class: 'color-black' },
	{ colorName: 'orange', color: 'orange', class: 'color-orange' },
];

export const BRAND_FONT_SIZE: IBrandFont[] = [
	{ font: 'small', class: 'font-small' },
	{ font: 'default', class: 'font-default' },
	{ font: 'large', class: 'font-large' },
];

export const IMAGE_GCP_URL = REACT_APP_CDN_URL + '/images/simplici-media';

export const SENTRY_URL =
	'https://2c28c59194382675e97633c6e48497a3@o4505663140724736.ingest.sentry.io/4505663595675648';

export const WEBSITE_HOST = {
	prod: 'https://app.simplici.io',
	pp: 'https://admin.pp.satschel.com',
	stage: 'https://admin.stage.satschel.com',
};
export const SocialLink = [
	{
		url: 'https://www.linkedin.com/company/simplici/',
		icon: 'ri-linkedin-fill',
	},
	{
		url: 'https://twitter.com/simplici2023',
		icon: 'ri-twitter-x-fill',
	},
];

export const ASSETS = {
	NOTSUPPORTED_SVG: 'Mask_Group_5.svg',
};

export const MESSAGE = {
	ERROR: 'Oops! Something went wrong.',
	ERROR_TRY: 'Oops! Something went wrong. Please try again later.',
};

export const getLogos: any = {
	default: 'Bank_Default.svg',
	'Navy Federal Credit Union': 'Navy_Federal_Credit_Union.svg',
	Fidelity: 'Fidelity.svg',
	Wealthfront: 'Wealthfront.svg',
	'TD Bank': 'TD.svg',
	'Citibank Online': 'Citi.svg',
	'Citizens Bank': 'Citizens.svg',
	Chase: 'Chase.svg',
	'Bank of America': 'Bank_of_America.svg',
	USAA: 'USAA.svg',
	'Regions Bank': 'Regions_Financial_Corporation.svg',
	'Charles Schwab': 'Charles_Schwab_Corporation.svg',
	'American Express - Personal Savings': 'American_Express.svg',
	'American Express': 'American_Express.svg',
	'Digital Federal Credit Union': 'DigitalFederal_Credit_Union.svg',
	'Wells Fargo': 'Wells_Fargo.svg',
	'Marcus by Goldman Sachs': 'Marcus_By_Goldman_Sachs.svg',
};

export const TOAST_MESSAGES = {
	ERROR: 'Oops! Something went wrong.',
};

export const NewAppLink = 'https://simplici.io';

export const FallBackErrorMessage = {
	HEADING: "We're sorry, but it seems like something went wrong",
	DESCRIPTION:
		'We apologize for any inconvenience this may cause and appreciate your patience.',
	DESCRIPTION_2: 'Our team is already on it and working to resolve the problem',
};

export const USER_LINEAR_FLOW_DISABLED = [
	'6312047e127944ae8e147bfe', //Austin Prod
	'654884bc547dc65a687659ca', //Sourabh PP
	'65268a6f9b3ed1a5914b6631', //Sourabh Stage
	'651bd5b3e67631df65ceb333', //Sourabh Prod
];

export const companySatschelURL = [
	'https://admin.stage.satschel.com',
	'https://admin.pp.satschel.com',
	'https://app.simplici.io',
	'https://admin.test.satschel.com',
	'https://beta.simplici.io',
];

export const ALLOWEDDATADOGLOGSURLS = [
	'https://app.simplici.io',
	'https://admin.pp.satschel.com',
	'https://admin.stage.satschel.com',
];

type IEnvironment = { [key in IEnvironmentHost]: string };

export const environmentHost: IEnvironment | any = {
	nonStage: 'stage', // this has been added temporarly
	stage: 'stage',
	preprod: 'preprod',
	prod: 'prod',
};

export const sandboxEnvHost: IEnvironment | any = {
	nonStage: 'stage', // this has been added temporarly
	stage: 'stage',
	preprod: 'stage',
	prod: 'preprod',
};

export const environmentUrl: IEnvironment | any = {
	nonStage: 'https://esign.stage.satschel.com/', // this is added temperorly
	stage: 'https://esign.stage.satschel.com/',
	preprod: 'https://esign.pp.satschel.com/',
	prod: 'https://esign.simplici.io/',
	pp: 'https://esign.pp.satschel.com/',
	beta: 'https://esign.beta.simplici.io/',
};

export const isMobileView = window.innerWidth < 600;

/**
 * session out time is 30 mins
 * */
export const sessionOutTime = 1800000;
// export const sessionOutTime = 30000

export const INVITE_MODAL_STEPS: {
	[key in Uppercase<IInviteNavigateModalSteps>]: IInviteNavigateModalSteps;
} = {
	CREATE: 'create',
	CSV: 'csv',
	SIGNOPTIONS: 'signOptions',
	CONFIGCOMPLEX: 'configComplex',
	SIGNAGGREMENT: 'signAggrement',
	PROOFREADING: 'proofReading',
	CONNECTBANK: 'connectBank',
	CONFIGCAPTABLE: 'configCaptable',
	BILLING: 'billing',
	SELECTBANKFLOW: 'selectBankFLow',
	PERSONALFORM: 'personalForm',
	BUSINESSFORM: 'businessForm',
	ACCOUNTPROCESSING: 'accountProcessing',
	ESCROWSUCCESS: 'escrowSuccess',
	REMINDERNOTIFICATION: 'reminderNotification',
};

export type IPacketStatus = 'completed' | 'configured' | 'voided';

export const PACKET_STATUS: {
	[key in Uppercase<IPacketStatus>]: Lowercase<key>;
} = {
	COMPLETED: 'completed',
	CONFIGURED: 'configured',
	VOIDED: 'voided',
};

export const createdAt = new Date('1-1-2021');

export const ROUTE_TAB_NAMES = ['esign-inbox', 'esign-sent'];

export const DocumentAccessMessage = {
	PROMPT_MESSAGE: 'Enter the password to access the document.',
	ERROR_MESSAGE: 'Invalid password. Access denied. Please try again.',
};

export const MODAL_TYPE = {
	LEAVE: 'leave',
	DELETE: 'delete',
};

export type ILoadingStatus = 'idle' | 'pending' | 'success';

export const LOADING_STATUS: {
	[key in Uppercase<ILoadingStatus>]: Lowercase<key>;
} = {
	IDLE: 'idle',
	PENDING: 'pending',
	SUCCESS: 'success',
};
