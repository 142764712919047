import { IData } from 'global-stores/sessions/type';
import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';
import { ActionButton } from '../action';
import { NoData } from '../no-data-available';
import { SessionDetailsHeading } from '../session-details-heading';
import { AmlVerifyIntoJsx } from './aml-verify';
import { ReactDropdown } from '@storybook';
import { KYBDownloadOption } from '../kyb-wrapper/constant';
import { useSessionDetailsActions } from 'views/sessions/hooks/use-session-actions';

interface IAddresss {
	countryCode: string;
	city: string;
	stateProv: any;
}

export interface IAmlInfo {
	name: string;
	positions: string;
	address: IAddresss;
	pepWatchList: any;
	riskscore: number;
	matchScore: number;
	adverseMedia: any;
	registered_events: any[];
	sanctionWatchList: any;
	dob: string;
	aliases: any;
}

export interface IAml {
	info: IAmlInfo;
	transactionId: any;
	status: string;
}

export interface IValue {
	value: string;
}

interface IBackgroundCheck {
	aml: IData;
	role: string;
	isAgentPermitted?: boolean;
	isTransactionPage: boolean;
	firstName: IValue;
	lastName: IValue;
	dateOfBirth: IValue;
	isLoading: boolean;
	isLoaded?: boolean;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
}
export const BackgroundCheck = ({
	aml,
	role,
	isAgentPermitted,
	isTransactionPage,
	firstName,
	lastName,
	dateOfBirth,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite = true,
}: IBackgroundCheck) => {
	const { exportToJson } = useSessionDetailsActions();

	const handleChangeSelect = (event: any) => {
		const { value } = event;
		if (value === 'json') {
			exportToJson(aml, 'AML.json');
		}
	};
	return (
		<>
			<div className="kycDetails-contain">
				{/* Pradeep Chaurasia : label and icon changed as per the UI design */}
				<SessionDetailsHeading
					label="AML PEPs and Sanctions"
					icon={
						<i className="ri-coins-fill form-icon-profile-container__formIcon" />
					}
				>
					{Object.values(aml ?? {}).length > 0 && (
						<div className="kyb-wrapper_download-wrapper">
							<ReactDropdown
								options={KYBDownloadOption}
								value={{ label: 'Download', value: 'download' }}
								defaultValue={{ label: 'Download', value: 'download' }}
								handleChangeSelect={handleChangeSelect}
								controlStyle={{ minHeight: 40, width: 200 }}
							/>
							<div className="action-btn">
								{role === 'AGENT' && !isAgentPermitted ? (
									<div className="action-btn__agent">{aml?.status}</div>
								) : (
									<ActionButton
										status={aml?.status}
										type="aml"
										isTransactionPage={isTransactionPage}
										isLoading={isLoading}
										nodeId={nodeId}
										isDisabled={!isUserPermissionWrite}
									/>
								)}
							</div>
						</div>
					)}
				</SessionDetailsHeading>

				{!isLoading && isLoaded ? (
					<>
						{Object.values(aml ?? {}).length > 0 ? (
							<div className="aml-details">
								<div
									className={`kycDetails-body kycDetails-body${
										isTransactionPage ? '--aml-transactions-page' : ''
									}`}
								>
									<AmlVerifyIntoJsx
										firstName={firstName}
										lastName={lastName}
										dateOfBirth={dateOfBirth}
										aml={aml}
									/>
								</div>
							</div>
						) : (
							<NoData />
						)}
					</>
				) : (
					<SessionDetailsSkeletonLoader />
				)}
			</div>
		</>
	);
};
