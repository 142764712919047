import {atom} from "recoil";

export const IsMultiSignModalOpen = atom<boolean>({
    key: "is-multi-sign-modal-open",
    default: false
})

export const IsSessionChartLoadingState = atom<boolean>({
    key: "is-session-chart-loading-state",
    default: false
})

export const IsOverlayMultiSignModalOpen = atom<boolean>({
    key: "is-overlay-multi-sign-modal-open",
    default: false
})

export const MultiSignPrepareUrl = atom<{envelopeId: string, prepareUrl: string} | Record<string, never>>({
    key: "multi-sign-prepare-url",
    default: {}
})

export const OverlayMultiSignPrepareUrl = atom<{templateId: string, prepareUrl: string} | Record<string, never>>({
    key: "overlay-multi-sign-prepare-url",
    default: {}
})

export const SignAgreementComplexStepConfigured = atom<{
	[key in string]: { status: 'pending' | 'configured' | "reconfigure" | 'saved'; envelopeId?: string, templateId?: string };
}>({
	key: 'sign-agreement-step-configured-flag-key',
	default: {},
});

export const OverlaySignAgreementEnvelopeId = atom<{envelopeId: string, status : "pending" | "configured" }>({
    key: "overlay-sign-agreement-envelope-id",
    default : {
        envelopeId: "",
        status: "pending"
    }
})

export const SelectedComplexSignAgreementStep = atom<string | null>({
    key: "selected-sign-agreement-step-state-key",
    default: null,
})

export const LinearMultiSignAgreementStepConfigured = atom({
    key: "linear-multi-sign-agreement-ste-configued",
    default: false,
})

export const CaptableOldState = atom({
    key: "captable-old-state-key",
    default: {},
})