import { ReactDropdown, ReactSwitch } from '@storybook';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { ExportPdf } from '../export-pdf';
import { renderColor } from './util';
import { useSessionDetailsActions } from 'views/sessions/hooks/use-session-actions';
import { DownloadActions } from 'views/sessions/constants';
import {
	ComplexSessionInfoState,
	ComplexSessionNodesState,
	ConditionalSessionFlowState,
	FundDetailState,
	PreviousSessionDataState,
	SelectedSessionsIdState,
	SessionItemSelected,
	SessionState,
} from 'global-stores';
import { useComplexSessionInfo } from 'views/sessions/hooks';
import { ColorLegends } from '../color-legends';

interface IKycDetailsHeader {
	isTransactionPage?: boolean;
	handleBackModal?: () => void;
	sessionSelected: {
		name?: string;
		_id?: string;
		type?: string;
		representativeId?: string;
	};
	isComplexFlow: boolean;
	setIsComplexFlow: any;
	getLabel?: (val: string) => any;
	isFundView?: boolean;
	isShowTreeFlow?: boolean;
}

export const SessionDetailsHeader: FC<IKycDetailsHeader> = ({
	sessionSelected,
	handleBackModal,
	isTransactionPage,
	isComplexFlow,
	getLabel,
	setIsComplexFlow,
	isFundView = false,
	isShowTreeFlow = true,
}) => {
	const isComplexTab = useRecoilValue(ConditionalSessionFlowState);
	const sessionList = useRecoilValue(SessionState);
	const selectedIdState = useRecoilValue(SelectedSessionsIdState);
	const fundDetailList = useRecoilValue(FundDetailState);
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const setComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const { complexSessionInfo } = useComplexSessionInfo();
	const previousSessionData = useRecoilValue(PreviousSessionDataState);
	const resetComplexSessionInfo = useResetRecoilState(ComplexSessionInfoState);
	const { exportCsv, exportPdf } = useSessionDetailsActions();

	const SessionListFullStatus = useMemo(() => {
		if (isFundView) {
			return fundDetailList.find(
				value => value.sessionId === selectedIdState[0]
			)?.fullStatus;
		} else {
			return sessionList.find(value => value._id === selectedIdState[0])
				?.fullStatus;
		}
	}, [selectedIdState, sessionList, fundDetailList, isFundView]);

	const handleChangeDownloadAction = useCallback((event: any) => {
		const { value } = event ?? {};
		if (value === 'csv') {
			exportCsv(sessionSelected._id ?? '', sessionSelected._id ?? 'session');
		} else {
			exportPdf([sessionSelected._id], 'pdf');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// @paras: this back button handler is only for kyc-aml verification members screen
	const handleKycAmlBackButton = useCallback(() => {
		if (previousSessionData) {
			const sessionId = previousSessionData?._id;
			const allNodes = previousSessionData?.nodes?.map(el => {
				return {
					...el,
					visited: true,
					session: sessionId || '',
				};
			});
			resetComplexSessionInfo();
			setComplexSessionNodes(allNodes ?? []);
			complexSessionInfo(sessionId ?? '');
			setSessionItemSelected({
				...previousSessionData,
				type: 'complex',
				name: previousSessionData?.name ?? 'NaN',
				_id: sessionId || '',
			});
			setSelectedIdState([sessionId ?? '']);
		}
	}, [
		complexSessionInfo,
		previousSessionData,
		setComplexSessionNodes,
		setSelectedIdState,
		setSessionItemSelected,
		resetComplexSessionInfo,
	]);

	return (
		<div className="kyc--Details--header--sticky">
			<div
				className={`kycDetails--header--export-pdf kycDetails--header--sticky ${
					isTransactionPage ? 'kyc-details-transaction-top-header' : ''
				}`}
			>
				<div className="kycDetails--header_detail kycDetails--header--sticky align__back_icon">
					{isTransactionPage && (
						<div onClick={handleBackModal} className="arrow__left_icon_main">
							<i className="ri-arrow-left-line fa-lg" />
						</div>
					)}
					{/* @paras: this back button is only for kyc-aml verification members screen */}
					{sessionSelected?.representativeId?.length && (
						<div
							onClick={handleKycAmlBackButton}
							className="arrow__left_icon_main"
						>
							<i className="ri-arrow-left-line fa-lg" />
						</div>
					)}

					<Fragment>
						<div className="kycUserName">
							<span>{sessionSelected.name}</span>
						</div>
						{!isComplexTab && !isTransactionPage && (
							<div className="Session-show-status">
								{Object.keys(SessionListFullStatus ?? {})?.map(keys => {
									const colors: any = renderColor(
										keys,
										SessionListFullStatus?.[keys as any] as string
									);

									return (
										<div
											key={'status-circle' + keys}
											className="Session-show-status-contain"
										>
											<div
												className="Session-show-status-circle"
												style={{ backgroundColor: colors.color }}
											/>
											<div>
												{getLabel && getLabel(keys)}
												{colors.status?.length > 0 && ` (${colors.status})`}
											</div>
										</div>
									);
								})}
							</div>
						)}
					</Fragment>
				</div>
				<div className="kycDetails--header--toggleContainer">
					{isComplexTab && isShowTreeFlow && (
						<div
							className="kycDetails--header--toggleContainer_togglebtn
						"
						>
							<ColorLegends title={'Status & Flow Legends:'} />
							<div className="kycDetails--header--toggleContainer_row">
								<div className="kycDetails--header--toggleContainer_text">
									Tree Flow
								</div>
								<ReactSwitch
									id={''}
									onColor="#33b87a"
									checked={isComplexFlow}
									handleChange={() =>
										setIsComplexFlow((prev: boolean) => !prev)
									}
								/>
							</div>
						</div>
					)}
					{/* // pradeep : removed complex condition for download zip */}
					{
						<div>
							{sessionSelected.type === 'complex' ? (
								<ReactDropdown
									handleChangeSelect={handleChangeDownloadAction}
									options={DownloadActions}
									defaultValue={{ label: 'Download' }}
									controlStyle={{ width: 215 }}
								/>
							) : (
								<ExportPdf
									sessionIDs={[sessionSelected._id]}
									type={'pdf'}
									label={'Download'}
								/>
							)}
						</div>
					}
				</div>
			</div>
		</div>
	);
};
