/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import {
	ComplexSelectedFundRecipientBankAccount,
	FundRecipientBankAccount,
	FundsState,
	RecipientBankAccountLoadingState,
	SelectedDocsForUploadState,
	SelectedFundType,
	SelectedInviteFundState,
	SelectedPipelineDetails,
	UploadedDocsState,
} from 'global-stores';
import { FundInvestment } from './fund-investment';
import { useNetwork, useNotification } from 'hooks';
import { API_URL, MESSAGE } from 'constant';
import {
	PlaidLinkOnEvent,
	PlaidLinkOnExit,
	PlaidLinkOnSuccess,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';

import {
	ComplexConfigStep,
	ComplexConfigurationInvite,
	ComplexSaveState,
	FundsIdState,
	SIGN_OPTIONS,
	ShowMultiSignTemplateSelectionScreen,
	ShowSignOptionInQr,
	SignOptionState,
} from './store';

import { InviteChooseFund } from './invite-choose-fund';
import { SessionTreeGraph } from 'views/complex-onboarding-flow/components/tree-graph-chart/session-tree-chart';
import { MultiSignAgreement, IsMultiSignModalOpen, CaptableOldState, SignAgreementComplexStepConfigured, IsOverlayMultiSignModalOpen } from 'views/multi-sign-agreement';
import { deepCompareLucksheetData, getLuckSheetData } from 'utils';
import { UploadProofDocument, UploadProofDocumentState } from 'views/upload-proof-document';
import { proofReadingKey } from '../constants';

import { MultisignTemplateSelection } from 'views/multisign-template-selection';
import { SignOption } from './sign-option';
import { SignAgreement } from 'views/sign-aggrement';
import { OverlayMultiSignAgreement } from 'views/multi-sign-agreement/components';

export const ComplexConfig = () => {

	const pipelines = useRecoilValue(SelectedPipelineDetails);
	const [configStep, setConfigStep] = useRecoilState(ComplexConfigStep);
	const { post: generateToken, data: tokenResponse } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const [configuration, setConfiguration] = useRecoilState(
		ComplexConfigurationInvite
	);
	const [addedFiles, setAddedFiles] = useRecoilState(UploadedDocsState);
	const setComplexSave = useSetRecoilState(ComplexSaveState);

	const [isCreate, setIsCreate] = useState(true);
	const [isNewUpload, setIsNewUpload] = useState(false);
	const [selectedFund, setSelectedFund] = useRecoilState(
		SelectedInviteFundState
	);
	const fundType = useRecoilValue(SelectedFundType);
	const setFundsIdState = useSetRecoilState(FundsIdState);
	const setFunds = useSetRecoilState(FundsState);
	const { post: createFund, loading: creatingComplexFund } = useNetwork();
	const funds = useRecoilValue(FundsState);
	const isMultiSignerModalOpen = useRecoilValue(IsMultiSignModalOpen);
	const signAgreementFlowType = useRecoilValue(SignOptionState);
	const resetSignComplexStepConfigs = useResetRecoilState(SignAgreementComplexStepConfigured)
	const oldCaptableData = useRecoilValue(CaptableOldState);
	const [IsShowSignOptionVisible, setIsShowSignOptionVisible] =
		useRecoilState(ShowSignOptionInQr);
	const [
		isShowMultiSignTemplateSelectionScreen,
		setIsShowMultiSignTemplateSelectionScreen,
	] = useRecoilState(ShowMultiSignTemplateSelectionScreen);
	const [ isOverlayConfigOpen, setIsOverlayConfigOpen] = useRecoilState(IsOverlayMultiSignModalOpen);

	const setLoading = useSetRecoilState(RecipientBankAccountLoadingState);

	const fundId = useRecoilValue(FundsIdState);
	const uploadProofDocument = useRecoilValue(UploadProofDocumentState);

	// Pradeep chaurasia : state for selected files
	const [selectedFiles, setSelectedFiles] = useRecoilState(
		SelectedDocsForUploadState
	);

	const onSelectionChange = useCallback((type: boolean) => {
		setIsNewUpload(type)
	}, [])


	const handleConfig = useCallback(
		(currentStep: string) => {
			if (currentStep) setConfigStep({ ...configStep, key: currentStep });
		},
		[configStep, setConfigStep]
	);

	const [token, setToken] = useState('');

	const { post: submitExchangeToken } = useNetwork();

	// constants
	const { proofVerification } = proofReadingKey;

	const changeComplexStatus = useCallback(() => {
		// pradeep chaurasia : update added files on save
		setAddedFiles(prevState => {
			const clonedPrev = structuredClone(prevState);
			const updatedVal = selectedFiles
				.filter(el => el.node === configStep.id)
				?.filter(
					item =>
						!clonedPrev.find(el => el._id === item._id && el.node === item.node)
				);
			return [...clonedPrev, ...updatedVal];
		});
		setTimeout(() => {
			setComplexSave(true);
		}, 50);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFiles]);

	const handleGenerateToken = useCallback(() => {
		const payload = {
			language: 'en',
			countryCodes: ['US'],
		};
		generateToken(API_URL.GenerateLinkToken, payload);
	}, [generateToken]);

	const { get: fetchBusinessBankAccounts } = useNetwork();
	const [fundRecipient, setFundRecipient] = useRecoilState(
		FundRecipientBankAccount
	);

	const [subSelectedItem, setSubSelectedItem] = useRecoilState(
		ComplexSelectedFundRecipientBankAccount
	);

	const [selectedItem, setSelectedItem] = useState<any>();
	const [selectedIndex, setSelectedIndex] = useState(0);

	/**
	 * here we are comparing weather the details in the captable have been edited by the user or not by going back to thr previous screen
	 * and according to that we are reseting all the sign steps configuration status so that the changes in the captable can be made available 
	 * in the sign agreement envelope
	 * */ 
	
	useEffect(() => {
		if(signAgreementFlowType === SIGN_OPTIONS.COMMON) {
			const lucksheetData = getLuckSheetData();
			const isEqual = deepCompareLucksheetData(lucksheetData ,oldCaptableData);			
			if(!isEqual) {
				resetSignComplexStepConfigs()
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
		if (fundRecipient?.length === 0) {
			fetchBusinessBankAccounts(API_URL.BusinessBankAccounts).then(res => {
				if (res?.data) {
					setFundRecipient(res.data);
				}
			});
		}
	}, [fetchBusinessBankAccounts, fundRecipient?.length, setFundRecipient]);

	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const payload = {
				bankName: metadata.institution?.name,
				linkToken: token,
				token: publicToken,
			};

			setLoading(true);

			const response = await submitExchangeToken(
				API_URL.TokenExchange,
				payload
			);
			if (response?.id) {
				successNotification('Account Linked Successfully');
				// handleConfig('fundInvestment');
				handleConfig('fundInvestmentVerification');
			}
			setToken('');
			fetchBusinessBankAccounts(API_URL.BusinessBankAccounts)
				.then(res => {
					if (res.data) {
						setFundRecipient(res.data);
						if (
							res.data.length > 0 &&
							res.data[0]?.accounts.length > 0 &&
							Object.keys(subSelectedItem ?? {}).length === 0 &&
							Object.keys(selectedItem ?? {}).length === 0
						) {
							setSelectedItem(res.data[0]);
							setSubSelectedItem((prev: any) => {
								const data = structuredClone(prev);
								data?.push({
									...res.data[0]?.accounts[0],
									_id: res.data[0]?._id,
								});
								return data;
							});
						}
					}
					setLoading(false);
				})
				.catch(err => {
					errorNotification(err.message ?? MESSAGE.ERROR);
					setLoading(false);
				});
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token, selectedItem]
	);

	//not required for now keep it for future use
	const onEvent = useCallback<PlaidLinkOnEvent>(() => ({}), []);

	//not required for now keep it for future use
	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setToken('');
	}, []);

	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onEvent,
		onExit,
	};

	const { open } = usePlaidLink(config);

	useEffect(() => {
		if (tokenResponse?.token) {
			setToken(tokenResponse?.token);
			open();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, tokenResponse?.token]);

	useEffect(() => {
		return () => {
			setConfigStep({ ...configStep, key: '' });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelect = useCallback(
		(event: any) => {
			const { selectedItem, subSelectedItem } = event ?? {};
			setSelectedItem(selectedItem);
			setConfiguration((prev: any) => {
				const data = structuredClone(prev);
				const exist = data['fundInvestment'].findIndex(
					(el: any) => el.node === configStep.id
				);
				const payload = {
					...subSelectedItem,
					node: configStep.id,
					fundId: fundId.fundId,
				};

				// TODO: this is a temp fix
				// we need to add proper fundName logic here
				if (selectedFund?.value !== fundId?.fundId) {
					payload.selectedFund = selectedFund;
				}

				if (exist === -1) {
					data['fundInvestment'].push(payload);
				} else {
					data['fundInvestment'][exist] = payload;
				}
				return data;
			});
			// Arun Kumar: Not clearing state
			setSelectedFund({ label: '', value: '' });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[configStep.id, fundId, setConfiguration, selectedFund]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const props = {
		setSelectedIndex,
		selectedIndex,
		setSelectedItem,
		selectedItem,
		setSubSelectedItem,
		subSelectedItem: configuration['fundInvestment'],
		handleSelect,
	};

	const handleUpload = useCallback(
		(event: any) => {
			const provider = configStep?.actions?.find(
				el => el.actionId === 'signAgreementVerification'
			)?.metadata?.signAgreementProvider;
			// pradeep chaurasia : setAddedFiles remved from here ,  update setSelectedFiles
			setSelectedFiles(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				return [{ ...event, node: configStep.id, provider }, ...prevState];
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[configStep.id]
	);

	const currentSelectedFunds = configuration['fundInvestment'].find(
		(el: any) => el.node === configStep.id
	);

	const chooseFundProps = useMemo(() => {
		return {
			selectedFundItem: currentSelectedFunds?.selectedFund ?? {},
		};
	}, [currentSelectedFunds?.selectedFund]);

	// Deepak: This is added to create complex fund to show adjecent funds
	const handleCreateComplexFund = useCallback(async () => {
		const isAlreadyAvailable = !!funds?.find(
			({ name }) =>
				name?.trim().toLowerCase() === selectedFund?.value?.trim().toLowerCase()
		);

		if (fundType === 'create') {
			if (isAlreadyAvailable) {
				errorNotification('Fund with this name already available.');
				return;
			}
		}
		if (fundType === 'create') {
			if (!isAlreadyAvailable) {
				const createFdResp = await createFund(API_URL.FUNDS, {
					name: selectedFund.value,
				});
				if (createFdResp?._id) {
					const { _id } = createFdResp ?? {};
					setFundsIdState({ fundId: _id });
					setFunds(prev => [...prev, createFdResp]);
					setIsCreate(false);
					successNotification('Fund created successfully');
				} else {
					errorNotification(createFdResp?.message);
					return;
				}
			}
		}
		if (fundType === 'edit') {
			setIsCreate(false);
		}
	}, [
		createFund,
		errorNotification,
		fundType,
		funds,
		selectedFund.value,
		setFunds,
		setFundsIdState,
		setIsCreate,
		successNotification,
	]);


	const handleQrSignOptionNext = useCallback (()=> {
		if (signAgreementFlowType===SIGN_OPTIONS.INDIVISUAL) {
			setIsShowSignOptionVisible(false);
			setIsShowMultiSignTemplateSelectionScreen(false)
		} else {
			setIsShowSignOptionVisible(false);
			setIsShowMultiSignTemplateSelectionScreen(true);
		}
	},[setIsShowMultiSignTemplateSelectionScreen, setIsShowSignOptionVisible, signAgreementFlowType])

	const handleSelectOverlayTemplate = useCallback((file: any) => {
		setSelectedFiles([file]);
	},[setSelectedFiles])

	const renderSignAgreement = useMemo(() => {		
		if(IsShowSignOptionVisible) {
			return {
				component: <SignOption />,
				title: 'Sign Agreement flow Option',
				subTitle:
					'This action allows user to electronically sign a document. Please upload all the documents that require signatures.',
				showFooterBtn: true,
				backBtnLabel: 'Back',
				nextButtonLabel: 'Next',
				handleNext: () => {
					handleQrSignOptionNext()
					// setConfigStep({ ...configStep, key: '' });
				},
				handleBack: () => {
					setIsShowSignOptionVisible(false);
					setConfigStep({ ...configStep, key: '' });
				},
			};
		}
		if (isShowMultiSignTemplateSelectionScreen)
		return {
			component: (
				<MultisignTemplateSelection 
					isAdvance 
					handleChange={handleSelectOverlayTemplate}
					uploadedFiles={selectedFiles.filter(
						(el) => el.node === configStep.id
					)}
					setSelectionChange={onSelectionChange}
					nodeId={configStep.id}
					setSelectedFiles={setSelectedFiles}
					selectedFiles={selectedFiles}
				/>
			),

			title: 'Choose document signing option',
			subTitle:
				'Select how you\'d like to proceed with the signing documents',
			showFooterBtn: true,
			backBtnLabel: 'Back',
			nextButtonLabel: 'Next',
			handleNext: () => {
				setIsOverlayConfigOpen(true);
				setIsShowMultiSignTemplateSelectionScreen(false);
				setConfigStep({ ...configStep, key: '' });
			},
			handleBack: () => {
				setIsShowSignOptionVisible(true);
				setIsShowMultiSignTemplateSelectionScreen(false);
				// setConfigStep({ ...configStep, key: '' });
			},
		};

		return {
			component: (
				<SignAgreement
					handleChange={handleUpload}
					uploadedFiles={selectedFiles.filter(el => el.node === configStep.id)}
					isAdvance
					// pradeep chaurasia : seter function passes as a prop
					setSelectedFiles={setSelectedFiles}
					selectedFiles={selectedFiles}
				/>
			),

			title: 'Agreement Document',
			subTitle:
				'This action allows user to electronically sign a document. Please upload all the documents that require signatures.',
			showFooterBtn: true,
			backBtnLabel: 'Back',
			nextButtonLabel: 'Save and Close',
			handleNext: () => {
				changeComplexStatus();
				setIsShowMultiSignTemplateSelectionScreen(false);
				setConfigStep({ ...configStep, key: '' });
			},
			handleBack: () => {
				const totalSignNodes = pipelines?.nodes?.filter(
					(node: any) => node.stepId === 'signAgreement'
				);
				if(totalSignNodes.length > 1) {
					setConfigStep({ ...configStep, key: '' });
				} else {
					setIsShowSignOptionVisible(true);
				}
			},
		};
		
	}, [IsShowSignOptionVisible, isShowMultiSignTemplateSelectionScreen, setIsShowMultiSignTemplateSelectionScreen, handleSelectOverlayTemplate, selectedFiles, onSelectionChange, configStep, setSelectedFiles, handleUpload, handleQrSignOptionNext, setConfigStep, setIsOverlayConfigOpen, setIsShowSignOptionVisible, changeComplexStatus, pipelines?.nodes]);

	const {
		title,
		subTitle,
		component,
		showFooterBtn = false,
		backBtnLabel,
		nextButtonLabel,
		handleNext,
		handleBack,
	} = useMemo(() => {
		switch (configStep.key) {
			case 'signAgreementVerification':			
			return renderSignAgreement

			// add proofVerification flow
			case proofVerification:
				return {
					component: <UploadProofDocument nodeId={configStep.id} />,

					title: proofReadingKey.DocumentReview,
					subTitle:
						'Upload all the documents that you want the end user to read.',
					showFooterBtn: true,
					backBtnLabel: 'Back',
					nextButtonLabel: 'Save and Close',
					handleNext: () => {
						setConfigStep({ ...configStep, key: '' });
					},
					handleBack: () => {
						setConfigStep({ ...configStep, key: '' });
					},
				};

			case 'fundInvestmentVerification':
				if (isCreate) {
					return {
						component: <InviteChooseFund {...chooseFundProps} />,
						title: 'Fund Detail',
						subTitle: 'Create fund or select fund',
						showFooterBtn: true,
						backBtnLabel: 'Back',
						nextButtonLabel: 'Next',
						handleNext: () => {
							handleCreateComplexFund();
						},
						handleBack: () => {
							setConfigStep({ ...configStep, key: '' });
							setIsCreate(true);
						},
					};
				} else {
					return {
						component: (
							<FundInvestment
								{...props}
								handleGenerateToken={handleGenerateToken}
							/>
						),
						title: 'Fund Detail',
						subTitle: 'Create fund or select fund',
						showFooterBtn: true,
						backBtnLabel: 'Back',
						nextButtonLabel: 'Save and Close',
						handleNext: () => {
							changeComplexStatus();
							setIsCreate(true);
							setConfigStep({ ...configStep, key: '' });
						},
						handleBack: () => {
							setIsCreate(true);
						},
					};
				}

			default:
				return {
					component: <InviteChooseFund />,
					title: 'Fund Detail',
					subTitle: 'Create fund or select fund',
					showFooterBtn: true,
					backBtnLabel: 'Back',
					nextButtonLabel: 'Next',
					handleNext: () => {
						handleCreateComplexFund();
					},
					handleBack: () => {
						setConfigStep({ ...configStep, key: '' });
						setIsCreate(true);
					},
				};
		}
	}, [configStep, renderSignAgreement, proofVerification, isCreate, setConfigStep, chooseFundProps, handleCreateComplexFund, props, handleGenerateToken, changeComplexStatus]);

	// this will manage the disable of the invite btn in the sign agreement section
	const handleDisableSignAggrement = useCallback(() => {
		if (IsShowSignOptionVisible) {
			return false;
		}
		if(isShowMultiSignTemplateSelectionScreen){
			if(isNewUpload) {
				return false
			}else {
				const selectedTempLength = selectedFiles.length;
				return !selectedTempLength
			}
			return true
		}

		const docLength = selectedFiles.length;

		const isAnyCheckedFile = selectedFiles.findIndex(
			({ isChecked, node }) => node === configStep.id && isChecked
		);

		const isAllCheckedConfigured = selectedFiles.findIndex(
			({ configured, isChecked, node }) =>  node === configStep.id &&  isChecked && !configured
		);

		if (
			docLength === 0 ||
			isAnyCheckedFile === -1 ||
			isAllCheckedConfigured > -1
		) {
			return true;
		}
		return false;
	}, [isShowMultiSignTemplateSelectionScreen, IsShowSignOptionVisible, selectedFiles, isNewUpload, configStep.id]);
	
	const isDisabled = useMemo(() => {
		switch (configStep.key) {
			case 'signAgreementVerification':
				return handleDisableSignAggrement();
			case proofVerification:
				return !uploadProofDocument.length;
			case 'fundInvestmentVerification':
				if (!isCreate) {
					return false;
				}
				return !selectedFund.value;
			default:
				return false;
		}
	}, [configStep.key, handleDisableSignAggrement, uploadProofDocument, isCreate, selectedFund.value, proofVerification]);

	return (
		<div className='onboarding-session-tree-graph--container'>
			<SessionTreeGraph
				details={pipelines}
				isInvite
				funds={configuration.fundInvestment ?? []}
				docs={addedFiles ?? []}
			/>

			{/* Shahbaaz: fixed modal fluctuation issue if config step null */}
			{configStep?.key && (
				<Modal
					isOpen={/fundInvestmentVerification|signAgreementVerification|proofVerification/.test(
						configStep.key
					)}
					modalName={'fund investment'}
					className="OnboardingInviteModal-modal configComplex"
					showCloseBtn
					closeModal={() => {
						setIsCreate(true);
						setIsShowSignOptionVisible(false);
						setIsShowMultiSignTemplateSelectionScreen(false);
						setConfigStep({ ...configStep, key: '' });
					}}
					title={
						<div className="OnboardingInviteModal-header">
							<div className="OnboardingInviteModal-head-title">{title}</div>
							<div className="OnboardingInviteModal-head-desc">{subTitle}</div>
						</div>
					}
				>
					{component}
					{showFooterBtn && (
						<div className="OnboardingInviteModal-btn">
							<Button
								handleClick={handleBack}
								label={backBtnLabel}
								type="button__filled button__filled--secondary button__large"
							/>
							<Button
								handleClick={handleNext}
								label={
									!creatingComplexFund ? (
										nextButtonLabel
									) : (
										<Loader
											type="loader"
											dimension={20}
											className="loader-white"
										/>
									)
								}
								type="button__filled button__filled--primary button__large"
								disabled={isDisabled || creatingComplexFund}
							/>
						</div>
					)}
				</Modal>
			)}
			{isMultiSignerModalOpen && <MultiSignAgreement />}
			{ isOverlayConfigOpen && <OverlayMultiSignAgreement/>}
		</div>
	);
};

