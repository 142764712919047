import type { IDocProvider } from './type';

export const SIGN_PROVIDER: { [key in Uppercase<IDocProvider>]: IDocProvider } =
	{
		DOCUSIGN: 'docusign',
		ESIGN: 'esign',
	};

export const QUESTION_TYPES = {
	CHECKBOX: 'checkbox',
	BOOLEAN: 'boolean',
	TEXT: 'text',
}	
export const ALLOWED_CHECKBOX_TYPES = ['checkbox', 'boolean'];
