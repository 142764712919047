export const AML_DETAILS_TABLE: any = {
	GWL: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: "address",
			label: "Addresses",
		  },
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
	EDD: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'title',
			label: 'Titles',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'address',
			label: 'Addresses',
		},
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'NameSource',
			label: 'Name source',
		},
		{
			key: 'Relationship',
			label: 'Relationship',
		},
		{
			key: 'SubCategory',
			label: 'Sub-category',
		},
		{
			key: 'EntityLevel',
			label: 'Entity level',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
	PEP: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'title',
			label: 'Titles',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'address',
			label: 'Addresses',
		},
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'NameSource',
			label: 'Name source',
		},
		{
			key: 'Relationship',
			label: 'Relationship',
		},
		{
			key: 'SubCategory',
			label: 'Sub-category',
		},
		{
			key: 'EntityLevel',
			label: 'Entity level',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
	SOE: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'title',
			label: 'Titles',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'address',
			label: 'Addresses',
		},
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'NameSource',
			label: 'Name source',
		},
		{
			key: 'Relationship',
			label: 'Relationship',
		},
		{
			key: 'SubCategory',
			label: 'Sub-category',
		},
		{
			key: 'EntityLevel',
			label: 'Entity level',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
};
export const AML_INFO_HEADER: { [key: string]: string } = {
	GWL: 'GWL (Global watchlist)',
	EDD: 'EDD (Enhanced Due Diligence)',
	PEP: 'PEP (Politically exposed person)',
	SOE: 'SOE (State owned enterprise)',
};