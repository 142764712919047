import { useRecoilValue } from 'recoil';
import {
	PipelineSettingsFormState,
	SelectedMultipleDateState,
} from 'global-stores';
import { useAddDollarCurrencySurvey } from 'hooks/use-add-dollar-currency';
import { EdgesState, NodesState } from 'views/complex-onboarding-flow';
import { ComplexSettingFormState, SendNotificationState } from 'views/pipeline';
import { usePublishPayload } from './publish-payload';
import { proofReadingKey } from 'views/pipelines';

export const useComplexPublishAPI = () => {
	const nodes = useRecoilValue(NodesState);
	const edges = useRecoilValue(EdgesState);
	const complexSettings = useRecoilValue(ComplexSettingFormState);
	const selectedMultipleDate = useRecoilValue(SelectedMultipleDateState);
	const reminderDayList = useRecoilValue(SendNotificationState);
	const { getPayload } = usePublishPayload();
	const settingForms = useRecoilValue(PipelineSettingsFormState);

	const { modifyPagesResponse } = useAddDollarCurrencySurvey();

	//constants
	const { proofVerification } = proofReadingKey;

	const getConfig = (steps: any) => {
		const configsteps = steps
			?.filter((el: any) =>
				/publish|deliveryMethod|successScreen/.test(el.stepId)
			)
			?.reduce((acc: any, crr: any) => {
				return { ...acc, [crr.stepId]: crr };
			}, {});

		const updatedPayload = structuredClone(configsteps);
		const step = updatedPayload['deliveryMethod'];
		const action = step?.actions.find(
			(reminder: any) =>
				reminder?.actionId === 'reminderSetting' &&
				reminder?.metadata.reminderSettingTrigger.label === 'Custom'
		);
		if (action) {
			if (settingForms['enableReminderSetting'])
				action.metadata.reminderSettingTrigger.value = reminderDayList.checked
					? selectedMultipleDate || []
					: reminderDayList.days || [];
			else {
				action.metadata.reminderSettingTrigger = '';
			}
		}
		return updatedPayload;
	};

	const transformConditionObj = (json: any) => {
		const { conditions = [] } = json ?? {};
		if (/form/.test(json.key)) {
			const data: any = [];
			const outputObj: any = {};
			conditions.forEach((item: any) => {
				const labelId = item.labelId;
				if (labelId in outputObj) {
					outputObj[labelId].push({
						label: item.label,
						key: item.key,
						nodeId: item.nodeId,
					});
				} else {
					outputObj[labelId] = [
						{
							label: item.label,
							key: item.key,
							nodeId: item.nodeId,
						},
					];
				}
			});
			data.push(outputObj);
			return data ?? [];
		}
		if (json.key === 'dynamicForm') {
			return conditions;
		}
		if (/amlVerification|kybVerification/.test(json.key)) {
			const data = [];
			for (let i = 0; i < conditions.length; i++) {
				const payload = {
					[conditions[i]['and'] ? 'and' : 'or']:
						conditions[i]['and'] ?? conditions[i]['or'],
					then: conditions[i].then,
					nodeId: conditions[i].nodeId,
					key: conditions[i].key,
				};

				data.push(payload);
			}
			return data;
		}
		if (json.key === 'allId') {
			const data = [];

			for (let i = 0; i < conditions.length; i++) {
				const payload = {
					[conditions[i]['and'] ? 'and' : 'or']:
						conditions[i]['and'] ?? conditions[i]['or'],
					then: conditions[i].then,
					nodeId: conditions[i].nodeId,
					key: conditions[i].key,
				};
				const datas = {
					[conditions[i].labelId]: payload,
				};

				data.push(datas);
			}

			return data;
		} else {
			return conditions;
		}
	};

	const deleteNodesValue = (dupNodes: any, i: number) => {
		delete dupNodes[i].height;
		delete dupNodes[i].dragging;
		delete dupNodes[i].data;
		delete dupNodes[i].updateStyle;
		delete dupNodes[i].width;
		delete dupNodes[i].zIndex;
		delete dupNodes[i].selected;
		delete dupNodes[i].positionAbsolute;
		delete dupNodes[i].position;
		delete dupNodes[i].type;
		delete dupNodes[i]?.formMetaData;
		// delete dupNodes[i].key;
		delete dupNodes[i].sourceHandle;
	};

	const getMetData = (metaData: any, key: string) => {
		const newObj = structuredClone(metaData);
		if (!newObj) {
			return {};
		}

		if (key === 'kybForm') {
			const data = [];

			const { elements } = structuredClone(newObj) ?? [];
			for (let i = 0; i < elements.length; i++) {
				const choices = [];
				for (let j = 0; j < elements[i]?.choices.length; j++) {
					delete elements[i].choices[j].status;
					choices.push(elements[i].choices[j]);
				}
				delete elements[i].status;
				data.push({
					...elements[i],
					choices,
				});
			}
			return { questions: data };
		}
		if (key === 'accreditationVerify') {
			// saeeting undefind for  imported , configured key as accreditation 506b  accreditationVerify custom upload has this key
			delete newObj.accreditationType?.configured;
			delete newObj.accreditationType?.imported;
		}

		return newObj;
	};

	const getAction = (obj: any, index: number) => {
		const { key, id } = obj ?? {};
		if (complexSettings[id] && key !== 'dynamicForm') {
			const payload = {
				actionId: key,
				metadata: getMetData(complexSettings[id], key),
				index,
			};
			return [payload];
		}

		if (key === proofVerification) {
			const payload = {
				actionId: key,
				metadata: {
					proofReading: 'proofReading',
				},
				index: 0,
			};
			return [payload];
		}

		if (key === 'amlVerification') {
			return [{ actionId: key, index, metadata: {} }];
		}

		if (key === 'dynamicForm') {
			const metaFormData = structuredClone(complexSettings[id]);
			const cloneMetaData = {
				...metaFormData,
				pages: modifyPagesResponse(metaFormData?.pages ?? []),
			};
			return [
				{
					actionId: key,
					index,
					metadata: {
						questions: cloneMetaData,
					},
				},
			];
		}

		return [];
	};

	const getComplexPayload = (authId?: string) => {
		const data = getPayload();
		const dupNodes = JSON.parse(JSON.stringify(nodes));

		dupNodes.forEach((node: any) => {
			if (node.edgeId) {
				delete node.edgeId;
			}
		});
		
		for (let i = 0; i < dupNodes.length; i++) {
			dupNodes[i]['actions'] = getAction(dupNodes[i], i);
			dupNodes[i]['conditions'] = transformConditionObj(dupNodes[i]);
			deleteNodesValue(dupNodes, i);
		}

		const isBiometric = nodes.find((el: any) => el.stepId === 'authentication');

		if (!isBiometric) {
			const parentNode = dupNodes.findIndex((el: any) => !el.parentId?.length);
			if (parentNode !== -1) {
				dupNodes[parentNode]['parentId'] = authId ? [authId] : ['face-auth-0'];
			}

			const findAuth = data.steps.find(
				(el: any) => el.stepId === 'authentication'
			);

			//deleting index as this is not required
			delete findAuth.index;

			const faceAuth = {
				...findAuth,
				label: 'Authentication',
				id: authId || 'face-auth-0',
				parentId: [],
			};
			dupNodes.unshift(faceAuth);
		}

		const payload = {
			name: data.name,
			configurations: getConfig(data.steps) ?? {},
			nodes: JSON.parse(JSON.stringify(dupNodes ?? [])),
			design: {
				nodes,
				edges,
			},
		};

		return payload;
	};

	return { getComplexPayload };
};
