import type { ICountries, ICountryCode as Props } from '.';

import { useEffect, useState } from 'react';
import Select from 'react-select';

import countries from 'json/country-codes.json';

import './country-code.scss';

/**
 * Confirmation Modal component
 *
 * @param {(e: ICountries) => void} Props.handleChangeCountry - Method to change Country code
 * @param {string} Props.countryCode - selected country code
 * @param {string} Props.optionsClassName - className for options dropdown
 * @param {string} Props.className - className for input box
 * @param {boolean} Props.isDisabled - disable country code dropdown
 *
 */

export const CountryCode = ({
	handleChangeCountry,
	countryCode = '+1',
	optionsClassName = '',
	className = '',
	isDisabled = false,
}: Props) => {
	const [country, setCountry] = useState<ICountries>();

	const customStyles = {
		option: (styles: any) => ({
			...styles,
			cursor: 'pointer',
			boxShadow: 'none',
		}),
		control: (styles: any) => ({
			...styles,
			cursor: 'pointer',
			border: 'none',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			'&:hover': {
				border: 'none',
				borderRadius: '5px',
				boxShadow: 'none',
			},
		}),
	};

	useEffect(() => {
		const countryObj = countries.find(item => item.label === countryCode);
		setCountry(countryObj);
	}, [countryCode]);

	return country ? (
		<Select
			defaultValue={country}
			styles={customStyles}
			formatOptionLabel={(country: ICountries) => (
				<div className={`option ${optionsClassName}`}>
					<img
						className="option__flag"
						src={country.image}
						alt="country-code"
					/>
					<span className="option__label">{country.label}</span>
				</div>
			)}
			className={`country-label ${className}`}
			options={countries}
			onChange={handleChangeCountry as any}
			isSearchable
			isDisabled={isDisabled}
			value={country}
			components={{
				IndicatorSeparator: () => null,
			}}
			maxMenuHeight={140}
		/>
	) : (
		<></>
	);
};
