import type {
	IPipelineSteps,
	IUploadedDocuments,
	TemplateForm,
	SubscriptionPrice,
} from './type';

import { atom } from 'recoil';

export const AddActionsDrawerStatusState = atom<boolean>({
	key: 'add-actions-drawer-status',
	default: false,
});

export const PipelineStepsVisibleState = atom<boolean>({
	key: 'pipeline-step-visible-status',
	default: false,
});

export const PipelineTemplateVisibleState = atom<boolean>({
	key: 'pipeline-template-visible-status',
	default: false,
});

export const PipelineSettingsFormState = atom<{
	[key: string]: boolean | string | any;
}>({
	key: 'pipeline-settings-form',
	default: {},
});

export const IsPipelineFlowErrorMessage = atom<{
	[key: string]: boolean;
}>({
	key: 'Is-Pipeline-Flow-Error-Message',
	default: {
		publishWebhook: false,
		publishRedirect: false,
		kybMatches: false,
		publishName: false,
	},
});

export const PipelineTemplateState = atom<TemplateForm[] | null>({
	key: 'pipeline-template-form',
	default: null,
});

export const SubscriptionPriceState = atom<SubscriptionPrice[] | null>({
	key: 'subscription-price-state',
	default: null,
});

export const PipelineStepsState = atom<IPipelineSteps[] | any>({
	key: 'pipeline-steps-state',
	default: [],
});

export const PipelineMasterSteps = atom<IPipelineSteps[]>({
	key: 'pipeline-master-steps-state',
	default: [],
});

export const PipelineTemplatesState = atom<
	{ name: string; description: string; steps: IPipelineSteps[] }[]
>({
	key: 'pipeline-templates-state',
	default: [],
});

export const AllActionsState = atom<IPipelineSteps>({
	key: 'selected-step-all-actions',
	default: { _id: '', actions: [], label: '', key: '' },
});

export const PipelinesTableRow = atom<any[]>({
	key: 'all-created-pipelines-table-row',
	default: [],
});

export const AllPipelinesState = atom<any[]>({
	key: 'all-available-created-pipelines',
	default: [],
});

export const SelectedPipelineState = atom<any>({
	key: 'selected-pipeline-data',
	default: {},
});

export const PublishPipelineTypeState = atom<'create' | 'edit'>({
	key: 'publish-pipeline-type',
	default: 'create',
});

export const SelectedPipelineId = atom<string>({
	key: 'selected-pipeline-id',
	default: '',
});

export const UploadedDocsState = atom<IUploadedDocuments[]>({
	key: 'uploaded-signature-docs',
	default: [],
});

export const SelectedDocsForUploadState = atom<IUploadedDocuments[]>({
	key: 'selected-docs-for-upload-state',
	default: [],
});

export const finalConfiguredAggrementDocsState = atom<any[]>({
	key: 'final-configured-sign-aggreement-docs',
	default: [],
});

export const UploadedDocumentState = atom<{ fileName: string; state: boolean }>(
	{
		key: 'uploaded-doccument-details',
		default: {
			fileName: '',
			state: false,
		},
	}
);

export const AllOpenedStepsNameState = atom<string[]>({
	key: 'all-opened-steps-name',
	default: [],
});

export const ActiveStepState = atom<{ key: string; index: number }>({
	key: 'active-step-details',
	default: {
		key: '',
		index: 0,
	},
});

export const ActiveActionKeyNameState = atom<string>({
	key: 'active-step-key-name',
	default: '',
});

export const AllConditionalPipelinesState = atom<any[]>({
	key: 'all-conditional-available-created-pipelines',
	default: [],
});

export const PaymentCardMethodsState = atom<null | any>({
	key: 'payment-card-methods-state',
	default: null,
});

export const PaymentCardMethodsLoadingState = atom<boolean>({
	key: 'payment-card-methods-loading-state',
	default: false,
});

// Paras: state for email and message reminder toggle
export const EmailMessageReminderValue = atom<{
	emailInput: boolean;
	messageInput: boolean;
}>({
	key: 'email-message-reminder-value',
	default: {
		emailInput: false,
		messageInput: false,
	},
});

export const SelectedOnboardingAction = atom({
	key: 'selected-onboarding-action',
	default: {
		id: '',
		type: '',
		authId: '',
	},
});

export const isChecqueFraudSelected = atom({
	key: 'is-cheque-fraud-selected',
	default: false,
});
