import { useMemo, useCallback, FC, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Tippy from '@tippyjs/react';
import { ReactResponsiveTable } from '@storybook';

import { formatSizeUnits } from 'utils';
import { useNetwork, useNotification } from 'hooks';
import { API_URL, message } from 'constant';
import {
	IUploadProofDocumentState,
	UPLOAD_HEADER,
	UploadProofDocumentState,
} from './stores';
import { BrowseFile } from './components';
import { useWindowSize } from 'hooks/window-size';

import './upload-proof-document.scss';

interface IUploadedDocs {
	nodeId?: string | null
}

export const UploadProofDocument: FC<IUploadedDocs> =  ({ nodeId=null }) => {
	const [addedFiles, setAddedFiles] = useRecoilState(UploadProofDocumentState);
	const { successNotification, errorNotification } = useNotification();
	const { remove: deleteProofDoc } = useNetwork();
	const { innerSize } = useWindowSize();

	// local state for show tippy
	const [showTippy, setShowTippy] = useState<{
		[key: string]: boolean;
	}>({});

	const handleDelete = useCallback(
		async (id: string) => {
			const filterFiles = addedFiles.filter(item => item.docId !== id);
			setAddedFiles(filterFiles);
			const resp = await deleteProofDoc(`${API_URL.UPLOAD_PROOF}/${id}`);
			if (resp) {
				successNotification(resp.message);
			} else {
				errorNotification(message.DELETE_MESSAGE_ERROR);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[addedFiles]
	);

	const usersRows = useMemo((): IUploadProofDocumentState[] => {
		const rows: IUploadProofDocumentState[] = [];
		if (UPLOAD_HEADER.length) {
			addedFiles.forEach((item: IUploadProofDocumentState) => {
				if (item && item.node===nodeId) {
					let row: any = {};
					UPLOAD_HEADER.forEach(({ format, key }) => {
						if (format === 'jsx' && key === 'size') {
							const value = () => <div>{formatSizeUnits(item[key])} </div>;
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div className="UploadProofDocument_container_action">
									<i
										className="ri-delete-bin-6-line"
										onClick={() => handleDelete(item?.docId)}
									/>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'name') {
							const value = () => (
								<Tippy
									disabled={!showTippy[`upload-proof-document-${item?.docId}`]}
									content={<div className='UploadProofDocument_tippy'>{item[key]}</div>}
								>
									<div
										className="UploadProofDocument_doc-name"
										id={`upload-proof-document-${item?.docId}`}
									>
										{item[key] ?? '--'}
									</div>
								</Tippy>
							);
							return (row[key] = value);
						}
						row = {
							...row,
							_id: item?.docId,
							[key]: item[key as 'name'],
						};
						return null;
					});
					rows.push(row);
				}
			});
		}
		return rows;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addedFiles, showTippy]);

	useEffect(() => {
		document.querySelectorAll('.UploadProofDocument_doc-name').forEach(cell => {
			setShowTippy((pre: any) => ({
				...pre,
				[cell.id]: !(cell.scrollWidth <= cell.clientWidth),
			}));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [innerSize, usersRows]);

	return (
		<div className="UploadProofDocument">
			<div className="UploadProofDocument_container">
				<BrowseFile selectedNodeId={nodeId} />
				{!!addedFiles.length && (
					<ReactResponsiveTable
						column={UPLOAD_HEADER}
						rows={usersRows}
						hideSortKey={['name', 'size']}
						tableType="document-review-table"
					/>
				)}
			</div>
		</div>
	);
};
