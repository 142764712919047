import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Input } from '@storybook';
import { MintCartaMessage } from 'constant';
import {
	ActiveEmailClientFormDataState,
	ActiveEmailClientMethodState,
	ActiveEmailClientState,
} from 'views/email-client/stores';
import './enter-credentials.scss';

export const EnterCredentials = () => {
	// globle states
	const activeEmailClient = useRecoilValue(ActiveEmailClientState);
	const [activeEmailClientMethod, setActiveEmailClientMethod] = useRecoilState(
		ActiveEmailClientMethodState
	);

	// local state
	const [authInputType, setAuthInputType] = useState('password');

	const { EmptyValidEmailMessage } = MintCartaMessage;
	const { methods } = activeEmailClient ?? {};

	const { credentials, key: methodKey } = useMemo(
		() =>
			methods?.find(method => method.key === activeEmailClientMethod) ?? {
				credentials: [],
				key: '',
			},
		[methods, activeEmailClientMethod]
	);

	const [credentialData, setCredentialData] = useRecoilState(
		ActiveEmailClientFormDataState(activeEmailClientMethod)
	);

	const handleChangeEdit = useCallback(
		(name: string, e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;

			setCredentialData(prev => {
				return { ...prev, [name]: value };
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[credentialData, activeEmailClientMethod]
	);

	const renderMethodHeader = useMemo(
		() =>
			methods &&
			methods.length > 1 && (
				<div className="enter-credentials-method-header">
					{methods.map(method => (
						<div
							key={method.key}
							onClick={() => setActiveEmailClientMethod(method.key)}
							className={`method-text ${
								method.key === activeEmailClientMethod
									? 'method-text__active'
									: ''
							}`}
						>
							{method.label}
						</div>
					))}
				</div>
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeEmailClientMethod, methods]
	);

	const isEmailError = useCallback((email: string) => {
		if (email === undefined) return false;
		return !encodeURIComponent(email);
	}, []);

	const renderIcon = useMemo(() => {
		return authInputType === 'text';
	}, [authInputType]);

	const handleSuffixIcon = useCallback((id: string) => {
		const node = document.getElementById(id);
		const inputType = node?.getAttribute('type');
		if (inputType === 'text') {
			node?.setAttribute('type', 'password');
			setAuthInputType('password');
		}
		if (inputType === 'password') {
			node?.setAttribute('type', 'text');
			setAuthInputType('text');
		}
	}, []);

	const renderCredentials = useMemo(
		() => (
			<div className="enter-credentials-body">
				{credentials &&
					credentials.length &&
					credentials.map(credential => (activeEmailClient?.key !== 'smtp' &&
							credential?.inputKey === 'userName') ||
							(activeEmailClient?.key === 'amazon_SES' &&
								credential?.inputKey === 'email') ? null : (
							<Input
								autoComplete="off"
								inputType={credential.type as 'text'}
								label={credential.label}
								isRequired={credential.isRequired}
								handleChange={e => handleChangeEdit(credential.inputKey, e)}
								placeholder=""
								value={credentialData[credential.inputKey]}
								id={methodKey + '_' + credential.inputKey}
								key={methodKey + '_' + credential.inputKey}
								isError={
									credential.inputKey === 'email' &&
									isEmailError(credentialData['email'] as string)
								}
								errorMessage={EmptyValidEmailMessage}
								suffixIcon={
									credential.preSuffixIcon && credential.postSuffixIcon
										? renderIcon
											? credential.postSuffixIcon
											: credential.preSuffixIcon
										: ''
								}
								handleSuffixIcon={() =>
									handleSuffixIcon(methodKey + '_' + credential.inputKey)
								}
							/>
						)
					)}
			</div>
		),
		[
			activeEmailClient,
			credentials,
			credentialData,
			methodKey,
			isEmailError,
			EmptyValidEmailMessage,
			renderIcon,
			handleChangeEdit,
			handleSuffixIcon,
		]
	);
	return (
		<div className="enter-credentials-wrapper">
			{renderMethodHeader}
			{renderCredentials}
		</div>
	);
};
