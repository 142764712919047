import { FC, MouseEvent, createRef, useCallback, useState } from 'react';
import { IconBtn, Loader } from '@storybook';
import { useOutsideClick } from 'hooks';

import './drop.scss';

export interface IDropOptions {
	handleView: () => void;
	handleDelete: () => void;
	handleUseFlow?: () => void;
	handleReinvite?: () => void;
	editLabel?: string;
	isDisabled?: boolean;
	isDeleteDisabled?: boolean;
	showReinvite?: boolean;
	isReInviteLoading?: boolean;
}

export const DropOptions: FC<IDropOptions> = ({
	editLabel = 'Edit',
	handleView,
	handleDelete,
	isDisabled = false,
	isDeleteDisabled = false,
	showReinvite = false,
	handleReinvite,
	isReInviteLoading = false,
}) => {
	// local state
	const [showOption, setShowOption] = useState<boolean>(false);

	// ref
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleOptions = useCallback((e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setShowOption(prev => !prev);
	}, []);

	const handleclickOnEdit = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			setShowOption(false);
			e.stopPropagation();
			handleView();
		},
		[handleView]
	);

	const handleclickOnDelete = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			setShowOption(false);
			e.stopPropagation();
			handleDelete();
		},
		[handleDelete]
	);

	return (
		<div ref={ref} className={'options-wrapper'}>
			<IconBtn
				isDisabled={!showReinvite && isDisabled}
				className="extra"
				btnClassName="ri-more-2-fill"
				handleClickIcon={handleOptions}
			/>
			{showOption && (
				<div className="options-box">
					{!isDisabled && (
						<>
							<button onClick={handleclickOnEdit} className="menu-item">
								<i className="ri-edit-line space" />
								{editLabel}
							</button>
							<button className="menu-item" onClick={handleclickOnDelete} disabled={isDeleteDisabled}>
								<i className="ri-delete-bin-line space" />
								Delete
							</button>
						</>
					)}

					{showReinvite && (
						<button className="menu-item" onClick={handleReinvite}>
							<i className="ri-refresh-line space"></i>
							<div>Reinvite</div>{' '}
							{isReInviteLoading && (
								<span>
									<Loader dimension={14} type="loader" />
								</span>
							)}
						</button>
					)}
				</div>
			)}
		</div>
	);
};
