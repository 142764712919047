import { Loader } from '@storybook';
import {
	ChangeEvent,
	DragEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactPipelineModal } from 'components';
import { API_URL } from 'constant';
import { REACT_APP_API_HOST } from 'envs';
import {
	PipelineSettingsFormState,
	SelectedOnboardingAction,
	WebTokenState,
	loginState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { formatSizeUnits, getDateWithTime } from 'utils';
import {
	AddedActionsState,
	AddedFileDataState,
	CustomAccreditationTempId,
} from 'views/onboarding-flow/store';
import {
	ComplexSettingFormState,
	fileUploadStatusState,
	uploadIsRequired,
} from '../store';
import className from 'classnames';
import './pipeline-custom-upload.scss';
import { SimpliciSignIframe } from 'shared-components';

interface IPipelineCustomUpload {
	type?: string;
	complexFlowId?: string;
}

interface IConfigDoc {
	prepareUrl: string;
	documentId: string;
}

let isApiCalled = false;
export const PipelineCustomUpload: FC<IPipelineCustomUpload> = ({
	type,
	complexFlowId,
}) => {
	const { accessToken: token, sandboxStatus } = useRecoilValue(loginState);
	const webToken = useRecoilValue(WebTokenState);
	const accessToken = webToken?.length > 0 ? webToken : token;
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);
	const settingsFormState = useRecoilValue(PipelineSettingsFormState);

	const [addedFiles, setAddedFiles] = useRecoilState(AddedFileDataState);
	const [signModalVisible, setSignModalVisible] = useState<boolean>(false);
	const setConfigTemplateId = useSetRecoilState(CustomAccreditationTempId);
	const [configDoc, setConfigDoc] = useState<IConfigDoc>({
		prepareUrl: '',
		documentId: '',
	});
	const setAddedActions = useSetRecoilState(AddedActionsState);
	const { type: selectedOnboardingType } = useRecoilValue(
		SelectedOnboardingAction
	);
	const [isRequired, setIsRequired] = useRecoilState(uploadIsRequired);
	const [uploadStatus, setUploadStatus] = useRecoilState(fileUploadStatusState);

	const { errorNotification, successNotification } = useNotification();
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const { get } = useNetwork();

	useEffect(() => {
		if (/modify|clone/.test(selectedOnboardingType)) {
			const { imported = false, documentId = '' } = complexFlowId
				? complexSettingForm[complexFlowId]?.accreditationType ?? {}
				: {};
			if (complexFlowId && !imported && !isApiCalled && documentId) {
				isApiCalled = true;
				setUploadStatus(prev => ({ ...prev, loading: true }));
				get(`${API_URL.DOCUMENTS}/${documentId}`)
					.then(resp => {
						if (resp.data) {
							const { _id, name, size, createdAt } = resp.data;
							setComplexSettingForm((prev: any) => {
								const newObj = JSON.parse(JSON.stringify(prev));
								if (complexFlowId) {
									Object.assign(newObj[complexFlowId]['accreditationType'], {
										imported: true,
										configured: true,
									});
								}
								return newObj;
							});
							setAddedFiles(prev => ({
								...prev,
								_id,
								name,
								size,
								createdAt,
								configured: true,
							}));
						}
					})
					.finally(() => {
						isApiCalled = false;
						setUploadStatus({ loaded: false, loading: false });
					});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleImportImage = useCallback(
		async (
			e: ChangeEvent<HTMLInputElement> | any,
			type?: 'drag' | 'browse'
		) => {
			/**
			 * @Avinash here we are assigning the file after extracting from the event based on the the type (drag or browse)
			 * */
			const file: File =
				(type === 'drag'
					? (e as File)
					: (e as ChangeEvent<HTMLInputElement>).target.files?.[0]) ??
				({} as File);
			if (file.size > 10000000) {
				if (type !== 'drag') e.target.value = '';
				return errorNotification('File size exceeds the maximum limit.');
			}
			if (file.type !== 'application/pdf') {
				if (type !== 'drag') e.target.value = '';
				return errorNotification('Only Pdf documents are supported.');
			}
			const payload = new FormData();
			payload.append('doc', file);
			payload.append('type', 'esign');
			if (file) {
				setUploadStatus(prev => ({ ...prev, loading: true }));
				try {
					const response = await fetch(`${REACT_APP_API_HOST}/url/upload`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accountMode: sandboxStatus ? 'sandbox' : 'live',
						},
						body: payload,
					});
					const apiData = await response.json();
					if (apiData.data) {
						const { name, size, _id, createdAt } = apiData.data ?? {};
						if (name) {
							setTimeout(() => {
								setUploadStatus({ loaded: true, loading: false });
								setComplexSettingForm((prev: any) => {
									const newObj = JSON.parse(JSON.stringify(prev));
									if (complexFlowId) {
										Object.assign(newObj[complexFlowId]['accreditationType'], {
											imported: true,
										});
									}
									return newObj;
								});
								setAddedFiles(prev => ({
									...prev,
									_id,
									name,
									size,
									createdAt,
								}));
								setUploadStatus({ loaded: false, loading: false });
							}, 1000);
						}
					} else {
						// errorNotification(MESSAGE.ERROR);
						setUploadStatus({ loaded: false, loading: false });
					}
				} catch (error) {
					/* empty */
				}
				if (type !== 'drag') e.target.value = '';
			}
			return null;
		},
		[
			errorNotification,
			accessToken,
			sandboxStatus,
			setAddedFiles,
			setComplexSettingForm,
			complexFlowId,
			setUploadStatus,
		]
	);

	const handleDeleteFile = useCallback(async () => {
		/*@avinash
          #reset all value from complexSettingform if user has clicked on delete file button
		*/
		setComplexSettingForm((prev: any) => {
			const newObj = JSON.parse(JSON.stringify(prev));
			if (complexFlowId) {
				const { label, price, value } =
					newObj[complexFlowId]['accreditationType'];

				const payload = {
					label,
					price,
					value,
				};
				newObj[complexFlowId]['accreditationType'] = payload;
			}
			return newObj;
		});
		setAddedFiles(prev => ({
			...prev,
			_id: '',
			configured: false,
			createdAt: '',
			name: '',
			size: '',
			type: 'customUpload',
		}));
		try {
			setIsRequired(false);
			await fetch(
				`${REACT_APP_API_HOST}/signing/${addedFiles._id}?type=esign`,
				{
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
						accountMode: sandboxStatus ? 'sandbox' : 'live',
					},
				}
			);
		} catch (err: any) {
			return null;
		}
		return null;
	}, [
		setAddedFiles,
		addedFiles?._id,
		accessToken,
		sandboxStatus,
		setComplexSettingForm,
		complexFlowId,
		setIsRequired,
	]);

	const handleSavedTemplate = useCallback(() => {
		successNotification('Document Configuration Success');
		setAddedFiles(prev => ({ ...prev, configured: true }));
		setComplexSettingForm((prev: any) => {
			const newObj = JSON.parse(JSON.stringify(prev));
			if (complexFlowId) {
				Object.assign(newObj[complexFlowId]['accreditationType'], {
					configured: true,
				});
			}
			return newObj;
		});
		setSignModalVisible(false);
		setConfigDoc({ documentId: '', prepareUrl: '' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleConfigureFiles = useCallback(async () => {
		setSignModalVisible(true);
		setConfigDoc(prev => ({ ...prev, loading: true }));
		const payload = { docId: addedFiles._id, type };
		try {
			const response = await fetch(`${REACT_APP_API_HOST}/signing`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
					accountMode: sandboxStatus ? 'sandbox' : 'live',
				},

				body: JSON.stringify(payload),
			});
			if (!response.ok) {
				errorNotification('Failed to open file. try again later');
				setSignModalVisible(false);
				return;
			}
			const apiData = await response.json();
			const { documentId, prepareUrl, templateId } = apiData.data ?? {};
			if (prepareUrl) {
				setConfigDoc({ documentId, prepareUrl });
				// setConfigTemplateId(templateId);

				setComplexSettingForm((prev: any) => {
					const newObj = JSON.parse(JSON.stringify(prev));
					if (complexFlowId) {
						const payload = {
							documentId: documentId,
							templateId:
								settingsFormState?.accreditationDocument?.value ===
								'customUpload'
									? templateId
									: undefined,
							provider:
								settingsFormState?.accreditationType?.value === '506b'
									? 'esign'
									: undefined,
						};
						Object.assign(newObj[complexFlowId]['accreditationType'], payload);
					}
					return newObj;
				});
				setAddedActions(preState => {
					const copy = [...preState];
					const filtered = copy.map(a => {
						return {
							...a,
							metadata: a.metadata.map((b: any) => {
								if (b.key === 'accreditationDocument') {
									return {
										...b,
										documentId,
									};
								}
								return b;
							}),
						};
					});
					return filtered;
				});
			}
		} catch (err: any) {
			errorNotification('Failed to open file. try again later');
			setSignModalVisible(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addedFiles._id, type, accessToken, sandboxStatus, setAddedActions]);

	const handleDisabledFiles = useMemo(() => {
		if (!uploadStatus.loaded && uploadStatus.loading) return true;
		return false;
	}, [uploadStatus.loaded, uploadStatus.loading]);

	const handleCloseIframe = useCallback(() => {
		setSignModalVisible(false);
		setConfigDoc({ documentId: '', prepareUrl: '' });
		setConfigTemplateId('');
		setIframeLoaded(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Ankur Singh: fix Iframe Flicker issue
	const handleIframeLoad = useCallback(() => {
		setIframeLoaded(true);
	}, []);

	const renderBoldSignIframe = useMemo(() => {
		return (
			<div className="iframe-container">
				{configDoc.prepareUrl ? (
					<SimpliciSignIframe
						title="uniqueTitle"
						styles={{ display: iframeLoaded ? '' : 'none' }}
						onLoad={handleIframeLoad}
						className="iframe"
						handleSubmitModal={handleSavedTemplate}
						signUrl={configDoc.prepareUrl}
					/>
				) : (
					<div
						className={
							iframeLoaded
								? 'iframe-container__display-hidden'
								: 'iframe-container__display-visible'
						}
					>
						<Loader dimension={60} className="loader-blue" />
					</div>
				)}
				<div
					style={{
						position: 'absolute',
						top: 8,
						right: 8,
						zIndex: 1,
						cursor: 'pointer',
					}}
					onClick={handleCloseIframe}
				>
					<i className="ri-close-line" />
				</div>
			</div>
		);
	}, [
		configDoc,
		handleCloseIframe,
		handleIframeLoad,
		handleSavedTemplate,
		iframeLoaded,
	]);

	const isConfigButton = useMemo(() => {
		if (complexFlowId) {
			//convert undefined into boolean
			return !!complexSettingForm[complexFlowId].accreditationType.configured;
		}
		return addedFiles.configured;
	}, [addedFiles.configured, complexFlowId, complexSettingForm]);

	const isFileUploaded = useMemo(() => {
		if (complexFlowId) {
			return !!complexSettingForm[complexFlowId]?.accreditationType?.imported;
		}
		return Object.keys(addedFiles).length && addedFiles?.name?.length > 0;
	}, [addedFiles, complexFlowId, complexSettingForm]);

	/**
	 * @avinash here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @avinash here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleImportImage(files?.[0] as File, 'drag');
		},
		[handleImportImage]
	);

	const lableClassName = useMemo(
		() =>
			className('browse-file__label-box', {
				'browse-file__is-required': !addedFiles._id && isRequired,
			}),
		[addedFiles, isRequired]
	);

	return (
		<div className="PipelineCustomUpload--fields">
			<div className="browse-file">
				{isFileUploaded ? (
					<div className="file-details">
						<div className="file-details-item">
							<div className="file-details-label">File name</div>
							<div className="file-details-value">{addedFiles.name}</div>
						</div>
						<div className="file-details-item">
							<div className="file-details-label">Date</div>
							<div className="file-details-value">
								{getDateWithTime(addedFiles.createdAt)}
							</div>
						</div>
						<div className="file-details-item">
							<div className="file-details-label">Size</div>
							<div className="file-details-value">
								{formatSizeUnits(addedFiles.size)}
							</div>
						</div>

						<div className="PipelineCustomUpload--fields-btn">
							{!isConfigButton ? (
								<div
									className="PipelineCustomUpload--fields-config"
									onClick={handleConfigureFiles}
								>
									Configure
								</div>
							) : (
								<div className="PipelineCustomUpload--fields-configured">
									Configured
								</div>
							)}

							<div
								className="PipelineCustomUpload--fields-remove"
								onClick={handleDeleteFile}
							>
								Remove
							</div>
						</div>
					</div>
				) : (
					<div
						className="doc-upload-wrapper"
						onDragOver={handleDragOver}
						onDrop={handleDrop}
					>
						<label htmlFor="browse-file" className={lableClassName}>
							<>
								{!uploadStatus.loaded && uploadStatus.loading ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: 16,
										}}
									>
										<Loader className="loader-blue" dimension={60} />
										<div style={{ fontSize: '16px' }}>Uploading file...</div>
									</div>
								) : (
									<>
										<i className="ri-file-upload-line browse-file__logo" />
										<div className="browse-file__label-text">
											<span className="browse-file__light-text">
												Drag and drop files or{' '}
												<span style={{ color: 'var(--color-primary-light)' }}>
													Browse file
												</span>
											</span>
										</div>
										<div className="browse-file__description">
											Supported file format: Pdf
										</div>
										<div className="browse-file__description">
											Maximum upload file size: 10 MB.
										</div>
									</>
								)}
							</>

							<input
								multiple={false}
								accept=".pdf, docx"
								id="browse-file"
								type="file"
								onChange={handleImportImage}
								disabled={handleDisabledFiles}
								className="browse-file__input"
							/>
						</label>
					</div>
				)}
			</div>
			<ReactPipelineModal
				body={renderBoldSignIframe}
				visible={signModalVisible}
				showFooter={false}
				showHeader={false}
				isFullScreen={false}
			/>
		</div>
	);
};
