import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { UploadCSVInput } from 'views/invite-investor/components';
import { SignAgreement } from 'views/sign-aggrement';
import { ComplexConfig } from '../complex-config';
import { FundInvestment } from '../fund-investment';
import { InviteChooseFund } from '../invite-choose-fund';
import { EmbedCode, EnterInstanceName, QrImage } from './components';
import { isHideHeader, isShowFooter } from './constants';
import './onboarding-qr-flow.scss';
import {
	OnboardingQRModalNavigateState,
	OnboardingQRModalOpenState,
	SelectedPipelineDetailsForQRState,
	SelectedQRTemplateIdState,
	SelectedQrInstanceState,
	SelectedQrOnbordingFlowName,
	useOnboardingQrFlow,
} from './stores';
import { IsMultiSignModalOpen, OverlaySignAgreementEnvelopeId } from 'views/multi-sign-agreement';
import { proofReadingKey } from 'views/pipelines/constants';
import { UploadProofDocument } from 'views/upload-proof-document';

export const OnboardingQRFlow = () => {
	//globle states
	const isModalOpen = useRecoilValue(OnboardingQRModalOpenState);
	const pipelineDetails = useRecoilValue(SelectedPipelineDetailsForQRState);
	const navigate = useRecoilValue(OnboardingQRModalNavigateState);
	const setMultiSignModalIsOpen = useSetRecoilState(IsMultiSignModalOpen);
	const QRTemplateId = useRecoilValue(SelectedQRTemplateIdState);
	const qrInstance = useRecoilValue(SelectedQrInstanceState);
	const qrOnbordingFlowName = useRecoilValue(SelectedQrOnbordingFlowName);
	const overlayInviteConfig = useRecoilValue(OverlaySignAgreementEnvelopeId);
	
	// hooks
	const {
		reset,
		handleNext,
		handleGenerateToken,
		props,
		isSubmitDisable,
		isSubmitLoading,
		handleBack,
		getOverlayRecipients,
		validateCapTableRowForQROverlay
	} = useOnboardingQrFlow();

	//constants
	const { proofReading } = proofReadingKey;

	const renderPage = useMemo(() => {
		switch (navigate) {
			case 'configComplex':
				return <ComplexConfig />;
			case 'reconfigQR':
				return <ComplexConfig />;
			case 'create':
				return <InviteChooseFund />;
			case 'connectBank':
				return (
					<FundInvestment
						{...props}
						handleGenerateToken={handleGenerateToken}
					/>
				);
			case 'signAgreement':
				return <SignAgreement />;
			case 'instance_name':
				return <EnterInstanceName handleClick={handleNext} />;
			case 'qr_image':
				return <QrImage />;
			case 'embed_code':
				return <EmbedCode />;
			case 'csv':
				return (
					<UploadCSVInput
						selectedDetail={pipelineDetails?.fullStatus ?? []}
						handleClose={reset}
					/>
				);
			case proofReading:
				return <UploadProofDocument />;
			default:
				return <></>;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, props, pipelineDetails?.fullStatus]);

	const renderHeader = useMemo(() => {
		switch (navigate) {
			case 'configComplex':
				return 'Configuration Steps';
			case 'reconfigQR':
				return 'Re-configuration Steps';
			case 'create':
				return 'Fund Detail';
			case 'connectBank':
				return 'Fund Recipient Bank Account';
			case 'signAgreement':
				return 'Agreement Document';
			case proofReading:
				return proofReadingKey.DocumentReview;
			case 'qr_image':
			case 'embed_code':
				return (
					<div className="Onbording_Qr_Flow_Header_title">
						<span className="onboarding-name-qr-elipsis">
							{qrInstance?.name ?? ''}
						</span>
						<span className="Onbording_Qr_Flow_Header_sub-title">
							Onboarding Name: {qrOnbordingFlowName ?? '--'}
						</span>
					</div>
				);
			default:
				return <></>;
		}
	}, [navigate, qrInstance?.name, qrOnbordingFlowName, proofReading]);

	const renderSubHeader = useMemo(() => {
		switch (navigate) {
			case 'configComplex':
				return 'Follow these steps to configure your steps properly. Ensure that you carefully review and adjust each setting to meet your specific requirements.';
			case 'create':
				return 'Create fund or select fund';
			case 'connectBank':
				return 'Please select or add a fund recipient bank to receive fund investment.';
			case 'signAgreement':
				return 'This action allows user to electronically sign a document. Please upload all the documents that require signatures.';
			case 'reconfigQR':
				return 'Follow these steps to re-configure your steps properly. Ensure that you carefully review and adjust each setting to meet your specific requirements.';

			case proofReading:
				return 'Upload all the documents that you want the end user to read.';
			default:
				return <></>;
		}
	}, [navigate, proofReading]);

	const handleNextButtonLabel = useMemo(() => {
		switch (navigate) {
			case 'csv':
				return QRTemplateId && overlayInviteConfig.status === "pending"
					? 'Next'
					: 'Invite';
			case 'reconfigQR':
				return 'Re-configure';
			default:
				return 'Next';
		}
	}, [navigate, QRTemplateId, overlayInviteConfig]);

	// If overlay is configured directly call the next function
	useEffect(() => {
		if(QRTemplateId && overlayInviteConfig.status === "configured" && overlayInviteConfig.envelopeId !== '') {
			handleNext();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [QRTemplateId, overlayInviteConfig])

	const handleNextButtonForCsv = useCallback(() => {
		if (QRTemplateId && overlayInviteConfig.status === 'pending') {
			// validating the rows of captable
			if(validateCapTableRowForQROverlay()){
				setMultiSignModalIsOpen(true);
			}
		} else {
			handleNext();
		}
	}, [QRTemplateId, handleNext, overlayInviteConfig, setMultiSignModalIsOpen, validateCapTableRowForQROverlay]);

	useEffect(() => {
		// fetching the recipients for validation for overlay
		if(QRTemplateId && overlayInviteConfig.status === 'pending'){
			getOverlayRecipients(QRTemplateId)
		}
	}, [QRTemplateId, getOverlayRecipients, overlayInviteConfig])

	return (
		<>
			{navigate !== 'table' && (
				<Modal
					isOpen={isModalOpen}
					modalName="Onboarding_QR_Flow"
					closeModal={reset}
					className={
						'OnboardingQRFlow-modal OnboardingQRFlow-modal__' + navigate
					}
					showCloseBtn={true}
					isStopOutsideClick={false}
					title={
						isHideHeader[navigate] ? (
							<></>
						) : (
							<div className="OnboardingQRFlow-modal__header_wrapper">
								<div className="OnboardingQRFlow-modal__header_wrapper__title">
									{renderHeader}
								</div>
								<div className="OnboardingQRFlow-modal__header_wrapper__sub-title">
									{renderSubHeader}
								</div>
							</div>
						)
					}
				>
					{renderPage}
					{isShowFooter?.[navigate] && (
						<div className="OnboardingInviteModal-btn">
							<Button
								handleClick={handleBack}
								label={navigate === 'csv' ? 'Close' : 'Back'}
								type="button__filled button__filled--secondary button__large"
							/>
							<Button
								handleClick={handleNextButtonForCsv}
								label={
									isSubmitLoading ? (
										<Loader
											type="loader"
											dimension={20}
											className="loader-white"
										/>
									) : (
										<>{handleNextButtonLabel}</>
									)
								}
								type="button__filled button__filled--primary button__large"
								disabled={isSubmitDisable || isSubmitLoading}
							/>
						</div>
					)}
				</Modal>
			)}
		</>
	);
};
