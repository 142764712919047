import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilValue,
	useRecoilState,
	useSetRecoilState,
	useResetRecoilState,
} from 'recoil';
import { ReactDropdown, Button, Loader } from '@storybook';

import {
	commentText,
	ConditionalSessionFlowState,
	SelectedSessionState,
	SessionItemSelected,
	userDataState,
} from 'global-stores';

import { CommentBox } from './commentBox';
import { Actions } from '../constants';
import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import Modal from '@storybook/new-modal/modal';
import {
	useComplexSessionInfo,
	useGetComplexNodeInfo,
} from '../hooks/use-complex-session-details';
import { StatusDocuments } from './status-docs';
import { StatusUploadedDocsState } from '../store';

interface IActions {
	label: string;
	value: string;
	color: string;
}

interface IActionButton {
	status: string;
	type: string;
	isTransactionPage?: boolean;
	isLoading?: boolean;
	nodeId?: string;
	isDisabled?: boolean;
	isChequeFraud?: boolean;
}

export const ActionButton: FC<IActionButton> = ({
	status,
	type,
	isTransactionPage = false,
	isChequeFraud = false,
	nodeId,
	isDisabled = false,
}) => {
	const [selectedItem, setSelectedItems] = useState<IActions>(Actions[2]);
	const [selectedStatus, setSelectedStatus] = useState<IActions>(Actions[2]);
	const [openComment, setOpenComment] = useState<boolean>(false);
	const selectedSessionItem = useRecoilValue(SessionItemSelected);
	const [comment, setComment] = useRecoilState(commentText);
	const setSessionSelected = useSetRecoilState(SelectedSessionState);
	const loginStateData = useRecoilValue(userDataState);
	const [isEmailSend, setIsEmailSend] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const isComplexFlow = useRecoilValue(ConditionalSessionFlowState);
	const addedFilesKybStatus = useRecoilValue(StatusUploadedDocsState);
	const resetAddedFiles = useResetRecoilState(StatusUploadedDocsState);
	const { getComplexNodeInfo } = useGetComplexNodeInfo();
	const { complexSessionInfo } = useComplexSessionInfo();
	const { errorNotification } = useNotification();
	const {
		patch: sessionAction,
		data: sessionActionData,
		error: sessionError,
		isLoaded: sessionActionLoaded,
	} = useNetwork();
	const {
		get: getSessionDetails,
		data: sessionDetails,
		error: sessionDetailsError,
	} = useNetwork();

	const isKYBComplete = useMemo(
		() => type === 'kyb' && selectedStatus.value === 'completed',
		[type, selectedStatus.value]
	);

	const handleCloseComment = useCallback(() => {
		setOpenComment(false);
		setTimeout(() => {
			resetAddedFiles();
			setComment('');
			setIsEmailSend(true);
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmitAction = useCallback(async () => {
		setIsLoader(true);
		const { _id, sendEmail } = selectedSessionItem;
		const { value } = selectedStatus;
		let actionType = 'comment';
		if (value !== status) {
			actionType = 'status';
		}
		const actionData = {
			stepId: type,
			to: value?.toLowerCase(),
			from: status,
			data: comment,
			type: actionType,
			userName: loginStateData?.name,
			sendMail: isEmailSend,
			recipientEmail: sendEmail,
			flow: isComplexFlow ? 'complex' : 'linear',
		};
		if (isKYBComplete && addedFilesKybStatus?.data?.length) {
			const documents = addedFilesKybStatus.data.map(({ url, name, size }) => ({
				base64File: url,
				name,
				size,
			}));
			(actionData as any).documents = documents;
		}
		if (isComplexFlow) {
			(actionData as any).nodeId = nodeId;
		}
		const resp = await sessionAction(
			`${API_URL.ACTIVITIES}/${_id}`,
			actionData
		);

		if (resp) {
			setIsLoader(false);
			handleCloseComment();
			//Deepak : to update the status of dropdown value in the linear flow
			setSelectedItems(selectedStatus);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedSessionItem,
		selectedStatus,
		status,
		type,
		comment,
		loginStateData?.name,
		isEmailSend,
		isComplexFlow,
		isKYBComplete,
		addedFilesKybStatus.data,
		sessionAction,
		nodeId,
		resetAddedFiles,
	]);

	useEffect(() => {
		if (sessionActionLoaded) {
			const { _id } = selectedSessionItem;
			if (isComplexFlow) {
				getComplexNodeInfo();
				complexSessionInfo(_id ?? '');
			} else {
				getSessionDetails(`${API_URL.SESSION_DETAIL}/${_id}`);
			}
		}
		// eslint-disable-next-line
	}, [
		sessionActionLoaded,
		sessionActionData,
		selectedSessionItem,
		sessionAction,
	]);

	useEffect(() => {
		if (sessionError) {
			errorNotification('Action Failed .');
		}
		// eslint-disable-next-line
	}, [sessionAction, sessionError]);

	useEffect(() => {
		if (sessionDetails?.data) {
			setSessionSelected(sessionDetails.data);
		}
		if (sessionDetailsError) {
			errorNotification('Failed to load Session flows.');
		}
		// eslint-disable-next-line
	}, [sessionDetails]);

	const handleChange = useCallback(
		(item: { color: string; label: string; value: string }) => {
			setSelectedStatus(item);
			setOpenComment(true);
		},
		[setOpenComment]
	);

	const changeCheckbox = useCallback((isChecked: boolean) => {
		setIsEmailSend(isChecked);
	}, []);

	useEffect(() => {
		if (status) {
			const propertyStatus = status === 'failed' ? 'rejected' : status;

			const result = Actions.find(
				action => action.value === propertyStatus.toLowerCase()
			);

			if (result) {
				setSelectedItems(result);
			}
		}
	}, [status]);

	const createStyle = {
		control: (styles: { [key: string]: number | string }) => ({
			...styles,
			border: '1px solid' + selectedItem.color,
			borderRadius: 6,
			color: selectedItem.color,
			boxShadow: 'none',
			'&:hover': {
				cursor: 'pointer',
			},
		}),
		singleValue: (styles: { [key: string]: number | string }) => ({
			...styles,
			color: selectedItem.color,
		}),
		menu: (styles: { [key: string]: any }) => ({
			...styles,
			zIndex: 9,
		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			svg: {
				fill: selectedItem.color,
			},
		}),
		option: (styles: { [key: string]: any }, { data }: any) => {
			return {
				...styles,
				backgroundColor: 'var(--color-bg-100-light)',
				color: data.color,
				cursor: 'pointer',
				'&:hover': {
					backgroundColor: '#F0F4FF',
				},
				'&:last-child': {
					borderBottom: 'none',
				},
			};
		},
	};

	const disable = useMemo(() => {
		if (isDisabled) return true;
		if (isChequeFraud) {
			return false;
		} else {
			return isTransactionPage;
		}
	}, [isChequeFraud, isDisabled, isTransactionPage]);

	const renderFooter = useMemo(
		() => (
			<div className="CommentFooter--right">
				<Button
					handleClick={handleCloseComment}
					label="Cancel"
					type="button__ghost button__filled--secondary"
					buttonType="button"
					disabled={isLoader}
				/>

				<Button
					handleClick={handleSubmitAction}
					label="Save"
					type="button__filled button__filled--primary"
					buttonType="button"
					disabled={isLoader || !comment?.trim()}
					loader={
						isLoader ? (
							<Loader type="loader" className="loader-blue" dimension={20} />
						) : (
							<></>
						)
					}
				/>
			</div>
		),
		[handleCloseComment, handleSubmitAction, isLoader, comment]
	);

	const renderCommentBox = useMemo(
		() => (
			<>
				<CommentBox />
				<div className="email--checkbox">
					<label className="email--checkbox-label">
						<input
							type={'checkbox'}
							checked={isEmailSend}
							className="checkbox email--checkbox-input"
							onChange={e => changeCheckbox(e.target.checked)}
						/>
						<div className="lable-text">Send as Mail</div>
					</label>
				</div>
			</>
		),
		[changeCheckbox, isEmailSend]
	);

	const modalBody = useMemo(() => {
		return isKYBComplete ? (
			<StatusDocuments footer={renderFooter} commentBox={renderCommentBox} />
		) : (
			<>
				{renderCommentBox}
				{renderFooter}
			</>
		);
	}, [isKYBComplete, renderCommentBox, renderFooter]);

	const modalTitle = useMemo(
		() =>
			isKYBComplete ? (
				<div className="Comments--modal-kyb__title">Change Status</div>
			) : (
				'Comments'
			),
		[isKYBComplete]
	);

	return (
		<>
			<ReactDropdown
				options={Actions.filter((item)=>item.isVisible===true)}
				defaultValue={Actions[0]}
				value={selectedItem}
				handleChangeSelect={handleChange as any}
				createStyle={createStyle}
				Isdisable={disable}
			/>

			<Modal
				isOpen={openComment}
				closeModal={handleCloseComment}
				modalName="Comments"
				title={modalTitle}
				className={`Comments--modal ${isKYBComplete ? 'Comments--modal-kyb' : ''
					}`}
			>
				{modalBody}
			</Modal>
		</>
	);
};
