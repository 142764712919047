/* eslint-disable no-case-declarations */
import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	PlaidLinkOnEvent,
	PlaidLinkOnExit,
	PlaidLinkOnSuccess,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { CapTableMapKeyAndValue } from '@storybook/custom-captable/states';
import { API_URL, INVITE_MODAL_STEPS, MESSAGE } from 'constant';
import {
	AllConditionalPipelinesState,
	FundRecipientBankAccount,
	FundsState,
	InvestorTableGridData,
	InviteNavigateModalState,
	IsCapTableLoading,
	IsCreatingFundState,
	OpenInviteSelectorModal,
	PipelineIdState,
	RecipientBankAccountLoadingState,
	SelectedBankFlowState,
	SelectedFundRecipientBankAccount,
	SelectedFundType,
	SelectedInviteFundState,
	SelectedMultipleDateState,
	SelectedPipelineDetails,
	ShowSelectedField,
	UploadedDocsState,
	useFunds,
} from 'global-stores';
import { number2words, useTrackEvents } from 'helpers';
import { useAllowedInvites, useNetwork, useNotification } from 'hooks';
import {
	deepCompareLucksheetData,
	findDuplicates,
	getLuckSheetData,
	isEmailValid,
	isNumber,
	isValidAmount,
	isValidDecimal,
	isValidName,
	isValidRecipientName,
} from 'utils';
import { UploadCSVInput } from 'views/invite-investor/components';
import {
	CaptableOldState,
	IsMultiSignModalOpen,
	LinearMultiSignAgreementStepConfigured,
	MultiSignAgreement,
	SelectedComplexSignAgreementStep,
	SignAgreementComplexStepConfigured,
} from 'views/multi-sign-agreement';
import { WelcomeNewUserModalState } from 'views/new-user-popup';
import { ReminderPage } from 'views/pipeline/components';
import { SendNotificationState } from 'views/pipeline/store';
import { FundInvestment } from 'views/pipelines/components/fund-investment';
import { SignAgreement } from 'views/sign-aggrement';
import { useGetSignDocument } from 'views/sign-aggrement/store';
import {
	BusinessIdentificationForm,
	BussinessInfoErrorState,
	ConfigCaptable,
	InviteChooseFund,
	InviteOnboardingPayment,
	MonthlyBillingSteps,
	OnboardingPaymentCurrentPage,
	PayNowCurrentPage,
	PayNowSteps,
	PersonalIdentificationForm,
	ProcessingCloseShowState,
	SignOption,
	SuccessDetails,
	VerifyingDetails,
	useResetInvitationFlow,
} from '.';
import {
	DEFAULT_FUND_OUT_TABLE,
	DEFAULT_FUND_TABLE,
	DEFAULT_FUND_TRANSFER_TABLE,
	DEFAULT_MAP_CAPTABLE_ROW,
	DEFAULT_TABLE,
	FUND_MAP_CAPTABLE_ROW,
} from '../../invite-investor/constants/default-cap-table';
import { SelectBankFlow } from '../components/select-bank-flow';
import {
	KEYLEVEL,
	ProviderEnum,
	accreditationMendatoryFields,
	defaultMapColumn,
	defaultMapColumnFund,
	defaultMapColumnFundOut,
	mendatoryFields,
	proofReadingKey,
} from '../constants';
import {
	BusinessInformationErrorState,
	BusinessInformationState,
	PersonalInformationErrorState,
	PersonalInformationId,
	PersonalInformationState,
} from './address-details/store';
import { ComplexConfig } from './complex-config';
import { usePayInUnitPricing } from '../hooks';
import {
	BusinessFormDataType,
	ComplexConfigStep,
	ComplexConfigurationInvite,
	ConditionalOnboardingFlowState,
	FundsIdState,
	SIGN_OPTIONS,
	SignOptionState,
	parseBusinessReqData,
	parsePersonalReqData,
	useInviteInvestor,
	useSubmitBusinessFrom,
	useSubmitPersonalForm,
	userCapTableState,
} from './store';
import { UploadProofDocument, UploadProofDocumentState } from 'views/upload-proof-document';

interface IPropsOnboardingInvite {
	from: 'funds' | 'onboardings';
}

export const OnboardingInviteModal: FC<IPropsOnboardingInvite> = ({ from }) => {
	//globle state
	const currentPagePayNow = useRecoilValue(PayNowCurrentPage);
	const conditionalPipelines = useRecoilState(AllConditionalPipelinesState);
	const currentPageMonthly = useRecoilValue(OnboardingPaymentCurrentPage);
	const [subSelectedItem, setSubSelectedItem] = useRecoilState(
		SelectedFundRecipientBankAccount
	);
	const { fundInvestment } = useRecoilValue(ComplexConfigurationInvite);
	const resetComplexConfiguration = useResetRecoilState(
		ComplexConfigurationInvite
	);
	const resetConfigSteps = useResetRecoilState(ComplexConfigStep);

	const [fundRecipient, setFundRecipient] = useRecoilState(
		FundRecipientBankAccount
	);
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const isComplex = useRecoilValue(ConditionalOnboardingFlowState);

	const setLoading = useSetRecoilState(RecipientBankAccountLoadingState);
	//local state
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedItem, setSelectedItem] = useState<any>();
	const [token, setToken] = useState('');

	const [isModal, setIsModal] = useRecoilState(OpenInviteSelectorModal);
	const [selectedFund, setSelectedFund] = useRecoilState(
		SelectedInviteFundState
	);
	const fundType = useRecoilValue(SelectedFundType);

	const [navigate, setNavigate] = useRecoilState(InviteNavigateModalState);
	const [fundsIdState, setFundsIdState] = useRecoilState(FundsIdState);

	const grid = useRecoilValue(InvestorTableGridData);
	const captableLoading = useRecoilValue(IsCapTableLoading);

	const pipelineId = useRecoilValue(PipelineIdState);
	const uploadedFiles = useRecoilValue(UploadedDocsState);
	const resetGrid = useResetRecoilState(InvestorTableGridData);
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);
	const [funds, setFunds] = useRecoilState(FundsState);
	const isNewFund = useRecoilValue(IsCreatingFundState);
	const [userCapTableData, setCapTableData] = useRecoilState(userCapTableState);
	const [selectedValue, setSelectedValue] = useRecoilState(
		CapTableMapKeyAndValue
	);
	const reminderState = useRecoilValue(SendNotificationState);
	const payNowCurrentPage = useRecoilValue(PayNowCurrentPage);
	const [personalInformationValue, setPersonalFormData] = useRecoilState(
		PersonalInformationState
	);
	const personalInformationError = useRecoilValue(
		PersonalInformationErrorState
	);

	const [businessInformationValue, setCompanyFormData] = useRecoilState(
		BusinessInformationState
	);
	const businessInformationError = useRecoilValue(
		BusinessInformationErrorState
	);
	const [showProcessingClose, setShowProcessingClose] = useRecoilState(
		ProcessingCloseShowState
	);
	const activeOption = useRecoilValue(SignOptionState);
	const multiSignConfigurationStatus = useRecoilValue(
		SignAgreementComplexStepConfigured
	);
	const [linearMultiSignStepConfigured, setLinearMultiSignStepConfigured] = useRecoilState(
		LinearMultiSignAgreementStepConfigured
	);

	const showSeletedValue = useRecoilValue(ShowSelectedField);
	const resetShowSeletedValue = useResetRecoilState(ShowSelectedField);

	const resetNotificationReminder = useResetRecoilState(SendNotificationState);
	const selectedMultipleDate = useRecoilValue(SelectedMultipleDateState);

	// escore business reset state
	const resetBusinessForm = useResetRecoilState(BusinessInformationState);
	const resetPersonalForm = useResetRecoilState(PersonalInformationState);
	const resetPersonalId = useResetRecoilState(PersonalInformationId);
	const resetSelectedMultipleDate = useResetRecoilState(
		SelectedMultipleDateState
	);
	const resetpipelineDetails = useResetRecoilState(SelectedPipelineDetails);
	const resetBussinessInfoError = useResetRecoilState(BussinessInfoErrorState);

	const { get: fetchFortressEscrowIdentity, loading: fortressEscrowLoading } =
		useNetwork();

	// Fortress Escrow form hooks
	const { handleSubmitBusinessForm, escrowBusinessData, escrowLoading } =
		useSubmitBusinessFrom();
	const { handleSubmitPersonalForm, escrowPersoanlLoading } =
		useSubmitPersonalForm();
	const [plId, setPlId] = useRecoilState(PersonalInformationId);
	const resetCapTableData = useResetRecoilState(userCapTableState);
	const selectBankFlow = useRecoilValue(SelectedBankFlowState);
	const resetSelectBankFlow = useResetRecoilState(SelectedBankFlowState);
	const resetFundId = useResetRecoilState(FundsIdState);
	const resetUploadProofDocument = useResetRecoilState(
		UploadProofDocumentState
	);
	const uploadProofDocument = useRecoilValue(UploadProofDocumentState);

	// envelope multisign in onboarding flow
	const [isMultiSignModalOpen, setIsMultiSignModalOpen] =
		useRecoilState(IsMultiSignModalOpen);
	const resetMultisignEsignConfig = useResetRecoilState(
		SignAgreementComplexStepConfigured
	);
	const resetSelectedNode = useResetRecoilState(
		SelectedComplexSignAgreementStep
	);
	const resetSignOption = useResetRecoilState(SignOptionState);
	const [oldCaptableData, setOldCaptableState] = useRecoilState(CaptableOldState);

	// local states
	const [isValid, setIsValid] = useState<boolean[]>([]);

	// hooks
	const { resetInvitationFlow } = useResetInvitationFlow();
	const {
		post: createFund,
		loading: creatingFund,
		patch: updateFund,
	} = useNetwork();
	const { get: fetchBusinessBankAccounts } = useNetwork();

	const { post: submitExchangeToken } = useNetwork();
	const { patch: submitReminder, loading: reminderLoading } = useNetwork();

	const { getFunds } = useFunds();
	const { successNotification, errorNotification, warningNotification } = useNotification();
	const { validateInputs } = useInviteInvestor();
	const { post: generateToken, data: tokenResponse } = useNetwork();
	const { trackEvents } = useTrackEvents();
	const { isAllowedToInvite } = useAllowedInvites();
	const { isAllSignDocNodesHaveEsignProvider } = useGetSignDocument();
	const { isPayInUnitPricing } = usePayInUnitPricing();

	//constants
	const { proofReading } = proofReadingKey;

	const flowNotHaveFundInvest = useMemo(
		() =>
			!/payIn|payOut|fundInvestment/gi.test(pipelineDetails?.fullStatus) &&
			pipelineDetails?.fullStatus &&
			from !== 'funds',
		[pipelineDetails?.fullStatus, from]
	);

	const isFundInvestment = useMemo(
		() =>
			pipelineDetails?.fullStatus?.includes('fundInvestment') ||
			pipelineDetails?.fullStatus?.includes('payIn'),
		[pipelineDetails?.fullStatus]
	);

	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const payload = {
				bankName: metadata.institution?.name,
				linkToken: token,
				token: publicToken,
			};

			setLoading(true);
			setIsModal(true);
			const response = await submitExchangeToken(
				API_URL.TokenExchange,
				payload
			);
			if (response?.id) {
				successNotification('Account Linked Successfully');
				setNavigate(INVITE_MODAL_STEPS.CONNECTBANK);
			}
			setToken('');
			fetchBusinessBankAccounts(API_URL.BusinessBankAccounts)
				.then(res => {
					if (res.data) {
						setFundRecipient(res.data);
						if (
							res.data.length > 0 &&
							res.data[0]?.accounts.length > 0 &&
							Object.keys(subSelectedItem ?? {}).length === 0 &&
							Object.keys(selectedItem ?? {}).length === 0
						) {
							setSelectedItem(res.data[0]);
							setSubSelectedItem({
								...res.data[0]?.accounts[0],
								_id: res.data[0]?._id,
							});
						}
					}
					setLoading(false);
				})
				.catch(err => {
					errorNotification(err.message ?? MESSAGE.ERROR);
					setLoading(false);
				});
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token, selectedItem]
	);

	//not required for now keep it for future use
	const onEvent = useCallback<PlaidLinkOnEvent>(() => ({}), []);

	//not required for now keep it for future use
	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setToken('');
		setIsModal(true);
	}, [setIsModal]);

	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onEvent,
		onExit,
	};

	const { open } = usePlaidLink(config);

	useEffect(() => {
		if (tokenResponse?.token) {
			setToken(tokenResponse?.token);
			setIsModal(false);
			open();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, tokenResponse?.token]);

	//states if the signAgreement document is configured or not
	const handleUncheckedSignAgreement = useCallback(() => {
		const unConfiguredFileIndex = uploadedFiles.findIndex(
			({ isChecked, configured }) => {
				if (isChecked) {
					return !configured;
				}
				return false;
			}
		);

		if (unConfiguredFileIndex > -1) {
			return true;
		}
		return false;
	}, [uploadedFiles]);

	// this will manage the disable of the invite btn in the sign agreement section
	const handleDisableSignAggrement = useCallback(() => {
		const docLength = uploadedFiles.length;

		const isAnyCheckedFile = uploadedFiles.findIndex(
			({ isChecked }) => isChecked
		);

		const isAllCheckedConfigured = uploadedFiles.findIndex(
			({ configured, isChecked }) => isChecked && !configured
		);

		if (
			docLength === 0 ||
			isAnyCheckedFile === -1 ||
			isAllCheckedConfigured > -1
		) {
			return true;
		}
		return false;
	}, [uploadedFiles]);

	const handleNavigate = useCallback(() => {
		const { nodes, fullStatus } = pipelineDetails ?? {};

		if (
			nodes &&
			fullStatus?.includes('signAgreement') &&
			isAllSignDocNodesHaveEsignProvider
			 ) {
			setNavigate(INVITE_MODAL_STEPS.SIGNOPTIONS);
		} else if (nodes) {
			// Arun kumar: showing csv first in complex invite
			setNavigate(INVITE_MODAL_STEPS.CSV);
		} else if (
			fullStatus?.includes('fundInvestment') ||
			fullStatus?.includes('payIn')
		) {
			setNavigate(INVITE_MODAL_STEPS.CREATE);
		} else if (fullStatus?.includes('payOut')) {
			setNavigate(INVITE_MODAL_STEPS.CONNECTBANK);
		} else if (
			fullStatus?.includes('signAgreement') &&
			isAllSignDocNodesHaveEsignProvider			
			) {
			setNavigate(INVITE_MODAL_STEPS.SIGNOPTIONS);
		} else {
			setNavigate(INVITE_MODAL_STEPS.CSV);
		}
		// setNavigate("billing");
	}, [pipelineDetails, isAllSignDocNodesHaveEsignProvider, setNavigate]);

	// this will manage the close of the modal
	const handleClose = useCallback(() => {
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet?.exitEditMode();

		resetInvitationFlow();
		resetSelectBankFlow();
		resetNotificationReminder();
		resetSelectedMultipleDate();
		resetFundId();
		resetBusinessForm();
		resetPersonalForm();
		resetPersonalId();
		resetComplexConfiguration();
		resetConfigSteps();
		resetShowSeletedValue();
		resetBussinessInfoError();
		resetUploadProofDocument();
		setTimeout(() => {
			handleNavigate();
			resetpipelineDetails();
			if (
				pipelineDetails?.fullStatus?.includes('fundInvestment') ||
				pipelineDetails?.fullStatus?.includes('payIn')
			)
				setNavigate(INVITE_MODAL_STEPS.CREATE);
		}, 500);
		resetCapTableData();
		document.body.classList.remove('captable');
		setShowProcessingClose(false);
		resetMultisignEsignConfig();
		resetSelectedNode();
		resetSignOption();
		setLinearMultiSignStepConfigured(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		finalXlsValue?.luckysheet?.destroy();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		resetPersonalId,
		pipelineDetails?.fullStatus,
		resetComplexConfiguration,
		resetConfigSteps,
	]);

	const getConfigCapTable = useMemo(() => {
		const { fullStatus } = pipelineDetails ?? {};
		const { headers } = userCapTableData ?? {};
		const dropDownOptions = headers?.map((header: string) => ({
			label: header,
			value: header,
		}));

		if (
			fullStatus?.includes('fundInvestment') ||
			(fullStatus?.includes('payIn') && !isPayInUnitPricing) ||
			fullStatus?.includes('payOut')
		) {
			return FUND_MAP_CAPTABLE_ROW.map(item => {
				const Payload = {
					options: dropDownOptions,
				};
				return { ...Payload, ...item };
			});
		} else if (isNewFund) {
			return FUND_MAP_CAPTABLE_ROW.map(item => {
				const Payload = {
					options: dropDownOptions,
				};
				return { ...Payload, ...item };
			});
		} else {
			return DEFAULT_MAP_CAPTABLE_ROW.map(item => {
				const Payload = {
					options: dropDownOptions,
				};
				return { ...Payload, ...item };
			});
		}
	}, [isNewFund, pipelineDetails, userCapTableData , isPayInUnitPricing]);

	// this will disable the submit btn collectively
	const isSubmitDisabled = useMemo(() => {
		const isError = Object.values(personalInformationError).some(val => val);
		const isValue = Object.values(personalInformationValue).some(val => !val);

		const hasError = Object.values(businessInformationError).some(val => val);
		const hasValue = Object.values(businessInformationValue).some(val => !val);

		switch (navigate) {
			case 'create':
				if (
					!selectedFund.value ||
					creatingFund ||
					(fundType === 'edit' && selectedFund.label === '')
				) {
					return true;
				}
				return false;
			case 'connectBank':
				if (fundRecipient.length > 0) {
					return false;
				}
				return true;
			case 'personalForm':
				return isError || isValue || escrowPersoanlLoading ? true : false;
			case 'businessForm':
				return hasValue || hasError ? true : false;
			case 'reminderNotification':
				return reminderLoading ?? false;
			case 'csv':
				// let temp: any = grid?.[1]?.findIndex((item) => {
				//   return item.value !== "";
				// });
				// if (creatingFund) return true;
				// else if (temp >= 0 || isNewFund) {
				//   return false;
				// } else if (isEntered) {
				//   return false;
				// }
				return false;
			case 'configCaptable':
				return showSeletedValue.length > 0 ? true : false;
			case 'signAggrement':
				return handleDisableSignAggrement();
			case 'selectBankFLow':
				if (selectBankFlow === '') return true;
				return false;

			case 'configComplex':
				const { nodes } = pipelineDetails?.design ?? {};

				const allIds = nodes
					?.filter((el: any) =>
						/signAgreementVerification|fundInvestmentVerification|proofVerification/.test(
							el.key
						)
					)
					?.map((el: any) => el.id);

				const allConfiguredSignAgreement = uploadedFiles.map(el => el.node);
				const allMultiSignStepConfigured = Object.keys(
					multiSignConfigurationStatus
				).filter(node => multiSignConfigurationStatus[node]?.status === 'configured');
				const allConfiguedFundInvestment = fundInvestment.map(
					(el: any) => el.node
				);
				const allConfiguredProofReading = uploadProofDocument.map(
					el => el.node
				);
				const allConfiguredSteps = [
					...allConfiguredSignAgreement,
					...allConfiguedFundInvestment,
					...allMultiSignStepConfigured,
					...allConfiguredProofReading,
				];

				const data = allIds?.every((el: string) =>
					allConfiguredSteps.includes(el)
				);

				return !data;
			case proofReading:
				return !uploadProofDocument.length;
			default:
				return false;
		}
	}, [
		personalInformationError,
		personalInformationValue,
		businessInformationError,
		businessInformationValue,
		navigate,
		selectedFund.value,
		selectedFund.label,
		creatingFund,
		fundType,
		fundRecipient.length,
		escrowPersoanlLoading,
		reminderLoading,
		showSeletedValue.length,
		handleDisableSignAggrement,
		selectBankFlow,
		pipelineDetails?.design,
		uploadedFiles,
		multiSignConfigurationStatus,
		fundInvestment,
		uploadProofDocument,
		proofReading,
	]);

	const handleBackToInvestor = useCallback(() => {
		const finalXlsValue: any = window;
		switch (navigate) {
			case 'create':
				setSelectedFund({ label: '', value: '' });
				// if (flowNotHaveFundInvest) {
				// 	setNavigate('csv');
				// 	break;
				// }
				handleClose();
				break;
			case 'connectBank':
				if (pipelineDetails?.fullStatus?.includes('payOut')) {
					handleClose();
					break;
				}
				// this will be used when escrow will be implemented
				// setNavigate("selectBankFLow");
				setNavigate(INVITE_MODAL_STEPS.CREATE);
				break;
			case 'signOptions':
				handleClose();
				break;
			case 'csv':
				if (pipelineDetails?.nodes) {
					if (pipelineDetails?.fullStatus?.includes('signAgreement')) {
						setNavigate(INVITE_MODAL_STEPS.SIGNOPTIONS);
						return;
					}
					// Arun kumar: on back click of on csv screen, it should close
					handleClose();
					return;
				}
				if (pipelineDetails?.fullStatus?.includes('signAgreement')) {
					setNavigate(INVITE_MODAL_STEPS.SIGNOPTIONS);
					return;
				}
				finalXlsValue?.luckysheet.exitEditMode();
				if (fundsIdState.hasEscrowLink) {
					setNavigate(INVITE_MODAL_STEPS.SELECTBANKFLOW);
					return;
				}
				if (
					pipelineDetails?.fullStatus?.includes('fundInvestment') ||
					pipelineDetails?.fullStatus?.includes('payIn') ||
					pipelineDetails?.fullStatus?.includes('payOut') ||
					isNewFund
				) {
					setNavigate(INVITE_MODAL_STEPS.CONNECTBANK);
				} else {
					handleClose();
				}
				break;
				setTimeout(() => resetGrid(), 500);
				break;
			case 'signAggrement':
				if (isComplex) {
					// Arun kumar: on back click of on configComplex screen
					setNavigate(INVITE_MODAL_STEPS.CONFIGCOMPLEX);
					// setNavigate('configComplex');
					break;
				}
				setNavigate(INVITE_MODAL_STEPS.CSV);
				break;
			case 'reminderNotification':
				if (
					pipelineDetails?.fullStatus?.includes('fundInvestment') ||
					pipelineDetails?.fullStatus?.includes('payIn') ||
					pipelineDetails?.fullStatus?.includes('payOut') ||
					isNewFund
				) {
					setNavigate(INVITE_MODAL_STEPS.CONNECTBANK);
				} else {
					handleClose();
				}
				break;
			case 'configCaptable':
				setNavigate(INVITE_MODAL_STEPS.CSV);
				break;
			case 'selectBankFLow':
				setNavigate(INVITE_MODAL_STEPS.CREATE);
				break;
			case 'personalForm':
				resetPersonalForm();
				setNavigate(INVITE_MODAL_STEPS.SELECTBANKFLOW);
				break;
			case 'businessForm':
				resetBusinessForm();
				setNavigate(INVITE_MODAL_STEPS.PERSONALFORM);
				break;
			case 'configComplex':
				const captableData = getLuckSheetData();
				const signAgreementInFlow = pipelineDetails?.fullStatus?.includes('signAgreement');
				if (signAgreementInFlow && activeOption === SIGN_OPTIONS.COMMON) {
					warningNotification(
						'If any changes are made to the cap table details, the configurations for signing agreements will be lost.'
					);
				}
				setNavigate(INVITE_MODAL_STEPS.CSV);
				setOldCaptableState(captableData);
				break;

			case proofReading:
				if (isComplex) {
					// Arun kumar: on back click of on configComplex screen
					setNavigate('configComplex');
					break;
				}
				if (pipelineDetails?.fullStatus?.includes('signAgreement')) {
					setNavigate('signAggrement');
					break;
				}
				setNavigate('csv');
				break;
			default:
				break;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fundsIdState.hasEscrowLink, handleClose, isComplex, isNewFund, navigate, pipelineDetails?.fullStatus, pipelineDetails?.nodes, resetBusinessForm, resetGrid, resetPersonalForm, getLuckSheetData]);

	const validateCaptable = useCallback(() => {
		// pradeep chaurasia : an issue in transfer amount fixed
		const { rows = [], headers = [] } = getLuckSheetData(
			pipelineDetails?.fullStatus?.includes('payIn')
		);
		setCapTableData({ rows, headers });
		let validateRow = true;
		selectedValue.forEach(item => {
			const key: any = Object.keys(item)[0];
			const value = item[key];
			const index = headers.indexOf(value);

			if (key !== 'Country Code' && key !== 'Mobile') {
				if (key === 'Dollars Invested') {
					const dollarsInvestedIndex = headers.indexOf(value);
					rows.forEach((rowItem: any, j: any) => {
						const dollarsInvestedValid = isValidDecimal(
							rowItem[dollarsInvestedIndex]
						);
						if (!dollarsInvestedValid && validateRow) {
							errorNotification(
								`Please fill the valid amount in   ${number2words(j + 2)} row`
							);
							validateRow = false;
							setNavigate(INVITE_MODAL_STEPS.CSV);
						}
						// pradeep chaurasia : minimum amount validate
						if (
							dollarsInvestedValid &&
							rowItem[dollarsInvestedIndex] > 0 &&
							rowItem[dollarsInvestedIndex] < 0.5
						) {
							errorNotification(
								`Minimum Amount should be $0.5 in ${number2words(j + 2)} row.`
							);
							validateRow = false;
							setNavigate(INVITE_MODAL_STEPS.CSV);
						}
					});
				}
				rows.forEach((rowItem: any, j: any) => {
					if (!rowItem[index] && rowItem.length && validateRow) {
						errorNotification(
							`Please fill the ${value} in   ${number2words(
								j + 2
							)} row and match the column`
						);
						validateRow = false;
						setNavigate(INVITE_MODAL_STEPS.CSV);
					}

					if (key === 'First Name' || key === 'Last Name') {
						const validName = isValidName(rowItem[index]);
						if (!validName && validateRow) {
							errorNotification(
								`Please fill the valid ${value.toLowerCase()} in   ${number2words(
									j + 2
								)} row`
							);
							validateRow = false;
							setNavigate(INVITE_MODAL_STEPS.CSV);
							return;
						}
					}

					if (key === 'Email') {
						const emailValid = isEmailValid(rowItem[index]);
						if (!emailValid && validateRow) {
							errorNotification(
								`Please fill the valid email in   ${number2words(j + 2)} row`
							);
							validateRow = false;
							setNavigate(INVITE_MODAL_STEPS.CSV);
							return;
						}
					}
				});
			} else {
				rows.forEach((rowItem: any, j: any) => {
					if (key === 'Country Code') {
						const index = headers.indexOf(value);
						const getValue = rowItem[index];
						const countryCodeValid =
							isNumber(getValue) &&
							getValue.length >= 1 &&
							getValue.length <= 6;
						if (!countryCodeValid && validateRow) {
							errorNotification(
								`Please fill the valid country code in ${number2words(
									j + 2
								)} row`
							);
							validateRow = false;
							setNavigate(INVITE_MODAL_STEPS.CSV);
						}
					} else if (key === 'Mobile') {
						const index = headers.indexOf(value);
						const getValue = rowItem[index];
						const mobileValid =
							isNumber(getValue) &&
							getValue.length >= 8 &&
							getValue.length <= 14;
						if (!mobileValid && validateRow) {
							errorNotification(
								`Please fill the valid mobile in ${number2words(j + 2)} row`
							);
							validateRow = false;
							setNavigate(INVITE_MODAL_STEPS.CSV);
						}
					}
				});
			}
		});
		return validateRow;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue, userCapTableData]);


	const validateCaptableData = useCallback(() => {
		const { rows = [], headers = [] } = getLuckSheetData(
			pipelineDetails?.fullStatus?.includes('payIn')
		);
		setCapTableData({ rows, headers });
		let validateRow = true;
		const rowEmails: string[] = [];
		const rowMobiles: string[] = [];

		selectedValue.forEach(item => {
			const key: any = Object.keys(item)[0];
			const value = item[key];
			const index = headers.indexOf(value);
			// if (value !== 'Fund Transfer Amount')
			rows.forEach((rowItem: any, j: any) => {
				if (!rowItem[index] && rowItem.length && validateRow) {
					errorNotification(
						`Please fill the ${value} in  ${number2words(j + 2)} row `
					);
					validateRow = false;
					return;
				}

				if (key === 'First Name' || key === 'Last Name') {
					const nameIndex = headers.indexOf(key);
					const validName = isValidRecipientName(rowItem[nameIndex]);
					if (!validName && validateRow) {
						errorNotification(
							`Please fill in a valid ${key.toLowerCase()} in row ${number2words(
								j + 2
							)}. The ${key.toLowerCase()} field only accepts valid characters.`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Country Code') {
					const countryCodeIndex = headers.indexOf('Country Code');
					const countryCodeValid =
						isNumber(rowItem[countryCodeIndex]) &&
						rowItem[countryCodeIndex].length >= 1 &&
						rowItem[countryCodeIndex].length <= 6;

					if (!countryCodeValid && validateRow) {
						errorNotification(
							`Please fill the valid country code in ${number2words(
								j + 2
							)} row`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Mobile') {
					const mobileIndex = headers.indexOf('Mobile');
					const currentMobiles = rowItem[mobileIndex];
					rowMobiles.push(currentMobiles);
					const mobileValid =
						isNumber(currentMobiles) &&
						currentMobiles.length >= 8 &&
						currentMobiles.length <= 14;
					if (!mobileValid && validateRow) {
						errorNotification(
							`Please fill the valid mobile in   ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Email') {
					const emailIndex = headers.indexOf('Email');
					const currentEmail = rowItem[emailIndex];
					const emailValid = isEmailValid(currentEmail);
					rowEmails.push(currentEmail?.toLowerCase());
					if (!emailValid && validateRow) {
						errorNotification(
							`Please fill the valid email in   ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Dollars Invested' && value === 'Fund Transfer Amount') {
					const fundTransferAmount = headers.indexOf('Fund Transfer Amount');
					const validAmount = isValidAmount(rowItem[fundTransferAmount]);
					if (!validAmount && validateRow) {
						errorNotification(
							`Please fill only numeric values for amount in ${number2words(
								j + 2
							)} row.`
						);
						validateRow = false;
						return;
					}
					// pradeep chaurasia : minimum amount validate
					if (
						validAmount &&
						rowItem[fundTransferAmount] > 0 &&
						rowItem[fundTransferAmount] < 0.5
					) {
						errorNotification(
							`Minimum Amount should be $0.5 in ${number2words(j + 2)} row.`
						);
						validateRow = false;
						return;
					}
				}
				if (key === 'Dollars Invested' && value !== 'Fund Transfer Amount') {
					const dollarsInvestedIndex = headers.indexOf('Amount To Pay');
					const dollarsInvestedValid = isValidDecimal(
						rowItem[dollarsInvestedIndex]
					);
					if (!dollarsInvestedValid && validateRow) {
						errorNotification(
							`Please fill the valid amount in   ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					}
				}
			});
		});
		// Check if the current contact number has been seen before in previous rows
		const duplicateMobiles = findDuplicates(rowMobiles);
		if (duplicateMobiles.length > 0) {
			errorNotification('All contact numbers should be unique.');
			validateRow = false;
			return;
		}
		// Check if the current email has been seen before in previous rows
		const duplicateEmails = findDuplicates(rowEmails);
		if (duplicateEmails.length > 0) {
			errorNotification('All emails should be unique.');
			validateRow = false;
			return;
		}

		return validateRow;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue, pipelineDetails]);

	const updateNewFund = useCallback(async () => {
		const { rows = [], headers = [] } = getLuckSheetData();

		const indexIA = headers.indexOf('Fund Transfer Amount');
		if (indexIA !== -1) {
			headers[indexIA] = 'Dollars Invested';
		}
		const payload = {
			capTable: {
				headers,
				rows,
			},
		};
		const resp = await updateFund(
			`${API_URL.FUNDS}/${fundsIdState.fundId}`,
			payload
		);
		if (resp?._id) {
			getFunds();
			handleClose();
			successNotification('Successfully Created');
		} else {
			errorNotification(resp?.message || 'Failed to update fund.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipelineDetails, fundsIdState]);

	const updateChangeHeaderFund = useCallback(async () => {
		const { rows = [] } = getLuckSheetData();
		const updateHeader = [...(userCapTableData?.headers ?? [])];
		for (let i = 0; i < selectedValue.length; i++) {
			const key: any = Object.keys(selectedValue[i])[0];
			const value = selectedValue[i][key];
			const index = updateHeader.indexOf(value);
			updateHeader[index] = key;
		}
		const payload = {
			capTable: {
				headers: updateHeader,
				rows,
			},
		};
		const resp = await updateFund(
			`${API_URL.FUNDS}/${fundsIdState.fundId}`,
			payload
		);
		if (resp?._id) {
			getFunds();
			handleClose();
			successNotification('Successfully Created');
		} else {
			if (resp?.message?.includes('Invalid value for header')) {
				errorNotification(resp?.message ?? 'Invalid value for header');
				setNavigate(INVITE_MODAL_STEPS.CSV);
			} else {
				errorNotification(resp?.message || 'Failed to update fund.');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fundsIdState, selectedValue]);

	const handleMoveToCaptable = useCallback(async () => {
		trackEvents('create-fund-flow-name', {});
		const isAlreadyAvailable = !!funds.find(
			({ name }) =>
				name?.trim()?.toLowerCase() ===
				selectedFund.value?.trim()?.toLowerCase()
		);

		if (fundType === 'create') {
			if (isAlreadyAvailable) {
				errorNotification('Fund with this name already available.');
				return;
			}
		}
		// create fund API if new fund arrived
		if (fundType === 'create') {
			const createFdResp = await createFund(API_URL.FUNDS, {
				name: selectedFund?.value,
			});
			if (createFdResp?._id) {
				const { _id } = createFdResp ?? {};
				setFundsIdState({ fundId: _id });
				setFunds(prev => [...prev, createFdResp]);
				successNotification('Fund Created Successfully');
			} else {
				errorNotification(createFdResp?.message);
				return;
			}
		}

		if (flowNotHaveFundInvest) {
			setNavigate(INVITE_MODAL_STEPS.CSV);
			return;
		}
		// this will be used when escrow will be implemented
		// setNavigate("selectBankFLow");
		setNavigate(INVITE_MODAL_STEPS.CONNECTBANK);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackEvents, funds, fundType, flowNotHaveFundInvest, selectedFund.value]);

	const handleBankFlow = useCallback(async () => {
		if (selectBankFlow === 'bank') {
			setNavigate(INVITE_MODAL_STEPS.CONNECTBANK);
		} else {
			if (!fundsIdState) {
				errorNotification('Please create fund first!');
				return;
			}
			// check bank account linked or not
			if (fundsIdState.hasEscrowLink) {
				setNavigate(INVITE_MODAL_STEPS.CSV);
				return;
			}
			// finding Fortress Escrow Identity
			const resp = await fetchFortressEscrowIdentity(
				`${API_URL.CLIENT_IDENTITY}?provider=${ProviderEnum.Provider}`
			);

			if (resp && resp.data) {
				const { personalIdentity, businessIdentity } = resp.data ?? {};
				if (businessIdentity) {
					const { id } = businessIdentity ?? {};
					const busReq: BusinessFormDataType =
						parseBusinessReqData(businessIdentity);
					setCompanyFormData(busReq);
					setPlId(id);
				}
				const req = parsePersonalReqData(personalIdentity);
				setPersonalFormData(req);
				const { kycLevel, upgradeRequirements, id } = personalIdentity ?? {};
				if (kycLevel === KEYLEVEL.L0) {
					setPlId(id);
					setNavigate(INVITE_MODAL_STEPS.PERSONALFORM);

					if (upgradeRequirements.length > 0) {
						upgradeRequirements.forEach((item: string) =>
							errorNotification(item)
						);
					}
				} else {
					if (!businessIdentity) return setNavigate(INVITE_MODAL_STEPS.BUSINESSFORM);
					const { kycLevel } = businessIdentity ?? {};
					if (kycLevel === KEYLEVEL.L0) setNavigate(INVITE_MODAL_STEPS.BUSINESSFORM);
					else setNavigate(INVITE_MODAL_STEPS.ACCOUNTPROCESSING);
				}
			} else setNavigate(INVITE_MODAL_STEPS.PERSONALFORM);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectBankFlow, fundsIdState]);

	const onClickReminderNotification = useCallback(async () => {
		const payload = {
			dateChange: !reminderState.checked
				? reminderState.days.length > 0
					? reminderState.days
					: selectedMultipleDate
				: selectedMultipleDate.length > 0
				? selectedMultipleDate
				: reminderState.days,
		};
		const resp = await submitReminder(
			`${API_URL.PIPELINE_USERS}/${pipelineId}`,
			payload
		);

		if (!resp.message) {
			setNavigate(INVITE_MODAL_STEPS.CSV);
		} else errorNotification(resp.message);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reminderState, pipelineId, selectedMultipleDate]);

	const checkProperties = (obj: any) => {
		for (const key in obj) {
			if (obj[key] > 1) return true;
		}
		return false;
	};

	const handleNextToInvestor = useCallback(() => {
		const emptyHeader = grid?.[0]?.find((item: any) => item.value === '');
		if (emptyHeader?.value === '') {
			errorNotification('Please enter column header.');
			return;
		}

		const { fullStatus } = pipelineDetails ?? {};
		let DEFAULT_TABLE_HEADER = DEFAULT_TABLE[0];

		switch (navigate) {
			case 'create':
				handleMoveToCaptable();
				break;
			case 'connectBank':
				// if (!isNewFund) setNavigate('reminderNotification');
				// else
				if (isNewFund) {
					setNavigate(INVITE_MODAL_STEPS.CSV);
				} else {
					setNavigate(INVITE_MODAL_STEPS.SIGNOPTIONS);
				}
				break;
			case 'signOptions':
				setNavigate(INVITE_MODAL_STEPS.CSV);
				break;
			case 'reminderNotification':
				onClickReminderNotification();
				break;
			case 'personalForm':
				handleSubmitPersonalForm();
				break;
			case 'csv':
				validateInputs();
				if (conditionalPipelines) {
					DEFAULT_TABLE_HEADER = DEFAULT_TABLE[0];
				}
				if (
					fullStatus?.includes('fundInvestment') ||
					(fullStatus?.includes('payIn') && !isPayInUnitPricing)
				) {
					DEFAULT_TABLE_HEADER = DEFAULT_FUND_TABLE[0];
				} else if (fullStatus?.includes('payOut')) {
					DEFAULT_TABLE_HEADER = DEFAULT_FUND_OUT_TABLE[0];
				} else if (!conditionalPipelines && (!fullStatus || isNewFund)) {
					DEFAULT_TABLE_HEADER = DEFAULT_FUND_TRANSFER_TABLE[0];
				} else {
					DEFAULT_TABLE_HEADER = DEFAULT_TABLE[0];
				}

				const finalXlsValue: any = window;
				finalXlsValue?.luckysheet.exitEditMode();
				const finalData = finalXlsValue?.luckysheet?.transToCellData?.(
					finalXlsValue?.luckysheet.getSheetData()
				);

				const expectedArray = finalData?.reduce((acc: any, curr: any) => {
					if (!acc[curr.r]) {
						acc[curr.r] = [];
					}
					if (pipelineDetails?.fullStatus?.includes('payIn')) {
						if (curr.v.v || curr.v.v === 0) {
							acc[curr.r][curr.c] = curr.v.v?.toString();
						} else {
							if (
								acc[0][curr.c] === 'Fund Transfer Amount' &&
								acc[curr.r]?.length
							)
								acc[curr.r][curr.c] = '0';
						}
						return acc;
					} else {
						if (curr.v.v) {
							acc[curr.r][curr.c] = curr.v.v?.toString();
						} else {
							if (
								acc[0][curr.c] === 'Fund Transfer Amount' &&
								acc[curr.r]?.length
							) {
								acc[curr.r][curr.c] = '0';
							}
						}
						return acc;
					}
				}, []);

				const rows = expectedArray.filter(
					(item: any, index: number) => index !== 0 && item.length
				);

				if (!isAllowedToInvite('onboarding', (rows ?? []).length)) {
					handleClose();
					setWelcomeNewUser({
						open: true,
						type: 'Credits_Remain_With_Remaining',
						serviceName: 'Onboarding Service',
					});
					return;
				}

				let isValid = false;

				const headers = expectedArray.shift();
				const matchedHeader = DEFAULT_TABLE_HEADER?.filter(el =>
					headers.includes(el)
				);
				const isSameHeader = DEFAULT_TABLE_HEADER?.every(el =>
					matchedHeader?.includes(el)
				);
				
				const isheaderEmpty = headers.includes(undefined);
				let isRowLengthGreater = false;

				if(!expectedArray.length || !expectedArray[0].length){
					isValid = true;
				}
				                                                                                                                                                                                                                                                                                                                                         
				for (let i = 0; i < expectedArray.length; i++) {
					if (expectedArray[i]?.length) {
						const indexAmountToPay = headers.indexOf('Amount To Pay'); 
						const isIndexValid = indexAmountToPay > -1;
						const value = expectedArray?.[i]?.[indexAmountToPay] ?? '';
						const isValueInvalid = !value || value < '0';
						if (isIndexValid && isValueInvalid) {
							return errorNotification('The Amount To Pay field cannot be 0 or less then 0.');
						}
						if (expectedArray[i].length !== headers.length) {
							isValid = true;
						}
						if (expectedArray[i].length > headers.length) {
							isRowLengthGreater = true;
						}
					}
				}

				if (new Set(headers).size !== headers.length) {
					const repeatHeader: any = {};
					headers?.forEach((value: any) => {
						const trimmedValue = value?.toLowerCase().trim();
						if (repeatHeader[trimmedValue]) {
							repeatHeader[trimmedValue] = repeatHeader[trimmedValue] + 1;
						} else {
							repeatHeader[trimmedValue] = 1;
						}
					});
					const isDuplicate = checkProperties(repeatHeader);
					if (isDuplicate) {
						errorNotification('Duplicate header value encountered');
						return;
					}
				}

				if (isheaderEmpty || isRowLengthGreater) {
					errorNotification('Header should not be empty');
					return;
				}

				if (isValid) {
					errorNotification('Please fill all fields');
					return;
				}

				if (
					pipelineDetails?.fullStatus?.includes('signAgreement') &&
					activeOption === SIGN_OPTIONS.COMMON &&
					!isComplex
					) {	
					if(validateCaptableData()) {
						const lucksheetData = getLuckSheetData();
							const isEqual = deepCompareLucksheetData(
								lucksheetData,
								oldCaptableData
							);
						if (!linearMultiSignStepConfigured || (!isEqual && JSON.stringify(oldCaptableData) !== '{}')) {
							setIsMultiSignModalOpen(true);
						} else {
							setNavigate(INVITE_MODAL_STEPS.BILLING);
						}
					}			 	
					return;
				}

				if (isSameHeader) {
					if (validateCaptableData()) {
						if (isNewFund) {
							updateNewFund();
						} else if (
							pipelineDetails?.fullStatus?.includes('signAgreement') &&
							!pipelineDetails.nodes
						) {
							// Arun kumar: showing configcomplex if isComplex
							if (isComplex) {
								setNavigate(INVITE_MODAL_STEPS.CONFIGCOMPLEX);
							} else setNavigate(INVITE_MODAL_STEPS.SIGNAGGREMENT)
						} else if (
							pipelineDetails?.fullStatus?.includes(proofReading)
						) {
							if (isComplex) {
								setNavigate(INVITE_MODAL_STEPS.CONFIGCOMPLEX);
							} else setNavigate(proofReading);
						} else {
							if (isComplex) {
								setNavigate(INVITE_MODAL_STEPS.CONFIGCOMPLEX);
							} else setNavigate(INVITE_MODAL_STEPS.BILLING);
						}
					}
				} else {
					setNavigate(INVITE_MODAL_STEPS.CONFIGCAPTABLE);
				}

				trackEvents('create-fund-flow-csv', { emptyHeader });
				break;
			case 'configCaptable':
				if (validateCaptable()) {
					if (isNewFund) {
						updateChangeHeaderFund();
					} else if (pipelineDetails?.fullStatus?.includes('signAgreement')) {
						setNavigate(INVITE_MODAL_STEPS.SIGNAGGREMENT);
					} else if (
						pipelineDetails?.fullStatus?.includes(proofReading)
					) {
						setNavigate(proofReading);
					} else {
						setNavigate(INVITE_MODAL_STEPS.BILLING);
					}
				}
				break;
			case 'signAggrement':
				if (handleUncheckedSignAgreement()) {
					errorNotification(
						'Please configure the document before moving to the next step'
					);
				}
				//Gaurav: Added to show proofReadDocuments.
				else if (pipelineDetails?.fullStatus?.includes(proofReading)) {
					setNavigate(proofReading);
				} else {
					// validateInputs();
					setNavigate(INVITE_MODAL_STEPS.BILLING);
				}
				break;
			case 'selectBankFLow':
				handleBankFlow();
				break;
			case 'businessForm':
				handleSubmitBusinessForm();
				break;
			case 'configComplex':
				setNavigate(INVITE_MODAL_STEPS.BILLING);
				break;
			case proofReading:
				setNavigate(INVITE_MODAL_STEPS.BILLING);
				break;
			default:
				break;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		navigate,
		grid,
		isValid,
		isNewFund,
		pipelineDetails?.fullStatus,
		errorNotification,
		handleMoveToCaptable,
		setNavigate,
		onClickReminderNotification,
		handleSubmitPersonalForm,
		validateInputs,
		activeOption,
		plId,
		conditionalPipelines,
		isAllowedToInvite,
		isComplex,
		trackEvents,
		validateCaptable,
		handleUncheckedSignAgreement,
		handleBankFlow,
		handleSubmitBusinessForm,
		oldCaptableData,
		setLinearMultiSignStepConfigured,
		handleClose,
		setWelcomeNewUser,
		linearMultiSignStepConfigured,
		setIsMultiSignModalOpen,
		validateCaptableData,
		updateNewFund,
		updateChangeHeaderFund,
		isPayInUnitPricing,
	]);

	const renderHeader = useMemo(() => {										
		switch (navigate) {
			case INVITE_MODAL_STEPS.CONFIGCOMPLEX :
				return {
					head: 'Configuration Steps',
					desc: 'Follow these steps to configure your steps properly. Ensure that you carefully review and adjust each setting to meet your specific requirements.',
				};
			case INVITE_MODAL_STEPS.CREATE :
				return {
					head: 'Fund Detail',
					desc: 'Create fund',
				};
			case INVITE_MODAL_STEPS.REMINDERNOTIFICATION :
				return {
					head: 'Reminder notification ',
					desc: 'These are the notifications to remind the user to complete their verification.',
				};
			case INVITE_MODAL_STEPS.SIGNOPTIONS :
				return {
					head: 'Choose document signing option',
					desc: '',
				};
			// case "create":
			//   return {
			//     head: "Business Identification Details",
			//     desc: "Payment method is required so it can billed according to your usage",
			//   };
			// case "create":
			//   return {
			//     head: "Personal Identification Details",
			//     desc: "Payment method is required so it can billed according to your usage",
			//   };
			case INVITE_MODAL_STEPS.CONNECTBANK :
				return {
					head: 'Fund Recipient Bank Account',
					desc: 'Please select or add a fund recipient bank to receive fund investment.',
				};
			case INVITE_MODAL_STEPS.CSV :
				return {
					head: 'Captable',
					desc: `Paste your captable data here to send ${
						pipelineDetails?.fullStatus?.includes('506b') ||
						pipelineDetails?.fullStatus?.includes('506c')
							? 'investors'
							: 'users'
					} an invite for approval on their email and phone.`,
				};
			case INVITE_MODAL_STEPS.CONFIGCAPTABLE :
				return {
					head: 'Match Column Type',
					desc: 'Captable need to be matched with their respective type to invite user for onboarding.',
				};
			case INVITE_MODAL_STEPS.SIGNAGGREMENT :
				return {
					head: 'Agreement Document',
					desc: 'This action allows user to electronically sign a document. Please upload all the documents that require signatures.',
				};
			case INVITE_MODAL_STEPS.BILLING :
				return {
					head: 'Billing',
					desc: 'Pay the invitation fee at one go or add in your monthly billing',
				};
			case INVITE_MODAL_STEPS.SELECTBANKFLOW :
				return {
					head: 'Select Fund Processing Method',
					desc: 'Choose a method to process funds for pay in and payout',
				};
			case INVITE_MODAL_STEPS.PERSONALFORM :
				return {
					head: 'Personal Identification Details',
					desc: 'Payment method is required so it can billed according to your usage',
				};
			case INVITE_MODAL_STEPS.BUSINESSFORM :
				return {
					head: 'Business Identification Details',
					desc: 'Payment method is required so it can billed according to your usage',
				};
			case proofReading:
				return {
					head: proofReadingKey.DocumentReview,
					desc: 'Upload all the documents that you want the end user to read.',
				};
			default:
				return {
					head: 'Fund Detail',
					desc: 'Create fund or select fund',
				};
		}
	}, [navigate, pipelineDetails?.fullStatus, proofReading]);

	useEffect(() => {
		handleNavigate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipelineDetails?.fullStatus]);

	// connecting iplad bank services
	const handleGenerateToken = useCallback(() => {
		const payload = {
			language: 'en',
			countryCodes: ['US'],
		};
		generateToken(API_URL.GenerateLinkToken, payload);
	}, [generateToken]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const props = {
		setSelectedIndex,
		selectedIndex,
		setSelectedItem,
		selectedItem,
		setSubSelectedItem,
		subSelectedItem,
	};

	const renderPage = useMemo(() => {
		switch (navigate) {
			case INVITE_MODAL_STEPS.CONFIGCOMPLEX :
				return <ComplexConfig />;
			case INVITE_MODAL_STEPS.SIGNOPTIONS :
				return <SignOption />;
			case INVITE_MODAL_STEPS.CREATE :
				return <InviteChooseFund />;
			case INVITE_MODAL_STEPS.CONNECTBANK :
				return (
					<FundInvestment
						{...props}
						handleGenerateToken={handleGenerateToken}
					/>
				);
			case INVITE_MODAL_STEPS.REMINDERNOTIFICATION :
				return (
					<div className="reminder_page_main">
						<ReminderPage hasInviteModal />
					</div>
				);
			case INVITE_MODAL_STEPS.CSV :
				return (
					<UploadCSVInput
						selectedDetail={pipelineDetails?.fullStatus ?? []}
						handleClose={handleClose}
					/>
				);
			case INVITE_MODAL_STEPS.SIGNAGGREMENT :
				return <SignAgreement />;
			case INVITE_MODAL_STEPS.CONFIGCAPTABLE:
				return 	<ConfigCaptable capTableHeader={getConfigCapTable ?? []} />
			case  INVITE_MODAL_STEPS.SELECTBANKFLOW :
				return <SelectBankFlow {...props} />;
			case INVITE_MODAL_STEPS.BILLING :
				return <InviteOnboardingPayment />;
			case INVITE_MODAL_STEPS.PERSONALFORM :
				return <PersonalIdentificationForm />;
			case INVITE_MODAL_STEPS.BUSINESSFORM :
				return <BusinessIdentificationForm />;
			case INVITE_MODAL_STEPS.ACCOUNTPROCESSING :
				return (
					<VerifyingDetails
						response={escrowBusinessData}
						loading={escrowLoading}
					/>
				);
			case INVITE_MODAL_STEPS.ESCROWSUCCESS :
				return <SuccessDetails />;
			case proofReading:
				return <UploadProofDocument />;
			default:
				return <InviteChooseFund />;
		}
	}, [
		escrowBusinessData,
		escrowLoading,
		getConfigCapTable,
		handleGenerateToken,
		navigate,
		pipelineDetails?.fullStatus,
		props,
		handleClose,
		proofReading
	]);

	const handleBackLabel = useMemo(() => {
		switch (navigate) {
			case INVITE_MODAL_STEPS.CREATE :
				return 'Cancel';
			case INVITE_MODAL_STEPS.CONNECTBANK :
				if (pipelineDetails?.fullStatus?.includes('payOut')) {
					return 'Cancel';
				}
				return 'Back';
			case INVITE_MODAL_STEPS.SIGNOPTIONS :
				return 'Back';
			case INVITE_MODAL_STEPS.REMINDERNOTIFICATION :
				if (
					pipelineDetails?.fullStatus?.includes('fundInvestment') ||
					pipelineDetails?.fullStatus?.includes('payIn') ||
					pipelineDetails?.fullStatus?.includes('payOut') ||
					isNewFund
				)
					return 'Back';
				else return 'Cancel';
			case INVITE_MODAL_STEPS.CONFIGCAPTABLE :
			case INVITE_MODAL_STEPS.SELECTBANKFLOW :
			case INVITE_MODAL_STEPS.PERSONALFORM :
			case INVITE_MODAL_STEPS.BUSINESSFORM :
			case INVITE_MODAL_STEPS.SIGNAGGREMENT :
			case INVITE_MODAL_STEPS.CSV :
				if (
					pipelineDetails?.fullStatus?.includes('fundInvestment') ||
					pipelineDetails?.fullStatus?.includes('payIn') ||
					pipelineDetails?.fullStatus?.includes('payOut') ||
					pipelineDetails?.fullStatus?.includes('signAgreement') ||
					isNewFund
				)
					return 'Back';
				else return 'Cancel';
			case  INVITE_MODAL_STEPS.CONFIGCOMPLEX :
				return 'Cancel';
			case proofReading:
				return 'Back';
			default:
				return null;
		}
	}, [navigate, pipelineDetails?.fullStatus, isNewFund, proofReading]);

	const handleNextLabel = useMemo(() => {
		switch (navigate) {
			case INVITE_MODAL_STEPS.CREATE :
				if (flowNotHaveFundInvest) {
					return 'Save';
				}
				return creatingFund ? (
					<Loader type="loader" dimension={20} className="loader-white" />
				) : (
					'Next'
				);
			case INVITE_MODAL_STEPS.SIGNOPTIONS :
				return 'Next';
			case INVITE_MODAL_STEPS.CONNECTBANK :
				return 'Next';
			case INVITE_MODAL_STEPS.REMINDERNOTIFICATION :
				return reminderLoading ? (
					<Loader type="loader" dimension={20} className="loader-white" />
				) : (
					'Next'
				);
			case INVITE_MODAL_STEPS.CSV :
				return creatingFund ? (
					<Loader type="loader" dimension={20} className="loader-white" />
				) : (
					'Next'
				);
			case INVITE_MODAL_STEPS.SELECTBANKFLOW :
				return fortressEscrowLoading ? (
					<Loader type="loader" dimension={20} className="loader-white" />
				) : (
					'Next'
				);
			case INVITE_MODAL_STEPS.CONFIGCAPTABLE :
				if (captableLoading || creatingFund) {
					return (
						<Loader dimension={20} className="loader-white" type="loader" />
					);
				} else {
					if (isNewFund) {
						return 'Create';
					} else if (pipelineDetails?.fullStatus?.includes('signAgreement')) {
						return 'Next';
					} else {
						return 'Next';
					}
				}
			case INVITE_MODAL_STEPS.SIGNAGGREMENT :
			case INVITE_MODAL_STEPS.PERSONALFORM :
				return escrowPersoanlLoading ? (
					<Loader type="loader" dimension={20} className="loader-white" />
				) : (
					'Next'
				);
			case INVITE_MODAL_STEPS.BUSINESSFORM :
				return 'Next';
			case INVITE_MODAL_STEPS.CONFIGCOMPLEX :
				return 'Next';
			case proofReading:
				return 'Next';
			default:
				return null;
		}
	}, [
		navigate,
		flowNotHaveFundInvest,
		fortressEscrowLoading,
		captableLoading,
		creatingFund,
		escrowPersoanlLoading,
		isNewFund,
		pipelineDetails?.fullStatus,
		reminderLoading,
		proofReading
	]);

	const getMendatoryFields = useMemo(() => {
		const { fullStatus } = pipelineDetails ?? {};
		if (
			(fullStatus?.includes('506b') || fullStatus?.includes('506c')) &&
			!isFundInvestment
		) {
			return accreditationMendatoryFields;
		} else return mendatoryFields; // keeping mendatoryFields in else because  acced 506b and 506c does not requred all fields mendatory
	}, [isFundInvestment, pipelineDetails]);

	useEffect(() => {
		const allKeys = grid[0].map((object: any) => object.value);
		const keys: any = [];
		allKeys.reduce(function (e: any, i: any) {
			if (getMendatoryFields.includes(e)) keys.push(i);
			return keys;
		}, []);
		const booleanArr = grid.map((item: any, i: any) => {
			return item.map((session: any, index: any) => {
				if (keys.includes(index) && i !== 0) {
					if (session.value === '') {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			});
		});
		const keepIndex: any[] = [];
		booleanArr.filter((item: any, index: number) => {
			item.map((items: any, i: number) => {
				if (items === false && keys.includes(i)) {
					keepIndex.push(index);
				}
				return null;
			});
			return null;
		});

		const finalValue: any[] = [];
		keepIndex.map(item => finalValue.push(booleanArr[item]));
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore  as this is throwing warning as es5 is not using ... with set it required es2015 that has to be changed in ts-config
		//will be changed in future for now keep it
		const result = [...new Set(finalValue.flat())];
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		setIsValid(result);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [grid]);

	useEffect(() => {
		if (navigate === 'csv' && pipelineId) {
			document.body.classList.add('captable');
		} else {
			document.body.classList.remove('captable');
		}
	}, [navigate, pipelineId]);

	useEffect(() => {
		if (fundRecipient?.length === 0) {
			fetchBusinessBankAccounts(API_URL.BusinessBankAccounts).then(res => {
				if (res?.data) {
					setFundRecipient(res.data);
				}
			});
		}
	}, [fetchBusinessBankAccounts, fundRecipient?.length, setFundRecipient]);

	useEffect(() => {
		const { fullStatus } = pipelineDetails ?? {};
		if (
			fullStatus?.includes('fundInvestment') ||
			(fullStatus?.includes('payIn') && !isPayInUnitPricing) ||
			from === 'funds'
		) {
			//TODO: @avinash we might need this in future code commented by avinash
			// const headers: any = FUND_DEFAULT_CAPTABLE[0].map((item) => item.value);
			localStorage.setItem(
				'CAPTABLE_HEADER',
				JSON.stringify(
					userCapTableData?.headers && userCapTableData?.rows
						? [userCapTableData.headers, ...userCapTableData.rows]
						: DEFAULT_FUND_TABLE ?? []
				)
			);
			setSelectedValue(defaultMapColumnFund);
		} else if (fullStatus?.includes('payOut')) {
			//TODO: @avinash we might need this in future code commented by avinash
			// const headers: any = FUND_DEFAULT_CAPTABLE[0].map((item) => item.value);
			localStorage.setItem(
				'CAPTABLE_HEADER',
				JSON.stringify(
					userCapTableData?.headers && userCapTableData?.rows
						? [userCapTableData.headers, ...userCapTableData.rows]
						: DEFAULT_FUND_OUT_TABLE ?? []
				)
			);
			setSelectedValue(defaultMapColumnFundOut);
		} else {
			localStorage.setItem(
				'CAPTABLE_HEADER',
				JSON.stringify(
					userCapTableData?.headers && userCapTableData?.rows
						? [userCapTableData.headers, ...userCapTableData.rows]
						: DEFAULT_TABLE ?? []
				)
			);
			setSelectedValue(defaultMapColumn);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipelineDetails, userCapTableData, selectedFund, from , isPayInUnitPricing]);

	const isFooterVisible =
		/create|csv|signAggrement|connectBank|configCaptable|selectBankFLow|personalForm|reminderNotification|businessForm|configComplex|signOption|proofReading/gi.test(
			navigate
		);

	const renderModalHeader = useMemo(() => {
		if (
			navigate !== 'csv' &&
			payNowCurrentPage !== 'success' &&
			payNowCurrentPage !== 'processing_payment'
		) {
			return (
				<div className="OnboardingInviteModal-header">
					<div className="OnboardingInviteModal-head-title">
						{renderHeader?.head ?? ''}
					</div>
					<div className="OnboardingInviteModal-head-desc">
						{renderHeader?.desc ?? ''}
					</div>
				</div>
			);
		}
		return <></>;
	}, [navigate, payNowCurrentPage, renderHeader?.desc, renderHeader?.head]);

	const showClose = useMemo(() => {
		if (
			currentPageMonthly === MonthlyBillingSteps.CHECKOUT ||
			currentPagePayNow === PayNowSteps.SUCCESS ||
			(currentPagePayNow === PayNowSteps.PROCESSING_PAYMENT &&
				!showProcessingClose)
		)
			return false;
		return true;
	}, [currentPageMonthly, currentPagePayNow, showProcessingClose]);

	return (
		<>
		<Modal
			isOpen={isModal}
			modalName="Onboarding invite"
			closeModal={handleClose}
			className={'OnboardingInviteModal-modal ' + navigate}
			showCloseBtn={showClose}
			isStopOutsideClick={false}
			title={renderModalHeader}
		>
			{renderPage}
			{isFooterVisible && (
				<div className="OnboardingInviteModal-btn">
					<Button
						handleClick={handleBackToInvestor}
						label={handleBackLabel as string}
						type="button__filled button__filled--secondary button__large"
					/>
					<Button
						handleClick={handleNextToInvestor}
						label={handleNextLabel ?? ''}
						type="button__filled button__filled--primary button__large"
						disabled={isSubmitDisabled as boolean}
					/>
				</div>
			)}
		</Modal>
		{isMultiSignModalOpen && !isComplex && <MultiSignAgreement />}
		</>
	);
};
