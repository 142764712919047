import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AsYouType, CountryCode as CountryType } from 'libphonenumber-js';
import { fetchCountryCodeFromPhone } from '@storybook/country-code';
import { Input } from './input';
import countries from 'json/country-codes.json';


/**
 * Interface for the props of the CountryMobileNumber component.
 * @param {function} Props.handleChange - Callback function invoked when the phone number changes, receiving an object with `countryCode` and `phone`.
 * @param {function} Props.handleBlur - Callback function invoked when the phone number blur, receiving an object with `countryCode` and `phone`.
 * @param {string} [Props.defaultNumber] - The default phone number to be displayed in the input field.
 * @param {string | number} [Props.defaultCountryCode] - The default country code to be used in the input field.
 * @param {boolean} [Props.isCountryCodeVisible] - Flag indicating whether the country code selector is visible.
 * @param {function} Props.handleChangeCountry - Callback function invoked when the country code changes, receiving the new country code.
 * @param {string} [Props.errorMessage] - The error message to be displayed when there's an error.
 * @param {boolean} [Props.isError] - Flag indicating whether there's an error in the input.
 * @param {function} [Props.handleClearField] - Callback function invoked when the input field is cleared.
 * @param {boolean} [Props.disabled] - Flag indicating whether the input field is disabled.
 * @param {boolean} [Props.isRequired] - Flag indicating whether the input field is required.
 * @param {function} [Props.onKeyDown] - Callback function invoked on key down events in the input field.
 * @param {function} [Props.onPaste] - Callback function invoked on paste events in the input field.
 * @param {string} [Props.className] - Additional CSS classes for styling the component.
 */

export interface IPhoneNumber {
	phone: string;
	countryCode: string | number;
}
interface IInput {
	label?: string;
	handleChange: (e: IPhoneNumber) => void;
	handleBlur?: () => void;
	defaultNumber?: string;
	defaultCountryCode?: string | number;
	isCountryCodeVisible?: boolean;
	handleChangeCountry: (e: string | number) => void;
	errorMessage?: string;
	isError?: boolean;
	handleClearField?: () => void;
	disabled?: boolean;
	isRequired?: boolean;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
	className?: string;
}
const Phone_Max_Number = 12;

export const CountryMobileNumber: FC<IInput> = ({
	defaultNumber = '',
	defaultCountryCode = '+1',
	...props
}) => {
	const { handleChange, handleChangeCountry } = props;
	const [inputCountryCode, setInputCountryCode] = useState<string | number>(
		defaultCountryCode
	);

	const [temporaryPhoneNumber, setTemporaryPhoneNumber] =
		useState<string>(defaultNumber);

	useEffect(() => {
		setTemporaryPhoneNumber(defaultNumber);
		setInputCountryCode(defaultCountryCode);
	}, [defaultCountryCode, defaultNumber]);

	const onHandleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			const phone = value.replace(/\D/g, '');

			if (value?.startsWith('+')) {
				const fetchedCountryCode = fetchCountryCodeFromPhone(value);
				if (fetchedCountryCode) {
					const newPhone = value
						?.replace(fetchedCountryCode, '')
						?.replace(/\D/g, '');
					setInputCountryCode(fetchedCountryCode);
					handleChange({
						countryCode: fetchedCountryCode,
						phone: newPhone.replace(/\D/g, ''),
					});
					setTemporaryPhoneNumber(newPhone);
				}
			} else if (phone.length < Phone_Max_Number) {
				handleChange({
					countryCode: inputCountryCode,
					phone: value.replace(/\D/g, ''),
				});
				if (value.length < 6) {
					setTemporaryPhoneNumber(value.replace(/\D/g, ''));
				} else {
					setTemporaryPhoneNumber(value);
				}
			}
		},
		[inputCountryCode, handleChange]
	);

	const getPhoneNumber = useMemo(() => {
		let countryLabel: CountryType = 'US';

		const countryObj = countries.find(item => item.label === inputCountryCode);

		if (countryObj) countryLabel = countryObj.code as CountryType;

		let formattedNumber = '';

		if (temporaryPhoneNumber.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(temporaryPhoneNumber);
		} else {
			formattedNumber = temporaryPhoneNumber;
		}
		return formattedNumber;
	}, [inputCountryCode, temporaryPhoneNumber]);

	const onHandleChangeCountry = useCallback(
		(e: { label: string | number }) => {
			setInputCountryCode(e.label);
			handleChangeCountry(e.label);
		},
		[handleChangeCountry]
	);

	return (
		<Input
			label="Mobile Number"
			{...props}
			handleChange={onHandleChange}
			value={getPhoneNumber}
			inputType="text" //keep type text as number is not giving suggestions for auto fill will use regex to accept number
			placeholder={``}
			isCountryCodeVisible={true}
			autoComplete="tel"
			inputName="phone"
			countryCode={inputCountryCode as string}
			handleChangeCountry={onHandleChangeCountry}
		/>
	);
};
