import {
	Calendar,
	DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { useOutsideClick } from 'hooks';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import './react-date-picker.scss';

const currentDate = new Date();
const currentDay = currentDate.getDate();
const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so January is 0
const currentYear = currentDate.getFullYear();
interface IDateCalender {
	optionalClassName?: string;
	value?: DayValue | any;
	onChange?: any;
	errorMessage?: string;
	isError?: boolean;
	label: string;
	isRequired?: boolean;
	id?: string;
	formate?: string;
	isModal?: boolean;
}

export const DateCalender: React.FC<IDateCalender> = ({
	value,
	onChange,
	isError = false,
	errorMessage,
	label,
	isRequired = false,
	id,
	formate = 'MM/DD/YYYY',
	isModal = false,
}) => {
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const wrapperRef = useRef<HTMLDivElement>(null);

	const formatDate = ({
		year,
		month,
		day,
	}: {
		day: number;
		month: number;
		year: number;
	}) => {
		return `${month.toString().padStart(2, '0')}/${day
			.toString()
			.padStart(2, '0')}/${year}`;
	};

	const onCalenderChange = (day: DayValue) => {
		if (day) {
			setInputValue(formatDate(day));
			const formattedDate = {
				target: {
					value: day,
				},
			};
			onChange(formattedDate);
		}
		setShowCalendar(false);
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		// Remove any non-numeric characters
		// Update the input value
		const [month, day, year] = value.split('/');
		setInputValue(value);
		let newDate;
		if (day && month && year) {
			newDate = {
				day: +day,
				month: +month,
				year: +year,
			};
		}
		const formattedDate = {
			target: {
				value: newDate,
			},
		};
		onChange(formattedDate);
	};

	useOutsideClick(wrapperRef, () => {
		setShowCalendar(false);
	});

	useEffect(() => {
		if (value) setInputValue(formatDate(value));
		else setInputValue('');
	}, [value]);

	return (
		<div ref={wrapperRef}>
			{label && (
				<label htmlFor={id} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<InputMask
				onClick={() => setShowCalendar(!showCalendar)}
				mask="99/99/9999"
				placeholder={formate}
				value={inputValue}
				onChange={handleInputChange}
				className={`react-date-picker ${isError ? 'active-error-message' : ''}`}
			/>

			{showCalendar && (
				<div
					className={isModal ? 'CalendarDropdownModal--container nowheel' : ''}
				>
					<Calendar
						value={value}
						onChange={onCalenderChange}
						shouldHighlightWeekends
						maximumDate={{
							year: currentYear,
							month: currentMonth,
							day: currentDay,
						}}
					/>
				</div>
			)}
			{isError && <span className="error-message">{errorMessage}</span>}
		</div>
	);
};
