import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ChangeEvent, DragEvent, FC, useCallback, useMemo, useState } from 'react';
import { REACT_APP_API_HOST } from 'envs';
import { Loader } from '@storybook';

import { MESSAGE } from 'constant';
import { useNotification } from 'hooks';
import { UploadedDocsState, loginState } from 'global-stores';
import { IDocProvider } from '../store';
import { documentTypeRegex } from 'utils';
import { IInputChange } from "types";

interface IProvider {
	provider: IDocProvider;
	handleChange?: any;
}

export const BrowseFile: FC<IProvider> = ({ provider, handleChange }) => {
	const setAddedFiles = useSetRecoilState(UploadedDocsState);
	const [uploadStatus, setUploadStatus] = useState({
		loading: false,
		loaded: false,
	});

	const { errorNotification, successNotification } = useNotification();

	const { accessToken, sandboxStatus } = useRecoilValue(loginState);

	const handleImportImage = useCallback(
		async (e: ChangeEvent<HTMLInputElement>, type?: 'drag' | 'browse') => {
			const file: File =
				(type === 'drag'
					? (e as any)
					: (e as IInputChange).target.files?.[0]) ?? ({} as File);
			if (file.size > 10000000 && e?.target?.value) {
				e.target.value = '';
				return errorNotification('File size exceeds the maximum limit.');
			}
			if (!documentTypeRegex.test(file.type) && e?.target?.value) {
				e.target.value = '';
				return errorNotification('Supported file format: Pdf, Doc, Docx.');
			}
			const payload = new FormData();
			payload.append('doc', file);
			if (provider === 'esign') {
				payload.append('type', provider);
				payload.append('extension', file.type);
			}
			if (file) {
				setUploadStatus(prev => ({ ...prev, loading: true }));
				try {
					const response = await fetch(`${REACT_APP_API_HOST}/url/upload`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accountMode: sandboxStatus ? 'sandbox' : 'live',
						},
						body: payload,
					});
					const apiData = await response.json();
					if (apiData.data) {
						const { name, size, _id, createdAt } = apiData.data ?? {};
						if (name) {
							setTimeout(() => {
								setUploadStatus({ loaded: true, loading: false });

								const payload = {
									_id,
									name,
									size,
									createdAt,
									prepareUrl: '',
									configured: false,
									isChecked: true,
									templateId: '',
									documentId: '',
									type: 'newUpload',
									templateName: "",
									provider
								};
	
								if (handleChange) {
									handleChange(payload);
								} else setAddedFiles(prev => [payload, ...prev]);
								successNotification('File Uploaded.');
								setUploadStatus({ loaded: false, loading: false });
							}, 1000);
						}
					} else {
						errorNotification(apiData.message === "Encrypted Document" ? apiData.message : MESSAGE.ERROR);
						setUploadStatus({ loaded: false, loading: false });
					}
				} catch (error) {
					setUploadStatus({ loaded: false, loading: false });
				}
				e.target.value = '';
			}
			return null;
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accessToken, provider]
	);

	/**
	 * @Manish here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Manish here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleImportImage(files?.[0] as any, 'drag');
		},
		[handleImportImage]
	);



	const isDisabled = useMemo(
		() => !uploadStatus.loaded && uploadStatus.loading,
		[uploadStatus.loaded, uploadStatus.loading]
	);

	return (
		<div className="browse-file" onDragOver={handleDragOver}
			onDrop={handleDrop}>
			<label
				htmlFor="browse-file"
				className="browse-file__label-box browse-file__label-box-height"
			>
				<>
					{isDisabled ? (
						<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
							<Loader className="loader-blue" dimension={60} />
							<div style={{ fontSize: '16px' }}>Uploading file...</div>
						</div>
					) : (
						<>
							<i className="ri-file-upload-line browse-file__logo" />
							<div className="browse-file__label-text">
								<span className="browse-file__light-text">
									Drag and drop files or{' '}
									<span style={{ color: 'var(--color-primary-light)' }}>
										Browse file
									</span>
								</span>
							</div>
							<div className="browse-file__description">
								Supported file format: Pdf, Doc, Docx
							</div>
							<div className="browse-file__description">
								Maximum upload file size: 10 MB.
							</div>
						</>
					)}
				</>

				<input
					multiple={false}
					accept=".pdf, .doc, .docx"
					id="browse-file"
					type="file"
					onChange={handleImportImage}
					className={`browse-file__input ${
						isDisabled && 'browse-file__input-loading'
					}`}
					disabled={isDisabled}
				/>
			</label>
		</div>
	);
};
