import { FC } from 'react';
import { Image } from '@storybook';

import { ComprehensiveComponentTypes } from './components/components-type';
import { ComprehensiveHeader } from './components/comprehensive-header';
import { ArraySection } from './components/array-section';

export type ComprehensiveCardType =
	| 'card'
	| 'info'
	| 'basic'
	| 'header'
	| 'array'
	| 'tab';

interface IProps {
	label: string;
	type?: ComprehensiveCardType;
	value: any;
}

export const KYBComprehensive: FC<IProps> = ({ label, value }) => {
	return (
		<div className="kyb-information">
			<ComprehensiveHeader label={label} />
			{Array.isArray(value?.value) && (
				<ArraySection label={label ?? '--'} data={value?.value} />
			)}
			{Object.keys(value).length === 0 ? (
				<>
					<div className="kyb-empty_data">
						<div className="kyb-empty_data__inner">
							<Image
								fileName="No_record_found.svg"
								className="empty_data_img"
								height="100"
							/>
							<div className="kyb-empty-data-txt">No records found</div>
						</div>
					</div>
				</>
			) : (
				Object.keys(value).map(key => {
					const {
						label,
						type,
						value: data = 'N/A',
					} = value[key as keyof typeof value] ?? {};
					const componentData: any = {
						label: label,
						value: data ?? value[key],
					};
					return (
						<ComprehensiveComponentTypes
							key={key}
							type={type as any}
							data={componentData}
						/>
					);
				})
			)}
		</div>
	);
};
