export const SUPPORTED_CLIENT = [
	{
		key: 'smtp',
		label: 'SMTP',
		icon: 'SMTP.svg',
		enable: true,
		methods: [
			{
				key: 'SMTP',
				label: 'SMTP',
				credentials: [
					{
						inputKey: 'userName',
						label: 'Username',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'email',
						label: 'Email',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'host',
						label: 'Host',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'port',
						label: 'Port',
						isRequired: true,
						type: 'number',
					},
					{
						inputKey: 'authUser',
						label: 'Auth User',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'authPassword',
						label: 'Auth Password',
						isRequired: true,
						type: 'password',
						preSuffixIcon: 'ri-eye-line',
						postSuffixIcon: 'ri-eye-off-line',
					},
				],
			},
			// {
			// 	key: 'SMTPMAIL',
			// 	label: 'SMTP Token',
			// 	credentials: [
			// 		{
			// 			inputKey: 'userName',
			// 			label: 'Username',
			// 			isRequired: true,
			// 			type: 'text',
			// 		},
			// 		{
			// 			inputKey: 'email',
			// 			label: 'Email',
			// 			isRequired: true,
			// 			type: 'text',
			// 		},
			// 	],
			// },
		],
	},
	{
		key: 'amazon_SES',
		label: 'Amazon SES',
		icon: 'AWS-SES.svg',
		enable: true,
		methods: [
			{
				key: 'AWS',
				label: 'Amazon SES',
				credentials: [
					{
						inputKey: 'userName',
						label: 'Username',
						isRequired: false,
						type: 'text',
					},
					{
						inputKey: 'email',
						label: 'Email',
						isRequired: false,
						type: 'text',
					},
					{
						inputKey: 'accessKey',
						label: 'Access key',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'secretKey',
						label: 'Secret key',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'region',
						label: 'Region',
						isRequired: true,
						type: 'text',
					},
				],
			},
		],
	},
	{
		key: 'send_grid',
		label: 'Sendgrid',
		icon: 'Sendgrid.svg',
		enable: true,
		methods: [
			{
				key: 'SG',
				label: 'Sendgrid',
				credentials: [
					{
						inputKey: 'userName',
						label: 'Username',
						isRequired: false,
						type: 'text',
					},
					{
						inputKey: 'email',
						label: 'Email',
						isRequired: true,
						type: 'text',
					},
					{
						inputKey: 'accessKey',
						label: 'Access key',
						isRequired: true,
						type: 'text',
					},
				],
			},
		],
	},
	// {
	// 	key: 'postmark_app',
	// 	label: 'Postmarkapp',
	// 	icon: 'Postmark.svg',
	// 	enable: false,
	// 	methods: [],
	// },
	// {
	// 	key: 'mailgun',
	// 	label: 'Mailgun',
	// 	icon: 'Mailgun.svg',
	// 	enable: false,
	// 	methods: [],
	// },
	// {
	// 	key: 'message_bird',
	// 	label: 'MessageBird',
	// 	icon: 'MessageBird.svg',
	// 	enable: false,
	// 	methods: [],
	// },
];

export const EmailClientModalTitle = {
	'select-client': 'Select email client',
	'enter-client-details': 'Enter credentials',
};

export const EmailClientMethod = {
	SMTP: 'SMTP',
	SMTPMAIL: 'SMTP Token',
	AWS: 'Amazon SES',
	SG: 'Sendgrid',
};

export const EmailClientMethodIcon = {
	SMTPMAIL: 'SMTP.svg',
	SMTP: 'SMTP.svg',
	AWS: 'AWS-SES.svg',
	SG: 'Sendgrid.svg',
};

export const avoidTrimKeys = {
	authPassword: true,
	userName: true,
};
