export const ElementsToRemove = [
	'kybMatchThreshold',
	'kybNonMatches',
	'payInPayOut',
	'accreditationType',
	'signAgreementProvider',
	'idv',
	'ssn',
	'addressVerification',
	'AML',
	'chequeFraud',
	'faceEnrollment',
	'palmEnrollment',
	'creditReport',
	'creditRange',
	'kyb_comprehensive',
	'representative_kyc_aml_verification',
	'dun-brad-street-section',
	'kycComprehensive'
];

export const SuccessKybKeyRemoved =[
	'isPlayStoreUrl',
	'Identity',
	'Explore',
	'isCustomBtnUrl',
	'isAppStoreUrl',
	'appStoreUrl',
	'playStoreUrl',
	'customRedirectUrl',
	'customRedirectBtnText'
]

export const ConfigActionKeys = {
	FormAction: 'formAction',
	AccreditationType: 'accreditationType',
	Form: 'form',
	FundInvestmentVerification: 'fundInvestmentVerification',
	SignAgreementVerification: 'signAgreementVerification',
	KybVerification: 'kybVerification',
	AmlVerification: 'amlVerification',
	AccreditationVerify: 'accreditationVerify',
	AllId: 'allId',
};

export const CHEQUE_FRAUD_CONFIRMATION_MODAL = {
	TITLE: 'Are you sure ?',
	DESCRIPTION:
		'By enabling Check Defense in KYC, all other actions will be automatically removed.',
	LABEL: 'Yes',
	SECONDRYL_LABEL: 'Cancel',
};
