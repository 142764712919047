import { Button, Loader } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLogin } from '../../store';
import {
	CountryCode,
	LoginCredsState,
	LoginMaskPhoneCredsState,
	LoginStep,
	MobileLoginLinkResponseState,
} from '../../store/states';
import { emailRegex } from 'utils';
import { useNotification, useTimer } from 'hooks';

interface IProps {
	props: any;
}

export const MobileLinkSent: FC<IProps> = ({ props }) => {
	const country = useRecoilValue(CountryCode);
	const { verificationId } = useRecoilValue(MobileLoginLinkResponseState);
	const setStep = useSetRecoilState(LoginStep);
	const maskPhoneCreds = useRecoilValue(LoginMaskPhoneCredsState);
	const phone = useRecoilValue(LoginCredsState);
	const [checkStatus, setCheckStatus] = useState(false);
	const [count, setCount] = useState(1);

	const { loginWithEmail: loginStatus } = useLogin();
	const { successNotification, errorNotification } = useNotification();
	const { onSuccess, onFailed } = props;
	const { seconds, setSeconds } = useTimer();

	const handleClickBtn = useCallback(() => {
		setStep('VERIFY');
	}, [setStep]);

	const getStatus = useCallback(async () => {
		const payload = {
			type: 'getTokenWithCode',
			verificationId,
			// securityCode,
			// code,
		};
		const response = await loginStatus(payload);
		if (response) {
			const { status, token, mfaEnabled } = response ?? {};
			if (status === 'approved') {
				onSuccess({
					token,
					status,
					message: 'Logged in successfully',
					type: 'mobileCode',
					mfaEnabled: mfaEnabled ?? false
				});
				return;
			}
			if (status === 'rejected') {
				onFailed({
					status: false,
					message: 'Verification failed due to invalid selection',
					type: 'mobileCode',
				});
				setStep('VERIFY');
				return;
			}
		}
	}, [loginStatus, onFailed, onSuccess, setStep, verificationId]);

	const isResendAllowed = useMemo(() => !seconds, [seconds]);

	useEffect(() => {
		if (verificationId !== '') {
			getStatus();
		}
		setTimeout(() => {
			setCheckStatus(prev => !prev);
		}, 5000);
		// eslint-disable-next-line
	}, [checkStatus, verificationId]);

	const formattedSeconds = useMemo(
		() => (seconds < 10 ? `0${seconds}` : seconds),
		[seconds]
	);

	const handleResendOTP = useCallback(async () => {
		if (!isResendAllowed || count > 3) return;

		setCount(prev => prev + 1);
		if (count === 1) {
			setSeconds(40);
		} else if (count === 2) {
			setSeconds(60);
		}
		let payload = {};
		if (emailRegex.test(phone)) {
			payload = {
				type: 'maskPhone',
				email: phone,
			};
		} else {
			payload = {
				type: 'phone',
				countryCode: country,
				phone,
			};
		}
		const response = await loginStatus(payload);
		if (response) {
			const { success, verificationId, message, errorData } = response;
			if (success && verificationId) {
				successNotification(message);
			} else {
				errorNotification(message ?? errorData.message);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phone, count, count, loginStatus, isResendAllowed, country, setStep]);

	const onClickIntercom = () => {
		(window as any)?.Intercom?.(
			'showNewMessage',
			'Unable to Login, Not receiving message'
		);
	};

	// FYI please do not remove the commented code they are super important
	return (
		<div className="mobile-link">
			<div className="mobile-link__wrapper">
				<div className="mobile-link__row">
					<div className="mobile-link__title">Waiting for approval</div>
					<div className="mobile-link__description">
						{/* {`We have sent you a text message to ${country} ${phone} . Open the link
                provided in the text message`} */}
						We have sent you a text message to{' '}
						<strong className="mobile-link__mobile-number">
							{' '}
							{` ${country} ${maskPhoneCreds}`}
						</strong>{' '}
						and an email with an approval link. Kindly open either the link
						provided in the text message or the one in the email to approve from
						there.
					</div>
				</div>
				<div className="mobile-link__row mobile-link__row-1 mobile-link__vertical-space">
					<Loader className="loader-blue" dimension={60} />
					{/* <div className="mobile-link__number">{securityCode}</div>
          <div className="mobile-link__specification">
            Tap <strong>{securityCode}</strong> on your phone to verify your
            phone number{" "}
          </div> */}
				</div>
				<div className="mobile-link__row">
					<Button
						label="Back"
						type="button__filled--secondary button__large button__block"
						handleClick={handleClickBtn}
					/>
				</div>
				{count !== 4 ? (
					<>
						{isResendAllowed ? (
							<div className="verify-modal__footer--resend">
								<span className="footer-text">
									Didn&apos;t receive the text message?
								</span>
								<span
									className="footer-resend-btn"
									onClick={() => handleResendOTP()}
								>
									Resend
								</span>
							</div>
						) : (
							<div className="verify-modal__footer">
								<span className="footer-text">
									Time remaining for next OTP 00:{formattedSeconds}
								</span>
							</div>
						)}
					</>
				) : (
					<div
						className="verify-modal__footer verify-modal--connect"
						onClick={onClickIntercom}
					>
						<div className="verify-modal--icon">
							<i className="ri-user-voice-line"></i>
						</div>
						<span className="footer-resend-btn">
							Facing trouble? Click here for assistance.
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
