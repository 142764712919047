import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useCookie, useNetwork } from 'hooks';
import {
	ComplexSessionInfoState,
	ComplexSessionNodesState,
	ComplexSessionsNodesDetailsState,
	ComplexSessionsNodesKybOfacDetailsState,
	ComplexSessionsNodesKycComperhensiveDetailsState,
	ComplexSessionsNodesLoadingState,
	loginState,
} from 'global-stores';
import { REACT_APP_API_HOST } from 'envs';
import { WebWorker } from 'hooks/worker';
import { KybOfacSelectedTab } from '../components';

export const useComplexSessionInfo = () => {
	const { get, loading } = useNetwork();

	const setComplexSessionInfo = useSetRecoilState(ComplexSessionInfoState);
	//Rahul Singh:to show activity log in case of complex
	const complexSessionInfo = async (sessionId: string) => {
		const resp = await get(`${API_URL.COMPLEX_SESSION_DETAILS}/${sessionId}`);
		const { data } = resp ?? {};
		if (data) {
			setComplexSessionInfo(data);
		}
	};
	return {
		complexSessionInfo,
		complexSessionDetailsLoading: loading,
	};
};

export const useGetComplexNodeInfo = () => {
	const { accessToken } = useNetwork();
	const { get: getCookie } = useCookie();
	const localUserData = getCookie('user');
	const { sandboxStatus } = useRecoilValue(loginState);
	const sandbox = sandboxStatus ?? localUserData?.sandboxStatus;

	const nodes = useRecoilValue(ComplexSessionNodesState);
	const setSessionDetails = useSetRecoilState(ComplexSessionsNodesDetailsState);
	const setKybOfacDetails = useSetRecoilState(
		ComplexSessionsNodesKybOfacDetailsState
	);
	const setKybOfacbyDefaultTab = useSetRecoilState(KybOfacSelectedTab)
	const setComplexSessionLoading = useSetRecoilState(
		ComplexSessionsNodesLoadingState
	);
	const setKycComperhensiveDetails = useSetRecoilState(
        ComplexSessionsNodesKycComperhensiveDetailsState
    );

	const getComplexNodeInfo = useCallback((kycAmlNodes?:any) => {
		const getNodes = kycAmlNodes || nodes
		if (getNodes) {
			getNodes.forEach((node: any) => {
				const { _id: nodeId = '', session: sessionId = '' } = node ?? {};
				const url = `${REACT_APP_API_HOST}${API_URL.Session_Nodes}/${sessionId}?nodeId=${nodeId}`;
				if (node?.stepId !== 'authentication' && node?.visited === true) {
					setComplexSessionLoading(prev => ({ ...prev, [nodeId]: true }));
					WebWorker.worker.postMessage({
						endpoint: url,
						config: {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${accessToken}`,
								accountMode: sandbox ? 'sandbox' : 'live',
							},
						},
						nodeId,
					});
				}
			});
		}
		WebWorker.worker.onmessage = resp => {
			const { status, nodeId, data } = resp.data;
			if (status === 200) {
				if (data?.stepId === 'kyb') {
					setKybOfacDetails(
						data.data[0].data?.companyDetails?.businessSanction?.report
					);
					setKybOfacbyDefaultTab(data.data[0].data?.companyDetails?.businessSanction?.report.subcases.value[0])
				}
				if (data?.stepId === 'kyc') {
					setKycComperhensiveDetails(data?.data?.kycComprehensive ?? {});
				}
				setSessionDetails((prev: any) => ({
					...prev,
					[nodeId]: data,
				}));
			}
			setComplexSessionLoading(prev => ({ ...prev, [nodeId]: false }));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, nodes]);

	return { getComplexNodeInfo };
};
