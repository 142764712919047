export const fundReportDetails = [
	{
		userType: 'sender',
		userTitle: 'Sender',
		details: [
			{
				key: 'bankName',
				name: 'Bank Name',
			},
			{
				key: 'accountHolderName',
				name: 'Account Holder Name',
			},
			{
				key: 'subtype',
				name: 'Type',
			},
			{
				key: 'mask',
				name: 'Account no',
			},
			{
				key: 'routingNumber',
				name: 'Routing Number',
			},
			{
				key: 'balance',
				subKey: 'available',
				name: 'Available Balance',
			},
			{
				key: 'balance',
				subKey: 'current',
				name: 'Current Balance',
			},
			{
				key: 'totalInvestmentAmount',
				name: 'Fund Amount Paid',
			},
		],
	},
	{
		userType: 'recipient',
		userTitle: 'Recipient',
		details: [
			{
				key: 'bankName',
				name: 'Bank Name',
			},
			{
				key: 'accountHolderName',
				name: 'Account Holder Name',
			},
			{
				key: 'subtype',
				name: 'Type',
			},
			{
				key: 'mask',
				name: 'Account no',
			},
			{
				key: 'routingNumber',
				name: 'Routing Number',
			},
		],
	},
];

export const otherInfoDetails = [
	{
		key: 'date',
		label: 'Date & time',
	},
	{
		key: 'transactionId',
		label: 'Transaction Id',
	},
];

export const unitPricingLabels: { [key: string]: string } = {
	pricePerUnit: 'Unit Price (USD)',
	unit: 'Number of Units',
	totalInvAmn: 'Total Investment Amount',
	totalInvestmentAmount: 'Total Investment Amount',
};

export const formatStatusKey = (status: string) => {
	switch (status?.toLocaleLowerCase()) {
		case 'paid':
			return 'completed';
		case 'in_process':
			return 'processing';
		case 'expired':
		case 'failed':
			return 'failed';
		default:
			return 'pending';
	}
};

export enum TransactionUserType {
	Sender = 'sender',
	Recipient = 'recipient',
}
export enum BalanceType {
	Available = 'available',
	Current = 'current',
}

export const sortingOrder = ['pricePerUnit', 'unit', 'total']; // Desired order