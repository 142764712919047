import type { IComplexFlowUnconfiged } from 'views/complex-onboarding-flow/stores/states';

import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SurveyCreator } from 'survey-creator-react';

import { survayFormOptions } from 'components';
import { API_URL } from 'constant';
import {
	ActiveActionKeyNameState,
	IAction,
	PipelineMasterSteps,
	PipelineTemplateState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import {
	ComplexConfigFromState,
	ComplexConfigModalState,
	ComplexDynamicFormPathsState,
	ComplexDynamicFormStatusState,
	ComplexFlowUnconfigedState,
	ConfigFormModalState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import {
	CurrentStepState,
	ErrorMsg,
	UnConfiguredStep,
} from 'views/onboarding-flow';
import { ComplexSettingFormState } from 'views/pipeline';
import { AllowedQuestionnaire } from '../../form-action';

interface IUseComplxSurvery {
	setIsFormOpen: (is: boolean) => void;
	addNewValue: () => void;
	setNodeId?: (el: string) => void;
}

export const useComplxSurvery = ({
	addNewValue,
	setIsFormOpen,
	setNodeId,
}: IUseComplxSurvery) => {
	// Local
	const [isTemplateModal, setIsTemplateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [templateName, setTemplateName] = useState('');

	// globle states
	const setUnOpennedConfig = useSetRecoilState(UnConfiguredStep);
	const setComplexSettingForm = useSetRecoilState(ComplexSettingFormState);
	const { errorNotification, successNotification } = useNotification();
	const setNodes = useSetRecoilState(NodesState);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const complexConfigFromState = useRecoilValue(ComplexConfigFromState);
	const { id: nodeId } = useRecoilValue<IAction>(CurrentStepState);
	const [templateInit, setTemplateInit] = useRecoilState(PipelineTemplateState);
	const setEdges = useSetRecoilState(EdgesState);
	const setPaths = useSetRecoilState(ComplexDynamicFormPathsState);
	const setStatus = useSetRecoilState(ComplexDynamicFormStatusState);
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	// globle states for setNextStep
	const steps = useRecoilValue(PipelineMasterSteps);
	const setActiveAction = useSetRecoilState(ActiveActionKeyNameState);
	const setCurrentStep = useSetRecoilState(CurrentStepState);
	const setIsConfigModal = useSetRecoilState(ComplexConfigModalState);
	const complexFlowConfiged = useRecoilValue(ComplexFlowUnconfigedState);
	const setIsConfigFormModal = useSetRecoilState(ConfigFormModalState);

	// Shahbaaz: survayFormOptions not changing if we put out side the component
	const creator = useMemo(() => new SurveyCreator(survayFormOptions), []);

	const setNextStep = useCallback(
		(pendingFlows?: IComplexFlowUnconfiged[]) => {
			const pending = pendingFlows ?? complexFlowConfiged ?? [];
			const pendingFlow = pending.find(flow => !flow.configed);
			if (!pendingFlow) {
				setIsConfigModal(false);
				return;
			}
			const { id, stepId } = pendingFlow;
			if (stepId === 'dynamicForm') {
				setIsConfigModal(false);
				setIsConfigFormModal(true);
			} else {
				setIsConfigFormModal(false);
				setIsConfigModal(true);
			}
			const result = steps.find(el => el.key === stepId);
			const renderObj: any = result?.actions && result.actions[0];
			setCurrentStep({ ...renderObj, id } || {});
			setActiveAction(stepId);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[complexFlowConfiged, steps]
	);

	const { post } = useNetwork({
		returnResponse: true,
		updateState: false,
	});

	// Arun Kumar : Add function for close modal
	const handleCloseModal = () => {
		setIsFormOpen(false);
	};

	const extractChoices = (arr: any[]) => {
		const choices: any = [];
		(arr ?? []).forEach(page => {
			page.elements?.forEach((element: any) => {
				const booleanChoices = [
					{
						page: page.name ?? '',
						question: element.name ?? '',
						choice: true,
					},
					{
						page: page.name ?? '',
						question: element.name ?? '',
						choice: false,
					},
				];
				if (element?.type === 'boolean') choices.push(...booleanChoices);
				else if (AllowedQuestionnaire[element?.type]) {
					element.choices?.forEach((choice: any) => {
						if (choice) {
							choices.push({
								page: page.name ?? '',
								question: element.name ?? '',
								choice: choice?.value ?? choice ?? '',
							});
						}
					});
				}
			});
		});
		return choices;
	};

	// const isDuplicateQuestions = (elements: any) => {
	// 	const questionTitles: string[] = [];

	// 	elements.filter(
	// 		(element: { type: string; name: string; title?: string }) => {
	// 			const questionTitle = element?.name;
	// 			questionTitles.push(questionTitle.trim().toLowerCase());
	// 		}
	// 	);
	// 	const questionsSet = new Set(questionTitles);
	// 	return questionsSet.size !== questionTitles.length;
	// };

	const close = () => {
		const { pages } = creator.JSON ?? [];
		if (!pages || !pages.some((item: { elements: any }) => item.elements)) {
			setUnOpennedConfig(prev => [...prev, 'formAction']);
			errorNotification(ErrorMsg.FormError);
			return;
		}
		if (pages) {
			const elements = pages.reduce((acc: any, curr: any) => {
				return [...acc, ...curr.elements];
			}, []);

			// No need to check duplicate
			// if (isDuplicateQuestions(elements)) {
			// 	errorNotification(ErrorMsg.DuplicateQuestions);
			// 	return;
			// }
			const investorDetailsQuestions = elements.filter(
				(element: { type: string; name: string }) =>
					element.type === 'investors-detail'
			);
			if (investorDetailsQuestions.length > 1) {
				errorNotification(ErrorMsg.FormInvestorDetailError);
				return;
			}
		}

		// Ptadeep : it will reset the path , status , and edges when json will be changed
		const { pages: formPages } = complexSettingForm?.[nodeId as string] ?? {};
		const isNewJson = JSON.stringify(pages) !== JSON.stringify(formPages);
		if (isNewJson) {
			const elementDef = extractChoices(formPages).filter(
				(item: any) =>
					!extractChoices(pages).find(
						(item1: any) => JSON.stringify(item1) === JSON.stringify(item)
					)
			);
			if (elementDef?.length) {
				elementDef.forEach((element: any) => {
					const key = `${nodeId}_${element.page}_${element.question}_${
						element.choice?.value ?? element.choice
					}`;
					setEdges((prev: any) => {
						const prevObj = JSON.parse(JSON.stringify(prev));
						const data = prevObj.filter((el: any) => key !== el.sourceHandle);
						return data;
					});
					setPaths(pre => {
						const prevObj = JSON.parse(JSON.stringify(pre));
						if (prevObj[key]) {
							delete prevObj[key];
						}
						return prevObj;
					});
					setStatus(pre => {
						const prevObj = JSON.parse(JSON.stringify(pre));
						if (prevObj[key]) {
							delete prevObj[key];
						}
						return prevObj;
					});
					setNodes((prev: any) => {
						const prevObj = JSON.parse(JSON.stringify(prev));
						const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
						if (prevObj[findIndex]) {
							const conditionalObj = (
								prevObj[findIndex]?.conditions ?? []
							).filter((el: any) => el.key !== key);
							prevObj[findIndex].conditions = conditionalObj ?? [];
						}
						return prevObj;
					});
				});
			}
		}

		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (prevObj[findIndex]) {
				prevObj[findIndex]['formMetaData'] = creator.JSON;
			}
			return prevObj;
		});
		setIsFormOpen(false);
		addNewValue();

		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);

			if (!newObj[nodeId as string]) {
				newObj[nodeId as string] = {};
			}
			Object.assign(newObj[nodeId as string], creator.JSON);
			//Shahbaaz Set key for form complex config setting form for showing price breakdown
			Object.assign(newObj[nodeId as string], {
				key: 'formAction',
			});

			return newObj;
		});
		const complexNodes: IComplexFlowUnconfiged[] = [];
		setComplexFlowConfiged((prev: any) => {
			const newArr = structuredClone(prev);
			return newArr.map((el: any) => {
				if (el.id === nodeId) {
					const value = {
						...el,
						configed: true,
					};
					complexNodes.push(value);
					return value;
				} else {
					complexNodes.push(el);
					return el;
				}
			});
		});
		if (complexConfigFromState === 'NEXT') {
			setNextStep(complexNodes);
		}
		if (setNodeId) setNodeId('');
	};

	const saveTemplate = useCallback(() => {
		const { pages } = creator.JSON ?? [];
		if (!pages || !pages.some((item: { elements: any }) => item.elements)) {
			errorNotification(ErrorMsg.FormError);
			return;
		}
		// No need to check
		// if (pages) {
		// 	const elements = pages.reduce((acc: any, curr: any) => {
		// 		return [...acc, ...curr.elements];
		// 	}, []);
		// if (isDuplicateQuestions(elements)) {
		// 	errorNotification(ErrorMsg.DuplicateQuestions);
		// 	return;
		// }
		// }
		setIsTemplateModal(prev => !prev);
	}, [creator.JSON, errorNotification]);

	const onCloseSaveTemplate = useCallback(() => {
		setIsTemplateModal(prev => !prev);
		setTemplateName('');
	}, []);

	const onSaveTemplate = useCallback(async () => {
		const alreadyExist = (templateInit ?? []).some(
			el =>
				el.name?.toLocaleLowerCase() ===
				templateName?.toLocaleLowerCase()?.trim()
		);
		if (!templateName?.trim()) {
			errorNotification(ErrorMsg.EmptyInput);
			return;
		}

		if (alreadyExist) {
			errorNotification(ErrorMsg.Duplicate);
			return;
		}
		setLoading(true);
		const payload = {
			name: templateName?.trim(),
			key: 'form',
			data: creator.JSON,
		};
		const resp = await post(API_URL.STEP_TEMPLATES, payload);
		if (resp?._id) {
			successNotification('Templates saved successfully');
			onCloseSaveTemplate();
			setTemplateInit(prev => {
				if (prev) {
					return [...prev, resp];
				} else return [];
			});
		} else {
			errorNotification(resp?.errorData?.message || 'Something went wrong');
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creator.JSON, templateName, post]);

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setTemplateName(value);
	}, []);
	return {
		handleChange,
		onSaveTemplate,
		saveTemplate,
		isTemplateModal,
		close,
		handleCloseModal,
		loading,
		nodeId,
		creator,
		onCloseSaveTemplate,
		templateName,
	};
};
