/**
 * Importing necessary modules from 'react' and custom hooks
 * */
import { useCallback, useEffect } from 'react';
import {useRecoilState} from "recoil";

/**
 * Importing constants and hooks from other files
 * */
import { useNetwork, useLogout } from 'hooks';
import {SessionOutTimer, ShowWarningPopup} from "./state";


/**
 * Declaring a variable to hold the interval ID
 * */
let intervalId: any;

/**
 * Custom hook to track user inactivity
 * */
export const useUserInactivity = (inactivityDuration: number): void => {
	/**
	 * State to keep track of time
	 * */
	const [timer, setTimer] = useRecoilState(SessionOutTimer);
	const [showPopup, setShowPopup] = useRecoilState(ShowWarningPopup);
	const { logout } = useLogout();

	/**
	 * Using the 'useNetwork' hook to handle network operations
	 * */
	const { accessToken } = useNetwork();

	/**
	 * Function to reset the timer
	 * */
	const resetTimer = useCallback(() => {
		if (timer !== 0) {
			setTimer(0);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timer]);

    /**
	 * Function to expire user session after inactivity
	 * */
	const expireSession = useCallback(async () => {
		logout();
        /**
        Resets the timer upon session expiry
        */
		clearInterval(intervalId);
		resetTimer(); 
	}, [logout, resetTimer]);


	/**
	 * Effect hook to check for inactivity duration and access token changes
	 * */
	useEffect(() => {
		/**
		 * Check if the inactivity duration has exceeded
		 * */

		if(timer > inactivityDuration* 0.8) {
			if(!showPopup) {
				setShowPopup(true)
			}
		}

		if (timer > inactivityDuration) {
            /**
            Expire user session if inactive
            */
            setShowPopup(false)
			expireSession(); 
		}

		/**
		 * Check if there's no access token available
		 * */
		if (!accessToken || /localhost/gi.test(window.location.origin)) {
            /**
            Clear the interval if no access token
            */
			clearInterval(intervalId); 
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inactivityDuration, timer]);


	/**
	 * Effect hook to start the timer interval
	 * */
	useEffect(() => {
		intervalId = setInterval(() => setTimer(prev => prev + 1000), 1000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Event listeners to detect user activity and reset the timer
	 * */
    /**
    Listen for mouse movement
    */
	document.addEventListener('mousemove', resetTimer); 
    /**
    Listen for key presses
    */
	document.addEventListener('keydown', resetTimer); 
};
