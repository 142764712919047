import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IKybDetails, ILinkRepresentative } from 'global-stores/sessions/type';
import { useRecoilState } from 'recoil';

import { Image, ReactDropdown } from '@storybook';
import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';
import { ActionButton } from '../action';
import { SessionDetailsHeading } from '../session-details-heading';
import { KYBDownloadOption, SessionStatus } from './constant';
import { KYB_INFO_HEADER_KEY, KYB_TYPE_TABS, KybInfoModalState } from './hooks';
import { KybLogo } from './components/kyb-logo';
import { KybInformationDetails } from './components/kyb-information-details';
import { KybMembers } from './components/kyb-members';
import { KybFiling } from './components/kyb-filing';
import {
	KYBInsightDetail,
	OFAC,
	SimilarCompanies,
	StatusUploadedDocuments,
} from './components';

import Modal from '@storybook/new-modal/modal';
import { KYBComprehensive } from './components/kyb-comprehensive/kyb-comprehensive';
import { ArraySection } from './components/kyb-comprehensive/components/array-section';
import {
	BankruptcyInformationSection,
	IRS500Information,
	KybAssetsInformation,
} from './components/kyb-comprehensive/components';
import { BankruptcyData } from './hooks/kyb-comperhensive-bankruptcy-types';
import { useSessionDetailsActions } from 'views/sessions/hooks/use-session-actions';
import './kyb-wrapper.scss';

import { SubsidiaryVerification } from './components/subsidiary-verification';
interface IKYB {
	kyb: any;
	isLoading: boolean;
	isLoaded: boolean;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
	role?: string;
	isAgentPermitted?: boolean | undefined;
}

const { Approved, Failed, Rejected } = SessionStatus;

export const KybWrapper: FC<IKYB> = ({
	kyb,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite = true,
	role,
	isAgentPermitted,
}) => {
	const {
		data,
		status = '',
		uploadedFile,
	} = useMemo(() => kyb?.[0] ?? {}, [kyb]);
	const { configurations } = data?.companyDetails ?? {};
	const [selectedKybType, setSeletedKybType] = useState(
		configurations?.[0] ?? ''
	);

	const KYBLabelDataKeys = useMemo(() => Object.keys(kyb?.[0] ?? {}), [kyb]);

	const isStatusApprovedOrRejected = useMemo(() => {
		return [Approved, Rejected, Failed].includes(status.toLowerCase());
	}, [status]);

	const { exportToJson } = useSessionDetailsActions();

	const handleChangeSelect = (event: any) => {
		const { value } = event;
		if (value === 'json') {
			exportToJson(kyb, 'KYb.json');
		}
	};
	// This is the Sub modal info Recoil for KYb comperhensive
	const [kybSubInfoModal, setKybSubInfoModal] =
		useRecoilState(KybInfoModalState);
	const [selectedTab, setSelectedTab] = useState('');

	useEffect(() => {
		const dataKeys = Object.keys(kybSubInfoModal?.data);
		if (dataKeys.length > 0) {
			setSelectedTab(dataKeys[0] ?? '');
		} else {
			// Handle the case when the object is empty
		}
	}, [kybSubInfoModal?.data]);

	//do not required useMemo as this is not going to rerender
	//@paramter will be optional
	const emptyPage = (message?: string) => (
		<div className="kyb-empty_data">
			<div className="kyb-empty_data__inner">
				<Image fileName="missing.svg" className="empty_data_img" height="100" />
				<div className="kyb-empty-data-txt">
					{message ?? 'No data is available'}
				</div>
			</div>
		</div>
	);

	const EnChangedkybCompanyDetail = useMemo(() => {
		return (
			<div className="kyb-wrapper__details kyb-wrapper__details-wrapper">
				{KYB_INFO_HEADER_KEY.map(key => {
					return (
						<KybInformationDetails
							linkRepresentatives={
								data?.linkRepresentatives as ILinkRepresentative[]
							}
							type={key}
							item={data?.companyDetails?.enhanced?.report as IKybDetails}
							key={key}
						/>
					);
				})}
				<KybMembers
					item={data?.companyDetails?.enhanced?.report as IKybDetails}
				/>
				<KybFiling
					item={data?.companyDetails?.enhanced?.report as IKybDetails}
				/>
				<KYBInsightDetail
					kyb={data?.companyDetails?.enhanced?.report as IKybDetails}
				/>
				<SubsidiaryVerification item={data?.companyDetails?.subsidiaries} />
				{data?.companyDetails?.enhanced?.report?.similarCompanies && (
					<SimilarCompanies
						item={data?.companyDetails?.enhanced?.report as IKybDetails}
					/>
				)}

				{/* Pradeep :  StatusUploadedDocuments should be always on bottom in enhanced*/}
				{Array.isArray(uploadedFile) && uploadedFile.length > 0 && (
					<StatusUploadedDocuments files={uploadedFile} />
				)}
			</div>
		);
	}, [
		data?.companyDetails?.enhanced?.report,
		data?.companyDetails?.subsidiaries,
		data?.linkRepresentatives,
		uploadedFile,
	]);

	const handleTabClick = (label: string) => {
		setSelectedTab(label);
	};

	const handleCloseModal = useCallback(() => {
		setKybSubInfoModal(prevState => ({
			...prevState,
			isOpen: false,
		}));
		// This Timeout is beacause to fix the Islutation cosmatic bug when we close the modal.
		setTimeout(() => {
			setKybSubInfoModal(prevState => ({
				...prevState,
				data: {},
			}));
		}, 1000);
	}, [setKybSubInfoModal]);

	// TODO :- Need to shift in new File @TODO
	const renderSubInfoModalBody = useMemo(() => {
		switch (kybSubInfoModal?.modalType) {
			case 'simple':
				return (
					<ArraySection
						disableMore={true}
						label={kybSubInfoModal?.data?.label ?? '--'}
						data={kybSubInfoModal?.data?.value ?? []}
					/>
				);
				break;
			case 'tabs':
				return (
					<div className="Kyb-info__tabs-box-modal">
						<div className="Kyb-info__tabs-wrapper">
							{Object.keys(kybSubInfoModal?.data).map(tab => (
								<>
									{kybSubInfoModal?.data[tab]?.label && (
										<div
											// eslint-disable-next-line react/no-array-index-key
											key={tab}
											className={`tab ${selectedTab === tab ? 'selected' : ''}`}
											onClick={() => handleTabClick(tab)}
										>
											{kybSubInfoModal?.data[tab]?.label}
										</div>
									)}
								</>
							))}
						</div>

						<ArraySection
							disableMore={true}
							label={kybSubInfoModal?.data[selectedTab]?.label ?? '--'}
							data={kybSubInfoModal?.data[selectedTab]?.value ?? []}
						/>
					</div>
				);
				break;

			default:
				return <></>;
		}
	}, [kybSubInfoModal?.data, kybSubInfoModal?.modalType, selectedTab]);

	// This Fuction is for Comperhensive Data
	const comprohensiveKybCompanyDetails = useMemo(() => {
		return (
			<div className="kyb-wrapper__details kyb-wrapper__details-wrapper">
				{selectedKybType === 'kyb-comprehensive' ? (
					Object.keys(data?.companyDetails?.comprehensive?.report).length ===
					0 ? (
						<>
							<div className="kyb-empty_data">
								<div className="kyb-empty_data__inner">
									<Image
										fileName="missing.svg"
										className="empty_data_img"
										height="100"
									/>
									<div className="kyb-empty-data-txt">No data is available</div>
								</div>
							</div>
						</>
					) : (
						Object.keys(data?.companyDetails?.comprehensive?.report ?? {}).map(
							key => {
								const { label, type, value } =
									data?.companyDetails?.comprehensive?.report[
										key as keyof typeof data.companyDetails.comprehensive.report
									] || {};
								if (
									key !== 'assetSection' &&
									key !== 'irs5500information' &&
									key !== 'bankruptcyInformation'
								) {
									return (
										<KYBComprehensive
											key={label}
											label={label}
											type={type as any}
											value={value as any}
										/>
									);
								} else if (key === 'assetSection') {
									return (
										<>
											<KybAssetsInformation
												key={label}
												label={label}
												data={value as any}
											/>
										</>
									);
								} else if (key === 'irs5500information') {
									return (
										<>
											<IRS500Information
												label="IRS5500 Information"
												data={value as any}
											/>
										</>
									);
								} else if (key === 'bankruptcyInformation') {
									return (
										<BankruptcyInformationSection
											label={label}
											bankruptcyData={value as BankruptcyData}
											key={label}
										/>
									);
								} else return <></>;
							}
						)
					)
				) : (
					<OFAC />
				)}
				{/*THis is the MoDal we will use Dynamically for KYb sub section Information after clicking on View button.*/}
				<Modal
					isOpen={kybSubInfoModal.isOpen}
					modalName={'Comperhensive'}
					closeModal={handleCloseModal}
					titleWithCss="kyb-comperhensive-modal-title"
					showCloseBtn
					isStopOutsideClick={true}
					className="arun-modal"
					title={kybSubInfoModal.label}
				>
					<div className="kyb-info__modal">{renderSubInfoModalBody}</div>
				</Modal>
			</div>
		);
	}, [
		data,
		handleCloseModal,
		kybSubInfoModal.isOpen,
		kybSubInfoModal.label,
		renderSubInfoModalBody,
		selectedKybType,
	]);

	// For default kyb tab select
	const handleKybType = () => {
		if (!configurations || configurations.length === 0) {
			// Handle the case when configurations are not available
			return '--';
		} else return configurations[0];
	};

	return (
		<div className="kyb">
			<div className="kyb-wrapper">
				<SessionDetailsHeading
					label="KYB"
					icon={<i className="ri-building-fill kyb-wrapper__header_icon" />}
				>
					{KYBLabelDataKeys?.length > 0 && (
						<div className="kyb-wrapper_download-wrapper">
							<ReactDropdown
								options={KYBDownloadOption}
								value={{ label: 'Download', value: 'download' }}
								defaultValue={{ label: 'Download', value: 'download' }}
								handleChangeSelect={handleChangeSelect}
								controlStyle={{ minHeight: 40, width: 200 }}
							/>
							<div className="action-btn">
								{role === 'AGENT' && !isAgentPermitted ? (
									<div className="action-btn__agent">{status}</div>
								) : (
									<ActionButton
										status={status}
										type="kyb"
										nodeId={nodeId}
										isDisabled={
											!isUserPermissionWrite || isStatusApprovedOrRejected
										}
									/>
								)}
							</div>
						</div>
					)}
				</SessionDetailsHeading>

				{/* THis is for Logo and Name*/}
				<div className="kyb-wrapper__details kyb-wrapper__details-wrapper">
					<KybLogo
						item={data?.companyDetails?.enhanced?.report as IKybDetails}
					/>
				</div>

				{/* THis is for Tabs  */}
				{configurations?.length > 1 && (
					<div className="kyb-wrapper__kyb-type-wrapper">
						{(configurations ?? []).map((item: string) => (
							<span
								key={item}
								className={`kyb__type ${
									item === selectedKybType ? 'kyb-active' : ''
								}`}
								onClick={() => setSeletedKybType(item)}
							>
								{KYB_TYPE_TABS[item] ? KYB_TYPE_TABS[item] : item}
							</span>
						))}
					</div>
				)}

				{/* THis is for Data  */}
				{!isLoading && isLoaded ? (
					<>
						{kyb && kyb[0]?.data?.companyDetails ? (
							<>
								{selectedKybType === handleKybType()
									? EnChangedkybCompanyDetail
									: comprohensiveKybCompanyDetails}
							</>
						) : (
							emptyPage(data?.error)
						)}
					</>
				) : (
					<SessionDetailsSkeletonLoader />
				)}
			</div>
		</div>
	);
};
