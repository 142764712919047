import numeral from 'numeral';

export const NumberWithCommas = (number: number, isLoading?: boolean) => {
	if (isLoading) {
		return '-';
	}
	return Number(number).toLocaleString();
};

export const formatNumberWithCommasAndCurrencySignSign = (
	number: number | string,
	sign?: string
) => {
	// Convert the number to a string
	const numberString = number.toString();
	// Split the number into integer and decimal parts
	const [integerPart, decimalPart] = numberString.split('.');
	// Add commas to the integer part
	const firstPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	// If there is a decimal part, format it
	const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '.00';
	// Combine the integer and formatted decimal parts with a dot
	let formattedNumber = `${firstPart}${formattedDecimalPart}`;
	// Add a dollar sign at the beginning
	formattedNumber = `${sign}${formattedNumber}`;
	return formattedNumber;
};

export const useFormatNumber = () => {
	const numberDecimal = (value: number | undefined) => {
		return numeral(value ?? 0).format('0,0.00');
	};

	return { numberDecimal };
};

export const AddZeroInSingleNumbers = (integer: string) => {
	return integer.length === 1 ? `0${integer}` : integer;
};

export const convertToCurrencySystem = (amount: string | number) => {
	return Math.abs(Number(amount)) >= 1.0e9
		? Number((Math.abs(Number(amount)) / 1.0e9).toFixed(2)) + 'B'
		: Math.abs(Number(amount)) >= 1.0e6
		? Number((Math.abs(Number(amount)) / 1.0e6).toFixed(2)) + 'M'
		: Math.abs(Number(amount)) >= 1.0e3
		? Number((Math.abs(Number(amount)) / 1.0e3).toFixed(2)) + 'K'
		: Number(Math.abs(Number(amount)).toFixed(2));
};
