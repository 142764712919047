import { useNavigate } from 'react-router-dom';
import { Loader } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { WebLogin } from 'components';
import { LoginStep, OptionsResponseState, useWebLogin } from 'components/login/store';
import { API_URL, MESSAGE } from 'constant';
import { useLocation, useNotification, useWebAuthn } from 'hooks';
import { AuthHeader, Footer } from '../components';
import { useSignup } from '../signup';

export const SignIn = () => {
	const { successNotification, errorNotification } = useNotification();
	const { fetchUserDetails } = useSignup();
	const setStep = useSetRecoilState(LoginStep);
	const navigate = useNavigate();

	const [tokenPassed, setTokenPassed] = useState(false);
	const { verifyRegistration } = useWebLogin();
	const [ipAddress] = useState('');
	const { locationInfo } = useLocation();
	const optionsResponse = useRecoilValue(OptionsResponseState);
	const resetOptionResponse = useResetRecoilState(OptionsResponseState);
	//TODO manish
	const params = new URLSearchParams(window.location.search);
	const token = params.get('token');
	const verificationType = params.get('type');
	const emailVerificationId = params.get('code');
	const { registerNewCredential } = useWebAuthn();

	const handleRegistrastionSuccess = useCallback(
		async (res: any) => {
			if (res) {
				const payloadSaveCredential = {
					type: 'verifyRegistration',
					registrationOptResponse: res,
					id: optionsResponse?.id,
					deviceInfo: {
						location: locationInfo,
						ip: ipAddress,
					},
				};
				const resp = await verifyRegistration(payloadSaveCredential);
				const { isRegistered, message } = resp ?? {};
				resetOptionResponse();
				if (isRegistered) {
					successNotification('Device registered successfully.');
					navigate('/login');
				} else {
					errorNotification(
						message ??
							'We have found some problems while registering your device'
					);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[optionsResponse?.id, locationInfo, ipAddress]
	);

	const registerWebAuth = async () => {
		try {
			await registerNewCredential(
				handleRegistrastionSuccess,
				optionsResponse?.registrationOptions ?? {}
			);
		} catch (error) {
			if (error instanceof DOMException && error.name !== 'NotAllowedError') {
				errorNotification(`There is some unexpected error`);
			}
		}
	};

	useEffect(() => {
		if (
			optionsResponse?.registrationOptions &&
			verificationType === 'emailVerification' &&
			emailVerificationId
		) {
			registerWebAuth();
			setTokenPassed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsResponse]);

	// this will call the client users get api

	const manageEmailOtpVarify = useCallback(
		(response: any) => {
			const { token, mfaEnabled } = response;
			if (token && !mfaEnabled) {
				fetchUserDetails(token);
			} else if (mfaEnabled) {
				setStep('MFA-AUTH');
			}
		},
		[fetchUserDetails, setStep]
	);

	const manageMobileCodeFailed = useCallback(
		(response: any) => {
			const { status, message } = response;
			if (!status) {
				errorNotification(message ?? MESSAGE.ERROR);
			}
		},
		[errorNotification]
	);

	useEffect(() => {
		if (token) {
			setTokenPassed(true);
			manageEmailOtpVarify({ token, message: 'Logged in successfully' });
		}
		// eslint-disable-next-line
	}, [token]);

	const onSuccess = function (event: { [key: string]: any }) {
		const { type } = event;
		switch (type) {
			case 'emailCode':
				manageEmailOtpVarify(event);
				break;
			case 'email':
				manageEmailOtpVarify(event);
				break;
			case 'mobileCode':
				manageEmailOtpVarify(event);
				break;
			case 'webAuth':
				manageEmailOtpVarify(event);
				break;
			default:
				break;
		}
	};

	const onFailed = function (event: { [key: string]: any }) {
		const { type } = event;
		switch (type) {
			case 'mobileCode':
				manageMobileCodeFailed(event);
				break;
			case 'webAuth':
				manageMobileCodeFailed(event);
				break;
			default:
				break;
		}
	};

	const renderMainComponent = useMemo(() => {
		if (!tokenPassed) {
			return (
				<WebLogin
					input_type="email|phone"
					onSuccess={onSuccess}
					onFailed={onFailed}
					methods="biometric|approval"
					send_otp_api={API_URL.PHONE_CODES}
					login_api={API_URL.USER_LOGIN}
					web_auth_api={API_URL.WebAuthLogin}
				/>
			);
		} else {
			return (
				<div className="loader-center">
					<Loader />
				</div>
			);
		}
		// eslint-disable-next-line
	}, [tokenPassed]);

	return (
		<div className="login-wrapper">
			<AuthHeader btnActive={'login'} />
			<div className="authlogin">{renderMainComponent}</div>
			<Footer />
		</div>
	);
};
