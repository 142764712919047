export const validateEmail = (email: string) => {
	const regex =
		// eslint-disable-next-line no-useless-escape
		/^[a-zA-Z0-9]+(?:[.]?[a-zA-Z0-9]+)+(?:\+[a-zA-Z0-9.]+)?@[A-Za-z0-9-]+\.[A-Za-z]{2,}$/;
	return !(!email || regex.test(email) === false);
};

export const isEmojiExist = (val: string) => {
	const emojiRegex =
		/[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{E0020}-\u{E007F}]/u;
	if (emojiRegex.test(val)) return true;
	else return false;
};
