import { REACT_APP_HOST_URL } from 'envs';

export const urlRegx =
	'^(https?:\\/\\/)?' + // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
	'(\\#[-a-z\\d_]*)?$';

export const InstanceTableHeaders = [
	{
		label: 'Instance Name',
		key: 'name',
		format: 'string',
		width: '30%',
		className: 'instance',
	},
	{
		label: 'Client Id',
		key: 'authId',
		format: 'jsx',
		width: '30%',
		className: 'instance',
	},
	{
		label: 'Date Created',
		key: 'date',
		format: 'jsx',
		width: '15%',
		className: 'instance',
	},
	{
		label: 'Signup Required',
		key: 'signup',
		format: 'jsx',
		width: '15%',
		className: 'instance',
	},
	{
		label: 'Actions',
		key: 'actions',
		format: 'jsx',
		width: '10%',
		className: 'instance',
	},
];

export const ConfigureInputmetaData = [
	{
		key: 'basicInformation',
		label: 'Basic Information',
		payloadType: 'keyValuePair',
		description:
			'Configure basic settings for your authentication instance including its name, client ID & secret, description, and logo.',
		metaData: [
			{
				inputType: 'text',
				placeholder: 'Name',
				label: 'Auth Instance Name',
				regex: '^[a-zA-Z0-9 ]+$',
				type: 'input',
				errorMessage: 'Please enter valid instance name',
				name: 'name',
				value: '',
				id: 'input_1',
			},
			{
				inputType: 'text',
				regex: '^[a-f0-9]{24}$',
				placeholder: 'Select',
				label: 'Client ID & Secret',
				errorMessage: 'Please enter valid clientId',
				type: 'dropdown',
				name: 'authId',
				id: 'input_2',
				value: '',
				isCustomComponent: true,
			},
			{
				inputType: 'text',
				placeholder: 'Enter description here...',
				label: 'Description',
				type: 'textArea',
				name: 'description',
				id: 'input_3',
			},
			{
				label: 'Logo',
				type: 'fileUpload',
				description:
					'Upload a logo or provide URL of the logo to display for the auth instance, if none is set the default badge will be shown. Recommended size is 192x192 pixels.',
				name: 'logo',
				id: 'input_4',
				accept: '.png, .jpg, .jpeg',
				errorMessage: 'Please enter valid image url',
				placeholder: 'https://mysite.com/logo.png',
			},
		],
	},
	{
		label: 'Auth Instance Properties',
		key: 'properties',
		payloadType: 'keyValuePair',
		description: 'Configure properties for your Auth instance.',
		metaData: [
			{
				label:
					'Use Simplici auth instead of identity provider for Single sign on',
				description:
					'If this setting is enabled, Simplici auth will handle Single Sign On instead of the Identity Provider (e.g.: No redirect to Facebook to log the user in if they have already logged in before).',
				type: 'toggle',
				name: 'properties',
				id: 'input_5',
			},
		],
	},
	{
		label: 'Signup configuration',
		key: 'signUp',
		description:
			'Specify whether user sign-up is required for accessing your application. When enabled, users must create an account before logging in.',
		metaData: [
			{
				label: 'Signup requirement',
				description:
					'Set the required methods for user sign-up, allowing users to sign up using either their phone number or email address or both phone number and email address for sign-up.',
				type: 'toggle',
				name: 'required',
				id: 'input_15',
				value: true,
			},
			{
				label: 'Email ',
				description: '',
				type: 'toggle',
				name: 'email',
				id: 'input_16',
				isHidden: false,
				value: true,
			},
			{
				label: 'Phone number',
				description: '',
				type: 'toggle',
				name: 'phone',
				id: 'input_17',
				isHidden: false,
				value: true,
			},

			{
				label: '',
				description: '',
				type: 'border',
				name: 'phone',
				id: 'input_17',
				ignore: true,
			},

			{
				label: 'Onboarding flow requirement',
				description:
					'Enable this option to ensure users goes through a particular onboarding flow once they finish signing up before accessing the platform.',
				type: 'toggle',
				name: 'onboaringFlowRequired',
				id: 'input_13',
				ignore: true,
			},

			{
				description: 'Choose onboarding flow ',
				label:
					'Select onboarding flow users goes through once they finish signing up before accessing the platform.',
				type: 'dropdown',
				name: 'onboardingflow',
				id: 'input_14',
				disabled: true,
			},
		],
	},
	{
		label: 'Login method configuration',
		key: 'methods',
		description:
			'Choose the authentication methods that users can use to verify their identity and access the system.',
		metaData: [
			{
				label: 'Device passkey',
				description:
					'Enable biometric authentication on supported devices for a seamless and secure login experience.',
				type: 'toggle',
				name: 'biometric',
				id: 'input_6',
				value: false,
			},
			{
				label: '',
				description: '',
				type: 'border',
				name: 'phone',
				id: 'input_17',
				ignore: true,
			},
			{
				label: 'One time password : Phone or Email',
				description:
					"Send a one-time password (OTP) to the user's mobile phone via text message or email for authentication purposes.",
				type: 'toggle',
				name: 'otp',
				id: 'input_7',
				value: false,
			},
			{
				label: '',
				description: '',
				type: 'border',
				name: 'phone',
				id: 'input_17',
				ignore: true,
			},

			{
				type: 'warning',
				note: `<div>
				 <div style="font-size: 14px;color: #6C7489;font-weight: 400;">Enable biometric authentication for users, allowing them to use their unique biological traits, such as facial features or palm print for authentication.</div>
				 <div style="font-size: 16px;color: #515767;font-weight: 500;">Simplici Biometric</div>
				 <div  style="font-size: 14px;color: #6C7489;font-weight: 400;">Enable biometric authentication for users, allowing them to use their unique biological traits, such as facial features or palm print for authentication.</div>
				 
				 </div>`,
				name: 'warning',
				ignore: true,
			},

			{
				label: 'Simplici Biometric',
				description:
					'Enable biometric authentication for users, allowing them to use their unique biological traits, such as fingerprints or facial features or palm print for authentication.',
				type: 'toggle',
				name: 'simpliciBiometric',
				id: 'input_9',
				value: false,
				payloadKey: 'biometric',
				isHidden: true,
			},

			{
				label: 'Facial Recognition',
				description:
					'Enable facial recognition for biometric authentication, allowing users to authenticate using their facial features.',
				type: 'toggle',
				name: 'facialRecognition',
				id: 'input_11',
				value: false,
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
			},
			{
				label: 'Facial recognition required or optional',
				description:
					'Make facial recognition either required or optional for users.',
				type: 'radio',
				name: 'facialRecognitionRequired',
				id: 'input_12',
				value: true,
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
			},

			{
				label: 'Liveness',
				description:
					'Enable liveness detection for biometric authentication, ensuring that the biometric sample provided by the user is from a live person and not a replayed or fake sample.',
				type: 'toggle',
				name: 'liveness',
				id: 'input_9',
				value: false,
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
			},
			{
				label: 'Liveness required or optional',
				description:
					'Make liveness verification either required or optional for users.',
				type: 'radio',
				name: 'livenessRequired',
				id: 'input_12',
				value: true,
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
			},
			{
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
				label: 'Select gestures',
				description:
					'Choose the required gestures for liveness verification. These actions will help ensure the user is present and not a photograph or other spoofing attempt.',
				placeholder: '',
				type: 'gestureSetting',
				name: 'gestureSetting',
				options: [
					{
						label: 'Blink',
						name: 'blink',
						value: 2,
						checked: false,
					},
					{
						label: 'Open mouth',
						name: 'openMouth',
						value: 2,
						checked: false,
					},
					{
						label: 'Pucker',
						name: 'pucker',
						value: 2,
						checked: false,
					},
					{
						label: 'Rotate head to left',
						name: 'rotateHeadToLeft',
						value: 2,
						checked: false,
					},
					{
						label: 'Rotate head to Right',
						name: 'rotateHeadToRight',
						value: 2,
						checked: false,
					},
					{
						label: 'Tilt head up',
						name: 'tiltHeadUp',
						value: 2,
						checked: false,
					},
					{
						label: 'Tilt head down',
						name: 'tiltHeadDown',
						value: 2,
						checked: false,
					},
				],
				id: 'input_10',
			},

			{
				label: 'Palm Print Verification',
				description:
					'Enable palm print verification for biometric authentication, allowing users to authenticate using their palm prints, which are unique to each individual.',
				type: 'toggle',
				name: 'palmPrintVerification',
				id: 'input_12',
				value: false,
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
			},
			{
				label: 'Palm print verification required or optional',
				description:
					'Make palm print verification either required or optional for users.',
				type: 'radio',
				name: 'palmPrintVerificationRequired',
				id: 'input_12',
				value: true,
				payloadKey: 'biometric',
				isHidden: true,
				className: 'ml-24',
			},
		],
	},

	{
		label: 'Whitelisting URI',
		key: 'whitelistingUrls',
		payloadType: 'keyValuePair',
		description:
			'Specify URIs that are allowed to access your Auth instance, restricting access to only whitelisted URIs.',
		note: '<div style=" font-size: 14px;"><b>Wildcard domain: </b> Both pre and post wildcards are supported. For a wildcard domain, enter it in the following format: https://%2A.xyzabc.com/*</div>',
		metaData: [
			{
				inputType: 'input',
				placeholder: 'Name',
				label: 'Whitelist domain address',
				description: 'Specify the domains that this auth instance will work.',
				type: 'textArray',
				regex:
					'^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
				name: 'whitelistingUrls',
				errorMessage: 'Please enter a valid URL',
				value: [],
				footerLabel: 'Whitelisted URIs',
				footerDescription: 'List of whitelisted URLs',
				id: 'input_18',
				validErrorMessage: 'Please enter a valid URL',
				duplicateErrorMessage: 'Duplicate URL not allowed.',
				emptyErrorMessage: 'At least one whitelisted URL is required.',
			},
		],
	},
	{
		label: 'Auth Instance URIs',
		key: 'authInstanceURIs',
		description:
			'Manage the URIs associated with the Auth instance, including login URIs and redirect URLs for authentication flows.',
		metaData: [
			{
				inputType: 'text',
				placeholder: 'https://',
				description: 'After login',
				label: 'Redirect URI',
				regex:
					'^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
				errorMessage: 'Please enter a valid URL',
				type: 'input',
				name: 'redirectURI',
				id: 'input_20',
			},
			{
				inputType: 'text',
				placeholder: 'Enter description here...',
				description:
					'In some scenarios, we will need to redirect to your application’s login page. This URI needs to point to a route in your application that should redirect to your tenant’s authorized endpoint.',
				label: 'Description',
				type: 'textArea',
				name: 'description',
				id: 'input_21',
			},
		],
	},
	{
		label: 'ID Token Expiration',
		key: 'token',
		description:
			'Set the expiration time for ID tokens issued by the Auth instance, ensuring tokens remain valid for a specified period.',
		metaData: [
			{
				label: 'ID Token Expiration',
				description:
					'This setting allows you to set the lifetime of the id_token (in seconds)',
				type: 'input',
				inputType: 'number',
				placeholder: '36000',
				name: 'expiration',
				id: 'input_23',
			},
		],
	},
	{
		label: 'Identity Providers',
		payloadType: 'keyArrayPair',
		key: 'providers',
		description:
			'Configure identity providers for your Auth instance, such as Google, Facebook, or custom providers.',
		metaData: [
			{
				label: 'Google',
				description: '',
				type: 'toggle',
				defaultValue: '',
				name: 'google',
				imageUrl: `${REACT_APP_HOST_URL}/media/google.svg`,
				id: 'input_30',
				className: 'auth-provider',
			},
			{
				label: 'Facebook',
				description: '',
				type: 'toggle',
				defaultValue: '',
				name: 'facebook',
				imageUrl: `${REACT_APP_HOST_URL}/media/facebook.svg`,
				id: 'input_31',
				className: 'auth-provider',
			},
			{
				label: 'Apple',
				description: '',
				type: 'toggle',
				defaultValue: '',
				name: 'apple',
				imageUrl: `${REACT_APP_HOST_URL}/media/apple.svg`,
				id: 'input_32',
				className: 'auth-provider',
			},
			{
				label: 'Microsoft',
				description: '',
				type: 'toggle',
				defaultValue: '',
				name: 'microsoft',
				imageUrl: `${REACT_APP_HOST_URL}/media/microsoft.svg`,
				id: 'input_33',
				className: 'auth-provider',
			},
		],
	},
];

export const HEADER_OPTIONS = [
	{
		label: 'General Settings',
		value: 'generalSettings',
	},
	{
		label: 'Connections',
		value: 'connections',
		isDisabled: true,
	},
];
