import axios from 'axios';
import { GOOGLE_API } from 'constant';
import { GoogleLocation } from 'global-stores';
import { useSetRecoilState } from 'recoil';

export const useGetLocationDetails = () => {
	const setGoogleLocation = useSetRecoilState(GoogleLocation);

	const getGeoInfo = async ({ lat, lng }: { lat: number; lng: number }) => {
		try {
			const resp = await axios.post(GOOGLE_API.IP, {
				location: { lat, lng },
				ip: true,
			});
			if (resp?.status === 200) {
				const { data } = resp;
				setGoogleLocation(data);
				return data;
			} else {
				// eslint-disable-next-line no-console
				console.error(
					'Failed to fetch location data. Unexpected status code:',
					resp?.status
				);
				return null;
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error fetching location data:', error);
			return null;
		}
	};

	return {
		getGeoInfo,
	};
};
