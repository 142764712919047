export const HEADER = {
	SUB_ACCOUNT: [
		{
			label: 'Name',
			key: 'name',
			format: 'string',
			width: '30%',
			className: 'sub-account-name-ellipsis',
		},
		{
			label: 'Creation Date',
			key: 'createdAt',
			format: 'date',
			width: '30%',
		},
		{
			label: 'Users',
			key: 'totalUsers',
			format: 'jsx',
			width: '20%',
		},
		{
			label: 'Status',
			key: 'status',
			key1: 'approveStatus',
			format: 'jsx',
			width: '20%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			width: '10%',
		},
	],

	USER: [
		{
			label: 'User Name',
			key: 'firstName',
			format: 'jsx',
			width: '24%',
			className: 'sub-account-name-ellipsis',
		},
		{
			label: 'Email',
			key: 'email',
			format: 'string',
			width: '26%',
		},
		{
			label: 'Role',
			key: 'role',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Subscription',
			key: 'subscription',
			format: 'jsx',
			width: '14%',
		},
		{
			label: 'Bill',
			key: 'price',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Status',
			key: 'status',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			width: '6%',
		},
	],
};

export const SUBACCOUNTDETAILS = {
	COMPANY_SUB_ACCOUNT_TITLE:
		'Sub-Account - Billing Borne by Sub-Account Holder',
	COMPANY_SUB_ACCOUNT_DESCRIPTION:
		'Empower sub-accounts to manage their own payments. Reduce your administrative burden with flexible account management.',
	OWN_BILLING_TITLE: 'Sub-Account - Billing Borne by You',
	OWN_BILLING_DESCRIPTION:
		'Simplify management with sub-accounts where all billing is handled by you. Keep finances in one place and enjoy seamless control.',
	MANAGER_DETAILS_TITLE: 'Manager details & service subscription',
	MANAGER_DETAILS_DESCRIPTION: '',
};

export const SUBACCOUNTSTATUS = {
	"PENDING" : 'Pending',
	"APPROVED" : 'Verified',
	"REJECTED" : 'Verification Failed',
	"UNDER_REVIEW" : 'Under Review'
}