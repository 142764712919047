import { FC, useCallback, useMemo, useState } from 'react';
import { Image } from '@storybook';
import { KYB_ICONS } from 'views/sessions/components/kyb-wrapper/hooks';

// Here any is because data is for multiple subsection so that here given any
interface IProps {
	label?: string;
	data: any;
	disableMore?: boolean;
}

export const ArraySection: FC<IProps> = ({ data, disableMore }) => {
	const [showAllDetails, setShowAllDetails] = useState(disableMore ?? false);

	const toggleShowAllMembers = () => {
		setShowAllDetails(!showAllDetails);
	};

	const renderArraySubArray = useCallback((label: string, data: any) => {
		return (
			<>
				<div>
					<strong>{label}</strong>
				</div>
				<div className="dunbrad-subarray-wrapper">
					{(data ?? []).map((item: any) => (
						<div className="dunbrad-subarray-wrapper-box" key={item.toString()}>
							<div className="dunbrad-subarray-wrapper-sicCode label-with-dot">
								{item?.sICCode?.value ?? '--'}
							</div>
							<div className="dunbrad-subarray-wrapper-desp">
								{item?.sICCodeDescription?.value ?? '--'}
							</div>
						</div>
					))}
				</div>
			</>
		);
	}, []);

	const handleValue = useCallback((value: string) => {
		return value?.length > 0 ? value : '--';
	}, []);

	const getClassName = useCallback((index: number) => {
		const classNames = [
			'kyb-comperhensive-blue',
			'kyb-comperhensive-violet',
			'kyb-comperhensive-yellow',
			'kyb-comperhensive-green',
		];
		const classIndex = index % classNames?.length ?? 0;  
		return classNames[classIndex] ?? "kyb-comperhensive-blue";
	}, []);

	const renderCards = useMemo(() => {
		return (
			<>
				{data?.length === 0 && (
					<div className="kyb-array-empty-wrapper">
						<div className="kyb-empty_data">
							<div className="kyb-empty_data__inner">
								<Image
									fileName="No_record_found.svg"
									className="empty_data_img"
									height="100"
								/>
								<div className="kyb-empty-data-txt">No records found</div>
							</div>
						</div>
					</div>
				)}
				{data?.length > 4 && !disableMore && (
					<div
						onClick={() => toggleShowAllMembers()}
						className="kyb-filing_view-more kyb-comprohensive__view-more "
					>
						{showAllDetails ? 'View less' : 'View more'}
					</div>
				)}
				{data?.map((item: any, index: number) => {
					if (index < 4 || showAllDetails) {
						const { name, corporationName, ultimateCompanyName, licenseNumber, logo, logoType } = item ?? {};
						return (
							<div
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								className="kyb-wrapper__details__kybInfoDetailsRow"
							>
								{/* Assuming item has a type property */}
								<div className="kyb-wrapper__details__kybAssestDetailsRow">
									{(name ||
										corporationName ||
										ultimateCompanyName || licenseNumber) && (
										<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper">
											<div
												className={`${getClassName(
													index
												)}  kyb-wrapper__details__kybAssestDetails-label-wrapper__icon" `}
											>
												<i
													className={
														KYB_ICONS[logo?.value] ??
														KYB_ICONS[logoType?.value ?? 'riquestionmark']
													}
												></i>
											</div>
											<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper__label">
												{handleValue(
													name?.value ??
														corporationName?.value ??
														ultimateCompanyName?.value ?? licenseNumber?.value
												)}

												{/* Assuming item has a label */}
											</div>
										</div>
									)}

									{item &&
										typeof item === 'object' &&
										Object.keys(item ?? {}).map((key: string) =>
											item[key]?.type === 'basic' ? (
												<>
													{key !== 'name' &&
														key !== 'logo' &&
														key !== 'logoType' &&
														key !== 'ultimateCompanyName' &&  key !== 'LicenseNumber' && (
															<div
																key={key} // Changed key to key from label
																className="kyb-wrapper__details__kybInfoDetailsRow"
															>
																<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
																	{item[key]?.label ?? key}
																	{/* Changed to key */}
																</div>
																<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
																	<span>:</span>
																	<div>
																		{item[key]?.value?.toString()?.length > 0
																			? item[key]?.value?.toString()
																			: '--'}
																	</div>
																</div>
															</div>
														)}
												</>
											) : item[key]?.type === 'array' && key === 'sICs' ? (
												<div className="kyb-array-subarray-wrapper">
													{renderArraySubArray(
														item[key]?.label,
														item[key]?.value
													)}
												</div>
											) : null
										)}
								</div>
							</div>
						);
					} else {
						return <></>;
					}
				})}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, disableMore, showAllDetails]); // Removed label dependency as it wasn't used inside the map function

	if (!Array.isArray(data)) {
		// Handle data not being an array
		return <div>Data not available</div>;
	}

	return <div className="kyb-comprohensive-wrapper">{renderCards}</div>;
};
