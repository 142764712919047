import {
	Button,
	Image,
	Input,
	Loader,
	fetchCountryCodeFromPhone,
} from '@storybook';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

// import { MobileInput } from '@storybook/mobile-input';
import { AsYouType, CountryCode as CountryType } from 'libphonenumber-js';
import countries from 'json/country-codes.json';
import {
	CountryCode,
	EmailCredsState,
	LoginCredsState,
	useLogin,
} from 'components';
import {
	DEFAULT_COUNTRY,
	isEmojiExist,
	message,
	signupFormMessage,
	validateEmail,
	validateName,
} from 'constant';
import {
	IframePropsState,
	IsOpenInIframeState,
	NewGmailState,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { useNotification } from 'hooks';
import { ROUTES } from 'routes';
import { AuthLayout, VerifyModal } from 'views/authentication/components';
import { useSignup } from '../hooks';
import '../signup.scss';
import { isFreeEmail } from 'utils';

interface IFormData {
	firstName: string;
	lastName: string;
	email: string;
	countryCode?: string;
	phone?: string;
	isMarketing?: boolean;
	company: string;
}

interface IShowFormDataError {
	isFirstNameError: boolean;
	isLastNameError: boolean;
	isEmailError: boolean;
	isPhoneError: boolean;
	isLinkedInUrlError: boolean;
	isCompanyError: boolean;
}

const { origin } = window.location;
const { ancestorOrigins = [] } = window.location;

const hasAncestorOrigins = ancestorOrigins?.length ?? 0 > 0;
const isSameOrigin =
	hasAncestorOrigins && origin === ancestorOrigins[ancestorOrigins.length - 1];

const host = isSameOrigin
	? ancestorOrigins[ancestorOrigins.length - 1]
	: `${origin}`;

const maxLength = 12;

export const SignUpComponent = () => {
	const newGmail = useRecoilValue(NewGmailState);
	const [phoneNumber, setPhoneNumber] = useRecoilState(LoginCredsState);
	const [temporaryPhoneNumber, setTemporaryPhoneNumber] = useState('');
	const setEmailCredsState = useSetRecoilState(EmailCredsState);

	const [countryCode, setCountry] = useRecoilState(CountryCode);
	const isIframe = useRecoilValue(IsOpenInIframeState);

	const iframeProps = useRecoilValue(IframePropsState);
	const { create, isLoaded } = useSignup();
	const { trackEvents } = useTrackEvents();

	const { loginWithSocial, isSocialLogin, isSocialDisable } = useLogin();

	const { errorNotification } = useNotification();
	const { InvalidEmailMessage } = message;
	const { firstNameMessage, lastNameMessage, emailMessage } = useMemo(
		() => signupFormMessage,
		[]
	);

	const navigate = useNavigate();
	const formDataProps = {
		firstName: '',
		lastName: '',
		email: newGmail,
		countryCode: '',
		phone: '',
		company: '',
	};

	const [formData, setFormData] = useState<IFormData>(formDataProps);
	const [consents, setConsents] = useState({
		terms: false,
		privacy: false,
		bometric: false,
	});

	const [errorMessages, setErrorMessages] = useState({
		email: '',
		firstName: '',
		lastName: '',
		company: '',
	});
	const [showFormDataError, setShowFormDataError] =
		useState<IShowFormDataError>({
			isFirstNameError: false,
			isLastNameError: false,
			isEmailError: false,
			isPhoneError: false,
			isLinkedInUrlError: false,
			isCompanyError: false,
		});
	const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

	// check isPhone Number or not
	const getPhoneNumber = useMemo(() => {
		let countryLabel: CountryType = 'US';

		const countryObj = countries.find(item => item.label === countryCode);

		if (countryObj) countryLabel = countryObj.code as CountryType;

		let formattedNumber = phoneNumber || '';

		if (temporaryPhoneNumber.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(temporaryPhoneNumber);
		} else if (phoneNumber.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(phoneNumber);
		} else {
			formattedNumber = temporaryPhoneNumber;
		}
		return formattedNumber;
	}, [countryCode, temporaryPhoneNumber, phoneNumber]);

	const url: string = useMemo(() => window.location.href, []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const urlParams: any = new URLSearchParams(window.location.search);

	// Shahbaz: tracking utm parameters
	const getUtmValues = () => {
		const utmValues: Record<string, string | undefined> = {};
		const queryString = url?.split('?')[1];
		if (queryString) {
			const queryParams = queryString.split('&');
			for (const param of queryParams) {
				const [key, value]: string[] = param.split('=');
				if (key?.startsWith('utm_')) {
					utmValues[key] = value;
				}
			}
		}
		if (Object.keys(utmValues ?? {}).length > 0) {
			try {
				(window as any)?.Intercom('update', {
					...utmValues,
				});
				// eslint-disable-next-line no-empty
			} catch (error) {}
		}
	};

	const handleChangeConsent = (
		e: ChangeEvent<HTMLInputElement>,
		name: string
	) => {
		const { checked } = e.target;
		setConsents(prev => ({ ...prev, [name]: checked }));
	};

	/* handle form submission */
	const onSubmit = useCallback(
		async (data?: IFormData) => {
			const {
				firstName,
				lastName,
				email,
				countryCode: code,
				phone,
				isMarketing = false,
				company,
			} = data ?? formData;

			// Shahbaaz:Not allow gmail domain for signup
			if (email?.length > 0 && isFreeEmail(email)) {
				setErrorMessages(prev => ({
					...prev,
					['email']: 'Please enter a valid company email address',
				}));
				setShowFormDataError(prev => {
					return { ...prev, ['isEmailError']: true };
				});
				return;
			}
			if (firstName) {
				if (!validateName(firstName.trim())) {
					setShowFormDataError(prev => {
						return { ...prev, isFirstNameError: true };
					});
					return errorNotification('Please enter alphabets only');
				}
				setShowFormDataError(prev => {
					return { ...prev, isFirstNameError: false };
				});
			} else {
				setShowFormDataError(prev => {
					return { ...prev, isFirstNameError: true };
				});
				return errorNotification(firstNameMessage);
			}

			if (lastName) {
				if (!validateName(lastName.trim())) {
					setShowFormDataError(prev => {
						return { ...prev, isLastNameError: true };
					});
					return errorNotification('Please enter alphabets only');
				}
				setShowFormDataError(prev => {
					return { ...prev, isLastNameError: false };
				});
			} else {
				setShowFormDataError(prev => {
					return { ...prev, isLastNameError: true };
				});
				return errorNotification(lastNameMessage);
			}

			if (email) {
				if (!validateEmail(email.trim())) {
					setShowFormDataError(prev => {
						return { ...prev, isEmailError: true };
					});
					return errorNotification(InvalidEmailMessage);
				}
				setShowFormDataError(prev => {
					return { ...prev, isEmailError: false };
				});
			} else {
				setShowFormDataError(prev => {
					return { ...prev, isEmailError: true };
				});
				return errorNotification(emailMessage);
			}
			// if (countryPhoneLength[countryCode]) {
			// 	const { min } = countryPhoneLength[countryCode] ?? { min: '0' };
			// 	if (phoneNumber.length < parseInt(min, 10)) {
			// 		setShowFormDataError(prev => ({ ...prev, isPhoneError: true }));
			// 		return errorNotification('Invalid phone number');
			// 	}
			// 	setShowFormDataError(prev => ({ ...prev, isPhoneError: false }));
			// }

			const payload = {
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				email: email.trim().toLowerCase(),
				countryCode: !isMarketing ? countryCode : code,
				company: company.trim(),
				phone: phone
					? phone.replace(/\D/g, '')
					: phoneNumber.replace(/\D/g, ''),
			};
			// setting for showing on email verification screen
			setEmailCredsState(payload['email']);
			const status = await create(payload);
			if (status) {
				setIsVerifyModalOpen(true);
				trackEvents('signup', { ...payload });
			}
			return null;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[
			formData,
			countryCode,
			phoneNumber,
			setEmailCredsState,
			create,
			errorNotification,
			firstNameMessage,
			lastNameMessage,
			InvalidEmailMessage,
			emailMessage,
			trackEvents,
		]
	);

	useEffect(() => {
		setFormData(pre => {
			const preState = { ...pre, countryCode: DEFAULT_COUNTRY.label };
			return preState;
		});
		//Shahbaaz: UTM Tracking Events
		getUtmValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isButtonDisable = useMemo(() => {
		const { firstName, lastName, email = '', company = '' } = formData;
		const { privacy, terms, bometric } = consents;
		const isValid = Object.values(errorMessages ?? {}).some(el => el);
		const isMakeDisable =
			!firstName ||
			!lastName ||
			!email.trim() ||
			!phoneNumber ||
			isValid ||
			!privacy ||
			!terms ||
			!company ||
			!bometric;
		return isMakeDisable;
	}, [formData, phoneNumber, consents, errorMessages]);

	const backToLogin = useCallback(() => {
		navigate(ROUTES.LOGIN);
	}, [navigate]);

	const changeInputHandle = useCallback(
		(name: string, isShowError: string, e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;

			setErrorMessages(prev => ({
				...prev,
				[name]: '',
			}));

			if (isEmojiExist(value)) {
				setErrorMessages(prev => ({
					...prev,
					[name]: 'Only letters are allowed',
				}));
			}

			if (name === 'email') {
				if (!validateEmail(value.trim())) {
					setErrorMessages(prev => ({
						...prev,
						[name]: 'Invalid Email',
					}));
					setShowFormDataError(prev => {
						return { ...prev, ['isEmailError']: true };
					});
				} else {
					setErrorMessages(prev => ({
						...prev,
						[name]: '',
					}));
					setShowFormDataError(prev => {
						return { ...prev, ['isEmailError']: false };
					});
				}
			}

			if (name === 'company' && value.length > 50) {
				setErrorMessages(prev => ({
					...prev,
					[name]: 'The company length should be 50 characters',
				}));
			}
			if (
				(name === 'firstName' || name === 'lastName') &&
				validateName(value)
			) {
				setErrorMessages(prev => ({
					...prev,
					[name]: '',
				}));
			}

			setFormData(pre => {
				const preState = { ...pre, [name]: value };
				return preState;
			});

			setShowFormDataError(prev => {
				return { ...prev, [isShowError]: false };
			});
		},
		[]
	);
	const onHandleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
		const { value } = e.target;
		//	const re = /^[0-9\b]+$/;

		if (value?.startsWith('+')) {
			const code = fetchCountryCodeFromPhone(value);
			if (code) {
				const newPhone = value?.replace(code, '')?.replace(/\D/g, '');
				setCountry(code);
				setPhoneNumber(newPhone.replace(/\D/g, ''));
				setTemporaryPhoneNumber(newPhone);
			} else {
				setPhoneNumber(value.replace(/\D/g, ''));
				setTemporaryPhoneNumber(value.replace(/\D/g, ''));
			}
		} else if (value.replace(/\D/g, '').length < maxLength) {
			setPhoneNumber(value.replace(/\D/g, ''));
			if (value.length < 6) {
				setTemporaryPhoneNumber(value.replace(/\D/g, ''));
			} else {
				setTemporaryPhoneNumber(value);
			}

			setShowFormDataError(prev => {
				return { ...prev, isPhoneError: false };
			});
		} else {
			setShowFormDataError(prev => {
				return { ...prev, isPhoneError: true };
			});
		}
	};

	const handleKeyPress = useCallback(
		(e: any, name?: string) => {
			if (e.key === 'Enter') {
				onSubmit();
			}

			if (
				(name === 'firstName' || name === 'lastName') &&
				!validateName(e.key)
			) {
				setErrorMessages(prev => ({
					...prev,
					[name]: 'Only letters are allowed',
				}));
				return;
			}
			if (name && !validateName(e.key)) {
				e.preventDefault();
			}
		},
		[onSubmit]
	);

	const buttonLabel = useMemo((): JSX.Element => {
		if (!isLoaded) {
			return <Loader type="loader" dimension={26} className="loader-white" />;
		}
		return <div className="signup-btn-label">Register</div>;
	}, [isLoaded]);

	const handleVerifyModal = () => {
		setIsVerifyModalOpen(false);
	};

	const renderVerifyModal = useMemo(() => {
		return (
			isVerifyModalOpen && (
				<VerifyModal
					visible={isVerifyModalOpen}
					handleModal={handleVerifyModal}
					regEmail={formData.email}
				/>
			)
		);
	}, [isVerifyModalOpen, formData.email]);

	useEffect(() => {
		const allParameter: any = {};

		for (const pair of urlParams.entries()) {
			if (!allParameter[pair[0]]) {
				allParameter[pair[0]] = '';
			}
			Object.assign(allParameter, { [pair[0]]: pair[1] });
		}
		if (
			Object.keys(allParameter).length &&
			allParameter.phone?.length &&
			allParameter.countryCode?.length &&
			allParameter.email?.length &&
			allParameter.firstName?.length &&
			allParameter.lastName?.length
		) {
			const countryCode = allParameter.countryCode?.trim() ?? '';

			allParameter['countryCode'] = countryCode.includes('+')
				? countryCode
				: `+${countryCode || '1'}`;
			// Shahbaz: For marketing country codes select
			setFormData({ ...allParameter, isMarketing: true });
			setPhoneNumber(allParameter.phone);
			setTemporaryPhoneNumber(allParameter.phone);
			setCountry(allParameter.countryCode);
			allParameter['isMarketing'] = true;
			onSubmit(allParameter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isDisableButton = useMemo(
		() =>
			!isLoaded ||
			isButtonDisable ||
			isSocialDisable ||
			showFormDataError.isPhoneError,
		[isButtonDisable, isLoaded, isSocialDisable, showFormDataError.isPhoneError]
	);

	return (
		<>
			<AuthLayout minHeight={400} width={832} className="signup-flow-wrapper">
				<div className="signup-header">
					<div className="signup-header__title">Sign Up</div>
					<div className="signup-header__text">
						Create your account and start your journey with us.
					</div>
				</div>

				<div className="signup-account-wrapper">
					<div className="social-signup-wrapper">
						<div className="social-signup-wrapper__icon">
							{/* <div onClick={() => loginWithSocial('facebook')}>
								<Image className="social-signup-icon" fileName="facebook.svg" />
							</div> */}
							{!isSocialLogin.google ? (
								<button
									disabled={isSocialDisable}
									onClick={() => loginWithSocial('google', host)}
								>
									<Image className="social-signup-icon" fileName="google.svg" />
								</button>
							) : (
								<div className="social-login-wrapper--icon-loader">
									<Loader dimension={20} type="loader" />
								</div>
							)}
							{!isIframe && (
								<>
									{!isSocialLogin.apple ? (
										<button
											disabled={isSocialDisable}
											onClick={() => loginWithSocial('apple', host)}
										>
											<Image
												className="social-signup-icon"
												fileName="apple.svg"
											/>
										</button>
									) : (
										<div className="social-login-wrapper--icon-loader">
											<Loader dimension={20} type="loader" />
										</div>
									)}
									{!isSocialLogin.microsoft ? (
										<button
											disabled={isSocialDisable}
											onClick={() => loginWithSocial('microsoft', host)}
										>
											<Image
												className="social-signup-icon"
												fileName="microsoft.svg"
											/>
										</button>
									) : (
										<div className="social-login-wrapper--icon-loader">
											<Loader dimension={20} type="loader" />
										</div>
									)}
								</>
							)}
						</div>
					</div>
					<div className="signup-divider">
						<span>or</span>
					</div>
				</div>

				<div className="signup-inputs">
					<div className="signup-row-1">
						<Input
							label="First Name"
							inputType="text"
							placeholder="First Name"
							handleChange={e =>
								changeInputHandle('firstName', 'isFirstNameError', e)
							}
							isError={
								!!errorMessages.firstName || showFormDataError.isFirstNameError
							}
							value={formData.firstName}
							handleKeyPress={e => handleKeyPress(e, 'firstName')}
							autoFocus={true}
							errorMessage={errorMessages.firstName}
							isRequired
						/>
						<Input
							label="Last Name"
							inputType="text"
							placeholder="Last Name"
							handleChange={e =>
								changeInputHandle('lastName', 'isLastNameError', e)
							}
							isError={
								!!errorMessages.lastName || showFormDataError.isLastNameError
							}
							value={formData.lastName}
							errorMessage={errorMessages.lastName}
							handleKeyPress={e => handleKeyPress(e, 'lastName')}
							isRequired
						/>
					</div>
					<div className="signup-row-2">
						<Input
							label="Business Email Address"
							inputType="text"
							placeholder="Business Email"
							handleChange={e => changeInputHandle('email', 'isEmailError', e)}
							isError={!!errorMessages.email || showFormDataError.isEmailError}
							value={formData.email}
							handleKeyPress={handleKeyPress}
							errorMessage={errorMessages.email}
							isRequired
						/>
					</div>
					<div className="signup-row-2">
						{/* We might need in future So Keep this Commented Code As it is */}
						{/* <MobileInput
							label="Mobile Number"
							country="us"
							isRequired
							value={getPhoneNumber}
							placeholder="+1 (xxx) xxx-xxx"
							handleChange={onHandleChange}
							enableSearch={true}
						/> */}
						<Input
							handleChange={onHandleChange}
							inputType="text" //keep type text as number is not giving suggestions for auto fill will use regex to accept number
							value={getPhoneNumber}
							placeholder={``}
							label="Mobile Number"
							handleChangeCountry={e => {
								setCountry(e.label);
							}}
							isCountryCodeVisible={true}
							countryCode={countryCode}
							isError={showFormDataError.isPhoneError}
							autoComplete="tel"
							inputName="phone"
							isRequired
						/>
					</div>
					<div className="signup-row-2">
						<Input
							label="Company Name"
							inputType="text"
							placeholder="Company"
							handleChange={e =>
								changeInputHandle('company', 'isCompanyError', e)
							}
							isError={
								!!errorMessages.company || showFormDataError.isCompanyError
							}
							value={formData.company}
							handleKeyPress={handleKeyPress}
							errorMessage={errorMessages.company}
							isRequired
						/>
					</div>
					<div className="tc_wrapper__footer">
						<input
							type="checkbox"
							id="tc-checkbox"
							name="terms"
							checked={consents.terms}
							hidden
							onChange={e => handleChangeConsent(e, 'terms')}
						/>
						<label htmlFor="tc-checkbox" />
						<div className="tc_wrapper__footer__content">
							I agree to{' '}
							{iframeProps.companyname?.length > 0
								? iframeProps.companyname
								: 'simplici'}{' '}
							<a
								href="https://simplici.io/terms-of-service"
								target="_blank"
								rel="noreferrer"
								className="privacy"
							>
								Terms and Conditions
							</a>
						</div>
					</div>
					<div className="tc_wrapper__footer">
						<input
							type="checkbox"
							id="policies-checkbox"
							hidden
							name="privacy"
							checked={consents.privacy}
							onChange={e => handleChangeConsent(e, 'privacy')}
						/>
						<label htmlFor="policies-checkbox" />
						<div className="tc_wrapper__footer__content">
							I agree to{' '}
							{iframeProps.companyname?.length > 0
								? iframeProps.companyname
								: 'simplici'}{' '}
							<a
								href="https://simplici.io/privacy-policy"
								target="_blank"
								rel="noreferrer"
								className="privacy"
							>
								Privacy Policy
							</a>
						</div>
					</div>
					<div className="tc_wrapper__footer">
						<input
							type="checkbox"
							id="biometric-checkbox"
							hidden
							name="biometric"
							checked={consents.bometric}
							onChange={e => handleChangeConsent(e, 'bometric')}
						/>
						<label htmlFor="biometric-checkbox" />
						<div className="tc_wrapper__footer__content">
							I agree to{' '}
							{iframeProps.companyname?.length > 0
								? iframeProps.companyname
								: 'simplici'}{' '}
							<a
								href="https://simplici.io/biometrics-privacy-policy"
								target="_blank"
								rel="noreferrer"
								className="privacy"
							>
								Biometric Policy
							</a>
						</div>
					</div>
				</div>

				<div className="signup-btn-container">
					<Button
						label={buttonLabel}
						handleClick={() => onSubmit()}
						type={'button__filled--primary button__large'}
						width={'100%'}
						height={'56px'}
						disabled={isDisableButton}
					/>
				</div>
				<div className="have-an-account">
					<span>Already have an account?</span>
					<div className="back-to-login" onClick={backToLogin}>
						Log In
					</div>
				</div>
			</AuthLayout>
			{renderVerifyModal}
		</>
	);
};
